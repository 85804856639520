
import List from 'list.js';

const SELECTORS = {
    table: '[data-table]'
}

class PricingTable{
    constructor(table) {

        this.table = $(table);

        if(!this.table.length){
            return;
        }
        
        this.cacheDOM();
        this.initList();

        this.bindEvents();


        this.order = 'asc';
        this.previentOpenCollapse = false;
    }
    cacheDOM(){
        this.sortButtons = this.table.find('[data-sort]');
        this.rows = this.table.find('[data-row]');
    }
    bindEvents(){
        this.sortButtons.on('click', (event)=>{

            let item = $(event.currentTarget);
            this.sortButtons.attr('data-sort-order', '');


            if(this.order == 'asc'){

                item.attr('data-sort-order', 'desc');
                this.order = 'desc';
                this.list.sort('js-price', { order: "desc" });

            }else{

                item.attr('data-sort-order', 'asc');
                this.order = 'asc';
                this.list.sort('js-price', { order: "asc" });

            }
        });

        if (this.showMore)
        {
	        this.showMore.on('click',(event)=>{
	            this.table.addClass('show-more');
	            this.showMore.addClass('is-hidden');
	        });
	    }

    }
    initList(){
        this.list = new List(this.table[0], {
            listClass: 'js-body',
            valueNames: ['js-price']

        });
    }
}



export default {
    init() {

        $(SELECTORS.table).each(function () {
            new PricingTable(this)
        });

    }
}
