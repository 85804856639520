import anime from 'animejs';
import {fade, fadeInTop, fadeInBottom} from './utils';


export default {
    refs: {},
    beforeInit(element, config) {
        this.refs['element'] = element;
        this.refs['brands'] = element.find('[data-animation-brand]')
        this.refs['arrow'] = element.find('[data-animation-arrow]')
        this.refs['tooltip'] = element.find('[data-animation-tooltip')
    },
    init(element, config) {
        fadeInTop([this.refs['brands'][0]], 0)
        fadeInTop([this.refs['brands'][1]], 400)
        fade(this.refs['arrow'], 500)
        fade(this.refs['tooltip'], 800)
    }
}
