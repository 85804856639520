import RangeSlider from './../range-slider/range-slider';

import jstz from 'jstz';

const toolContainer = $("[data-tool]");

function copyButton() {

    toolContainer.find('[data-copy-value]').on('click', function(){
        let input = $(this).closest('.input-group').find('input');
        input[0].select();

        document.execCommand("copy");
    })

}


function initSliders(){

    toolContainer.find('[data-range-slider-container]').each(function(){
        new RangeSlider(this, {
            step: 1,
            startValue: 16,
            minValue: 8,
            maxValue: 40,
        })
    })

}

function subnetCalcIpV4(){

    let frm_tool = $('select[form="frm_tool"][name="mask_bits"]');

    if(frm_tool.length){

        frm_tool[0].selectize.on('change',function()
        {
            $('#frm_tool').submit();
        });

    }

    let frm_usable_addresses = $('select[form="frm_usable_addresses"][name="mask_bits"]');

    if(frm_usable_addresses.length){

        frm_usable_addresses[0].selectize.on('change',function()
        {
            $('#frm_usable_addresses').submit();
        });

    }

    let frm_subnet_mask = $('select[form="frm_subnet_mask"][name="mask_bits"]');

    if(frm_subnet_mask.length){
        frm_subnet_mask[0].selectize.on('change',function()
        {
            $('#frm_subnet_mask').submit();
        });
    }

    let frm_wildcard_mask = $('select[form="frm_wildcard_mask"][name="mask_bits"]');

    if(frm_wildcard_mask.length){
        frm_wildcard_mask[0].selectize.on('change',function()
        {
            $('#frm_wildcard_mask').submit();
        });
    }

}

function subnetCalcIpV6(){

    let frm_maskbits = $('select[form="frm_maskbits"][name="prefix_length"]');
    if(frm_maskbits.length){
        frm_maskbits[0].selectize.on('change',function()
        {
            $('#frm_maskbits').submit();
        });
    }


    let frm_display = $('select[form="frm_display"][name="display"]');
    if(frm_display.length){
        frm_display[0].selectize.on('change',function()
        {
            $('#frm_display').submit();
        });
    }

    let frm_usable = $('select[form="frm_usable"][name="prefix_length"]');
    if(frm_usable.length){
        frm_usable[0].selectize.on('change',function()
        {
            $('#frm_usable').submit();
        });
    }

    let frm_subnets = $('select[form="frm_subnets"][name="prefix_length"]');
    if(frm_subnets.length){
        frm_subnets[0].selectize.on('change',function()
        {
            $('#frm_subnets').submit();
        });
    }
}

var g_tool = $('[data-default-tool]').data('default-tool');
var g_convert = $('[data-convert]').data('convert');
var g_convert_valid = $('[data-convert-valid]').data('convert-valid');


function toggleTools()
{
    if (g_tool == 'epoch')
    {
        $('[tool-eppoch]').hide();
        $('[tool-date]').show();
        $('[data-switcher-from]').text('Date/Time');
        $('[data-switcher-to]').text('Unix Time');
        g_tool = 'date';
    }
    else if (g_tool == 'date')
    {
        console.log(g_tool);
        $('[tool-eppoch]').show();
        $('[tool-date]').hide();
        $('[data-switcher-from]').text('Unix Time');
        $('[data-switcher-to]').text('Date/Time');
        g_tool = 'epoch';
    }
}

/**
* Get the user's timezone
*/
function getTz()
{
    // Determines the time zone of the browser
    var tz = jstz.determine();

    // String for timezone
    var timezone = tz.name();

    return timezone;
}

/**
* Get the user's timezone and populate the timezone fields with the value
*/
function getTzOffset()
{
    var offset = new Date().getTimezoneOffset();

    // Time-zone offset is difference in minutes between UTC and local time
    // (UTC-5 == 300), so multiply the result by -1 to match
    offset = (offset / 60) * -1;

    // Add the positive symbol before any positive difference in timezone offset
    // (negative offsets always have the negative symbol in front)
    if (offset > 0)
    {
        offset = '+' + offset;
    }

    return offset;
}

/**
* Adds local timezone to the top of a select field.
*/
function updateTzSelect(id, timezone, offset)
{
    if (timezone != '' && typeof timezone != 'undefined' && typeof timezone != null)
    {
        $('#' + id).prepend("<option value='" + timezone + "'>Local: " + timezone + ' (GMT ' + offset + ":00)</option>");
    }
}

function unixTimeCalc(){
    // Setup event handlers
    $('[data-switcher-button]').on('click' , function()
    {
        toggleTools();
        return false;
    });

    // Setup tools
    var local_timezone = getTz();
    var local_offset = getTzOffset();

    // (epoch)
    updateTzSelect('epoch_tz', local_timezone, local_offset);

    if (g_convert_valid && g_convert == 'epoch')
    {
        let frm_epoch_tz = $('select[name="epoch_tz"]');
        let frm_epoch_format = $('select[name="epoch_format"]');

        if(frm_epoch_tz.length){
            frm_epoch_tz[0].selectize.on('change',function()
            {
                $('#frm_epoch').submit();
            });
        }

        if(frm_epoch_format.length){
            frm_epoch_format[0].selectize.on('change',function()
            {
                $('#frm_epoch').submit();
            });
        }
    }

    // (date)
    updateTzSelect('date_tz_from', local_timezone, local_offset);
    updateTzSelect('date_tz_to', local_timezone, local_offset);

    if (g_convert_valid && g_convert == 'date')
    {
        let frm_date_tz_from = $('select[name="date_tz_from"]');
        let frm_date_tz_to = $('select[name="date_tz_to"]');
        let frm_date_format = $('select[name="date_format"]');

        if(frm_date_tz_from.length){
            frm_date_tz_from[0].selectize.on('change',function()
            {
                $('#frm_date').submit();
            });
        }
        if(frm_date_tz_to.length){
            frm_date_tz_to[0].selectize.on('change',function()
            {
                $('#frm_date').submit();
            });
        }
        if(frm_date_format.length){
            frm_date_format[0].selectize.on('change',function()
            {
                $('#frm_date').submit();
            });
        }
    }

    // Start time counter
    setInterval(function()
    {
        try
        {
            var epoch = Math.round(new Date().getTime() / 1000.0);
            $('#current_unix').html(epoch.toString());
        }
        catch (e)
        {
            // ...
        }
    }, 500);
}



export default{
    init(){
        initSliders();
        copyButton();
        subnetCalcIpV4();
        subnetCalcIpV6();
        unixTimeCalc();

    }
}
