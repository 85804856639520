
import StickySidebar from 'sticky-sidebar';
import ResizeSensor from 'resize-sensor';
window.ResizeSensor = ResizeSensor;
import util from './util.js';

const SELECTORS = {
    stickyElement: '[data-sticky]',
};

const Default = {
    topOffset: 0,
    bottomOffset: 0
};

class Sticky{
    constructor(element, options){
        this.element = element;
        this.options = options;

        this.getConfig();
        this.initPlugn();
        this.bindEvents();
    }
    getConfig(){
        var dataConfig  = this.element.data();
        if(dataConfig.options){
            this.dataOptions = util.parseDataOptions(dataConfig.options);
        }else{
            this.dataOptions = {};
        }
        this.config = $.extend({}, Default, dataConfig, this.dataOptions, this.options);
    }
    bindEvents(){
        $( window ).on('resize',()=>{

            this.stickyInstance.destroy();
            this.initPlugn();

        });

        $(document).on('updateStickySidebar',()=>{
            this.stickyInstance.destroy();
            this.initPlugn();
        })
    }
    initPlugn(){
        this.stickyInstance = new StickySidebar(this.element[0], {
            topSpacing: this.config.topOffset,
            bottomSpacing: this.config.bottomOffset,
            containerSelector: this.config.container,
            resizeSensor: true,
            stickyClass: 'is-sticky'
        });
        if (typeof this.config.onOpen === 'function') {
            this.config.onInit(this.stickyInstance);
        }
        // this.element[0].addEventListener('affix.top.stickySidebar', () =>{
        //     console.log('affix');
        //     this.addEmptyBox();
        // });
        // this.element[0].addEventListener('affix.static.stickySidebar', () =>{
        //     console.log('affix static');
        //     this.removeEmptyBox();
        // });

    }
    addEmptyBox(){

        if(this.box){
            this.box.remove();
        }
        this.box = $(`<div class="virtual-sticky-container" style="height:${this.element.innerHeight()}px; width:${this.element.innerWidth()}px"></div>`);
        this.element.append(this.box);

    }
    removeEmptyBox(){

        if(this.box){
            this.box.remove();
        }

    }
    destroy(){
        this.stickyInstance.destroy();
    }
}
function initDataSelectors(){
    $(SELECTORS.stickyElement).each(function(){
        new Sticky($(this));
    });

}
function initJqueryPlugin(){
    $.fn.luSticky = function( options ) {
        return this.each(function() {
            new Sticky($(this), options);
        });
    };
}
const init = {
    initDataSelectors,
    initJqueryPlugin,
};

export default init;
