class MobileNav {
    constructor(button, index) {
        this.cacheDOM(button);
        this.bindEvents();

        this.isOpen = false;

    }

    cacheDOM(button) {

        this.button = $(button);
        this.menu = $(this.button.data('open-nav'));
        this.closeBtn = this.menu.find('[data-nav-close]');

    }

    bindEvents() {

        this.button.on('click', () => this.toggleMenu());
        this.menu.on('close-nav', () => this.hideMenu());
        this.closeBtn.on('click', ()=> this.hideMenu())
    }

    toggleMenu() {

        if (!this.isOpen) {

            this.openMenu();

        } else {

            this.hideMenu();

        }

    }

    openMenu() {

        this.isOpen = true;
        this.menu.addClass('is-open');
        this.button.addClass('is-open');
        this.registerClickOutside();

    }
    hideMenu() {


        this.isOpen = false;
        this.menu.removeClass('is-open');
        this.button.removeClass('is-open');
        this.unregisterClickOutside();

    }

    registerClickOutside(){

        setTimeout(()=>{

            $(window).on('click.mobileNav'+ this.index, this.clickOutside.bind(this));

        },0)

    }

    unregisterClickOutside(){

        $(window).off('click.mobileNav'+ this.index);

    }

    clickOutside(event){

        if (!$(event.target).closest(this.menu).length) {

            this.hideMenu();

        }

    }
}



export default {
    init() {

        $('[data-open-nav]').each((index, item)=>{
            new MobileNav(item);
        });

    }
}
