
class FAQList{
    constructor(container) {

        this.cacheDOM(container);
        this.bindEvents();

    }
    cacheDOM(container){

        this.container = container;
        this.questions = this.container.find('[data-faq-question]');

        this.showMore = this.container.find('[data-show-more]');

        this.answerView = this.container.find('[data-faq-answer-view]');
        this.answerTitleView = this.container.find('[data-faq-answer-title-view]');
        this.viewAll = this.container.find('[data-view-all]');

    }
    bindEvents(){

        this.questions.on('click', (event)=>{
            let title = $(event.currentTarget).parent().find('[data-faq-question-title]');
            let content = $(event.currentTarget).parent().find('[data-faq-answer-content]');

            this.questions.removeClass('is-active');
            $(event.currentTarget).addClass('is-active');

            this.updateAnswerView(content.html(), title.text());
        });

        this.viewAll.on('click', ()=>{
            this.container.addClass('mob-show-more');
        });

    }
    showCollapse(){

    }
    hideCollapse(){

    }
    updateAnswerView(content, title){

        this.answerView.html(content);
        this.answerTitleView.html(title);

    }

}




export default {
    init() {
        new FAQList($('[data-faq-container]'));
    }
}
