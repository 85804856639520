import Gumshoe from 'gumshoejs'
export default{
    init(){
        let sidebarNavButton = $('[data-resources-navbar] [data-open-nav]');
        let sidebarLinks = $('#resources-nav a');
        let stickyContainer = $('[data-resources-sidebar] [data-sticky]')

        if(sidebarLinks.length){
            new Gumshoe('#resources-nav a', {

                navClass: 'is-active',
                contentClass: 'is-active',
                nested: true,
                nestedClass: 'is-active',
                offset: 100,
                reflow: false,
                events: true
    
            });
            $(document).on('gumshoeActivate', (event)=>{
                $(document).trigger('updateStickySidebar')
            });
            $('[data-resources-sidebar] a').on('click',()=>{
                sidebarNavButton.trigger('click')
            })
        }
    }
}
