import anime from 'animejs';

function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export default {
    refs:{},
    beforeInit(element, config){
        this.refs['element'] = element;
    },
    init(element, config){

        let value =  element.data('animation');

        let counterAnimation = {
            counterValue: 0
        };

        anime({
            targets: counterAnimation,
            counterValue: value,
            round: 1,
            easing: 'linear',
            duration: 1600,
            update: () => {
                let newNumber = numberWithCommas(counterAnimation.counterValue)
                this.refs['element'].text(newNumber);
            }
        });
        
        anime({
            targets: counterAnimation,
            counterValue: value,
            round: 1,
            easing: 'linear',
            duration: 1600,
            update: () => {
                let newNumber = numberWithCommas(counterAnimation.counterValue)
                this.refs['element'].text(newNumber);
            }
        });
    }
}
