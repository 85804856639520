

import Drop from 'tether-drop';
import util from './../util.js';


const SELECTORS = {
    dropdownToggle: '[data-toggle="lu-dropdown"], [data-dropdown]',
    dropdownParent: '.has-dropdown',
    dropdownMenu: '[data-dropdown-menu]',
    dropdownArrowTarget: '[data-arrow-target]',
    dropdownPlacement: '[data-placement]',
    dropdownOpen: '[data-open]',
    dropdownClasses: '[data-class]',
    width: '[data-width]'
};

const Default = {
    open: 'click',
    placement: 'bottom left',
    width: null,
    class: '',
    arrowPosition: true,
    clickableElements: 'a'
};

class Dropdown{
    constructor(dropdown, options){
        this.dropdown =  $(dropdown);
        this.options = options;

        this.dropdownParent = this.dropdown.closest(SELECTORS.dropdownParent);
        this.dropdownArrowTarget = $(this.dropdownParent).find(SELECTORS.dropdownArrowTarget);
        this.dropdownToggle = $(this.dropdownParent).find(SELECTORS.dropdownToggle);
        this.dropdownMenu = $(this.dropdownParent).find(SELECTORS.dropdownMenu);

        this.getConfig();
        this.initPlugin();
        this.bindEvents();

    }
    bindEvents(){
        this.dropdown.on('closeDropdown',this.close.bind(this));
        this.dropdown.on('openDropdown',this.open.bind(this));
        $(this.drop.drop).find(this.config.clickableElements).on('click',this.onClick.bind(this));
    }
    getConfig(){
        var dataConfig  = this.dropdown.data();

        if(dataConfig.options){
            this.dataOptions = util.parseDataOptions(dataConfig.options);
        }else{
            this.dataOptions = {};
        }
        this.config = $.extend({}, Default, dataConfig, this.dataOptions, this.options);
        this.setDropdownWidth();

        if(!this.dropdownArrowTarget.length){
            this.dropdownArrowTarget = this.dropdown;
        }
    }
    initPlugin(){
        this.drop = new Drop({
            target:  this.dropdown[0],
            content: this.dropdownMenu[0],
            position: this.config.placement,
            classes: this.config.class,
            openOn: this.config.open,
            tetherOptions: {
                constraints: [
                    {
                        to: 'window',
                        pin: ['right', 'left'],
                        attachment: 'together'
                    }
                ]
            }
        });
        if(this.config.onInit){
            this.config.onInit(this.drop);
        }

        if (typeof this.config.onInit === 'function') {
            this.config.onInit(this.drop);
        }

        this.arrow = $(this.drop.drop).find('[data-arrow]');

        this.drop.on('open', this.onOpen.bind(this));

        $(window).on('resize', ()=>{
            this.setArrowPostion();
        });

        this.setArrowPostion();

        this.drop.on('close', this.onClose.bind(this));

    }
    getArrowTargetPoition(){
        this.dropdownOffset = this.dropdown.offset();
        this.arrowTargetOffset = this.dropdownArrowTarget.offset();
        var arrowTargetWidth = this.dropdownArrowTarget.outerWidth();


        if(/left/i.test(this.config.placement)){
            this.arrowTargetPosition = (this.arrowTargetOffset.left - this.dropdownOffset.left) + (arrowTargetWidth/2);
        }else if(/right/i.test(this.config.placement)){
            this.arrowTargetPosition = this.dropdown.outerWidth() - (this.arrowTargetOffset.left - this.dropdownOffset.left) - (arrowTargetWidth/2);
        }
    }
    setArrowPostion(){

        if(!this.arrow){
            return
        }
        this.getArrowTargetPoition();

        var arrowBorderWidth = this.arrow.outerWidth()/2;
        this.pinnedCorrection = (this.dropdownMenu.offset().left + this.dropdownMenu.outerWidth()) - (this.dropdownOffset.left + this.dropdown.outerWidth());

        if(/center/i.test(this.config.placement)){
            this.arrow.css({
                left: '0',
                right: '0',
                margin: 'auto'
            });

        }else if(/left/i.test(this.config.placement)){

            this.arrow.css({
                left: this.arrowTargetPosition - arrowBorderWidth
            });

        }else if(/right/i.test(this.config.placement)){
            this.arrow.css({
                left: 'auto',
                right: this.arrowTargetPosition - arrowBorderWidth + this.pinnedCorrection
            });
        }

    }
    setDropdownWidth(){

        if(!this.config.width){
            return;
        }


        var width = parseInt(this.config.width);
        var parentWidth = this.dropdownParent.outerWidth();

        $(this.dropdownMenu).css({
            width: (width * parentWidth)/100,
            'min-width': (width * parentWidth)/100
        });

    }
    onOpen(){

        if(this.config.arrowPosition){
            this.setArrowPostion();
        }
        this.setDropdownWidth();

        if (typeof this.config.onOpen === 'function') {
            this.config.onOpen(this.drop);
        }
    }
    onClose(){
        if (typeof this.config.onClose === 'function') {
            this.config.onClose(this.drop);
        }
    }
    onClick(event){
        this.close();

        if (typeof this.config.onClick === 'function') {
            this.config.onClick(this.drop, event);
        }
    }
    open(){
        this.drop.open();
    }
    close(){
        this.drop.close();
    }
    position(){

        this.drop.position();
    }
}



function initDataSelectors(){
    $(SELECTORS.dropdownToggle).each(function(){
        new Dropdown($(this));
    });
}

function initJqueryPlugin(){

    $.fn.luDropdown = function( options) {
        return this.each(function() {

            const $this = $(this);
            let dropdown  = $this.data('lu-dropdown');

            if (typeof options === 'string') {
                if (typeof dropdown[options] === 'undefined') {
                    throw new Error(`No method named "${options}"`);
                }

                dropdown[options]();

            }else{

                dropdown =  new Dropdown($(this), options);
                $this.data('lu-dropdown', dropdown);

            }

        });
    };

}


function closeAllDropdowns(){
    for(let i=0; i < Drop.drops.length; i++){
        Drop.drops[i].close();
    }
}

const init = {
    initDataSelectors,
    initJqueryPlugin,
    closeAllDropdowns
};

export default init;
