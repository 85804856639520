import anime from 'animejs';

export default {
    refs:{},
    beforeInit(element, config){

        this.refs['element'] = element;
        this.refs['charts'] = element.find('[data-animation-chart]');
        this.refs['bars'] = element.find('[data-animation-bar]');
        this.refs['barValues'] = element.find('[data-animation-bar-value]');

    },
    init(element, config){

        for(let bar of Array.from(this.refs.bars)){
            let value = $(bar).data('animation-bar');
            if(!value){
                value = 50
            }

            let iconsAnimation = anime({
                targets: bar,
                height: [5, `${value}%`],
                duration: 1200,
                easing: 'easeInSine'
            })

        }


        for(let barValue of Array.from(this.refs.barValues)){

            let $barValue = $(barValue);
            let value = $barValue.data('animation-bar-value');

            let valueAnimation = {
                barValue: 0
            };

            anime({
                targets: valueAnimation,
                barValue: value,
                round: config.valueRound ? config.valueRound : 1,
                easing: 'linear',
                duration: 1200,
                update: () => {
                    $barValue.text(valueAnimation.barValue);
                }
            })
        }

    },
}
