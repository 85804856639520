/**
 * --------------------------------------------------------------------------
 * Name: Switcher(layersUI)
 * Version: Alpha
 * License: -
 * Website: http://layersui.com/
 * --------------------------------------------------------------------------
 */



const SELECTORS = {
    container: '[data-switcher]', 
    item: '[data-switcher-item]',
    itemHighlight: '[data-switcher-highlight]'
};

const Default = {
    activeElement: 2,
    transition: 'all 500ms cubic-bezier(.250, .250, .000, 1.015)'
};


 class Switcher{
    constructor(element , options){
        this.element = $(element);
        this.items = element.find(SELECTORS.item);
        this.itemHighlight = this.element.find(SELECTORS.itemHighlight);
        this.options = options;

        this.activeItemData = {};
        this.isInit = false;

        this.getConfig();
        this.setActiveItem(this.config.activeElement);
        this.bindEvents();
    }
    getConfig(){
        var dataConfig  = this.element.data();
        if(dataConfig.options){
            this.dataOptions = util.parseDataOptions(dataConfig.options);
        }else{
            this.dataOptions = {};
        }
        this.config = $.extend({}, Default, dataConfig, this.dataOptions, this.options);
    }
    bindEvents(){
        this.items.on('click', (event) => this.slideToItem(event.currentTarget));
        $(window).on('resize', (event) => this.onResize(event));
    }
    setActiveItem(itemIndex){
        this.slideToItem(this.items[itemIndex]);
    }
    getItemPosition(element){
        this.activeItemData.element = $(element);
        this.activeItemData.width = $(this.activeItemData.element).innerWidth();
        this.activeItemData.position = $(this.activeItemData.element).position().left;
    }
    slideToItem(element){

        if(this.activeItemData.element){
            this.activeItemData.element.removeClass('is-active');
        }

        this.getItemPosition(element);

        this.itemHighlight.css({
            'transform': 'translateX(' + this.activeItemData.position + 'px)',
            'width': this.activeItemData.width
        });
        
        if(this.isInit){
            this.itemHighlight.css({
                transition: this.config.transition
            });
            
        }else{
            this.onInit();
            this.isInit = true;
        }
        this.onSlide();
    }
    onInit(){

        if (typeof this.config.onInit === 'function') {

            this.config.onInit(this.element, this.activeItemData.element);

        }

    }
    onSlide(){
        if (typeof this.config.onSlide === 'function') {

            this.config.onSlide(this.element, this.activeItemData.element);

        }

        this.activeItemData.element.addClass('is-active');
    }
    onResize(){

        if (typeof this.config.onSlide === 'function') {

            this.config.onResize(this.element, this.activeItemData.element);


        }

        this.activeItemData.element.trigger('click');
    }
    
}



function initDataSelectors(){

    $( document ).ready(function() {
        $(SELECTORS.container).each(function(){
            new Switcher($(this));
        });
    });
    
}

function initJqueryPlugin(){

    $.fn.luSwitcher = function( options ) {
        return this.each(function() {
            var switcher = new Switcher($(this), options);
        });
    };

}

const init = {
    initDataSelectors,
    initJqueryPlugin,
};

export default init;
