
import Drop from 'tether-drop';
import util from './../util.js';

const SELECTORS = {
    toggle: '[data-toggle="lu-tooltip"], [data-tooltip]',
};

const Default = {
    openOn: 'hover',
    placement: 'top center',
    classes: 'tooltip',
};


class tooltip{
    constructor(tooltip, options){
        this.tooltip = $(tooltip);      
        this.options = options;

        this.getConfig();
        this.getContent();
        this.initPlugin();
    }
    getConfig(){
        var dataConfig  = this.tooltip.data();        
        if(dataConfig.options){
            this.dataOptions = util.parseDataOptions(dataConfig.options);
        }else{
            this.dataOptions = {};
        }
        this.config = $.extend({}, Default, dataConfig, this.dataOptions, this.options);
    }
    getContent(){
        
        if ($(this.tooltip).attr('data-title')){
            this.tooltipContent = $(this.tooltip).attr('data-title');
        }
        else{
            $(this.tooltip).attr('data-title', $(this.tooltip).attr('title'));
            $(this.tooltip).removeAttr('title');
            this.tooltipContent = $(this.tooltip).attr('data-title');
        }
    }
    initPlugin(){
        this.drop = new Drop({
            target:  this.tooltip[0],
            content: this.tooltipContent,
            position: this.config.placement,
            classes: this.config.classes,
            openOn: this.config.openOn,
        });     

        if (typeof this.config.onInit === 'function') {
            this.config.onInit(this.drop);
        }
        this.drop.on('open', this.onOpen.bind(this));  
        this.drop.on('close', this.onClose.bind(this));  
    }

    getArrowTargetPoition(){
        var tooltipwitdh = this.tooltip.outerWidth();
        
        if(/left/i.test(this.config.placement)){
            this.arrowTargetPosition = (tooltipwitdh/2);
        }else if(/right/i.test(this.config.placement)){
            this.arrowTargetPosition = (tooltipwitdh/2);
        }
    }

    setArrowPostion(){   
        $(this.drop.drop).find('.tooltip__arrow').remove();
        $(this.drop.drop).append('<div class="tooltip__arrow" data-arrow></div>');
        var arrow = $(this.drop.drop).find('[data-arrow]');
        
        if(!arrow){
            return;
        }
        this.getArrowTargetPoition();
        var arrowBorderWidth = arrow.outerWidth()/2;

        if(/center/i.test(this.config.placement)){
        
            arrow.css({
                left: '0',
                right: '0',
                margin: 'auto'
            });
        
        }else if(/left/i.test(this.config.placement)){
            arrow.css({
                left: this.arrowTargetPosition,
                marginLeft: -arrowBorderWidth
            });
        
        }else if(/right/i.test(this.config.placement)){
            arrow.css({
                left: 'auto',
                right: this.arrowTargetPosition,
                marginRight: -arrowBorderWidth
            });
        }
    }

    onOpen(){
        this.setArrowPostion();
        if (typeof this.config.onOpen === 'function') {
            this.config.onOpen(this.drop);
        }
    }
    onClose(){
        if (typeof this.config.onClose === 'function') {
            this.config.onClose(this.drop);
        }
    }
}


function initDataSelectors(){
    $(SELECTORS.toggle).each(function(){
        new tooltip($(this));
    });
}

function initJqueryPlugin(){
    $.fn.luTooltip = function( options ) {
        return this.each(function() {
            new tooltip($(this), options);
        });
    };

}
const init = {
    initDataSelectors,
    initJqueryPlugin,
};


export default init;
