import anime from 'animejs';

export default {
    refs:{},
    beforeInit(element, config){

        this.refs['element'] = element;
        this.refs['arrow'] = element.find('[data-animation-arrow]');
        this.refs['speed'] = element.find('[data-speedometer-speed]');
        this.refs['score'] = element.find('[data-animation-score]');
        this.refs['max-speed'] = element.find('[data-speedometer-speed]');

    },
    init(element, config){
        let value = config.value ? config.value : 50;

        let dialLength = (87.4986 - (value * this.refs['max-speed'][0].getTotalLength())/100).toFixed(4)
        let arrowAngle = ((value * 238)/100)


        anime({
            targets: [...Array.from(this.refs.speed)],
            strokeDashoffset: [anime.setDashoffset, dialLength],
            duration: 1200,
            easing: 'cubicBezier(.21,.55,.43,.75)'
        })

        anime({
            targets: [this.refs.arrow[0]],
            duration: 1200,
            rotate: `${arrowAngle}deg`,
            easing: 'cubicBezier(.21,.55,.43,.75)',
        })

        let scoreObject = {
            scoreValue: 0
        };

        anime({
            targets: scoreObject,
            scoreValue: value,
            duration: 1200,
            easing: 'cubicBezier(.21,.55,.43,.75)',
            update: () => {
                if(scoreObject.scoreValue != 0){
                    this.refs['score'].text(parseInt(scoreObject.scoreValue));
                }
            },
        });


    },
}
