
const buttons = [].slice.call(document.querySelectorAll('.btn--primary:not(.btn--outline):not(.btn--link):not(.no-hover-effect), .btn--light-overlay:not(.no-hover-effect), .btn--primary-light'));

export default {
    init() {

        buttons.forEach(function (item) {

            let span = document.createElement('span');
            span.className = 'btn-hover-effect';
            let hoverEffect = item.appendChild(span);

            item.onmousemove = (e) => {

                let rect = e.currentTarget.getBoundingClientRect();

                let x = e.clientX - rect.left;
                let y = e.clientY - rect.top;


                hoverEffect.style.left = x + 'px';
                hoverEffect.style.top = y + 'px';

            }

        });

    }
}
