/**
 * --------------------------------------------------------------------------
 * Name: Off-Canvas(layersUI)
 * Version: Alpha
 * License: -
 * Website: http://layersui.com/
 * --------------------------------------------------------------------------
 */



import util from './util.js';


const SELECTORS = {
    button: '[data-toggle="offCanvas"]',
    overlayers: '.overlayer',
    dismiss: '[data-dismiss="offCanvas"]'
};

const Default = {
    activeClass: 'is-open',
    mode: 'normal',
    overlayer: false,
    bodyClass: 'off-canvas-is-active'
};

function getScrollbarWidth() {
    let outer = document.createElement("div");
    outer.style.visibility = "hidden";
    outer.style.width = "100px";
    outer.style.msOverflowStyle = "scrollbar"; 

    document.body.appendChild(outer);

    let widthNoScroll = outer.offsetWidth;

    outer.style.overflow = "scroll";


    let inner = document.createElement("div");
    inner.style.width = "100%";
    outer.appendChild(inner);        
    let widthWithScroll = inner.offsetWidth;

    outer.parentNode.removeChild(outer);

    return widthNoScroll - widthWithScroll;
}
class OffCanvas{

    constructor(element, options){
        this.element = element;
        this.options = options;
        this.isOpen = false;

        this.getConfig();
        this.bindEvents();
    }
    getConfig(){

        var dataConfig  = this.element.data();

        if(dataConfig.options){
            this.dataOptions = util.parseDataOptions(dataConfig.options);
        }else{
            this.dataOptions = {};
        }

        this.config = $.extend({}, Default, dataConfig, this.dataOptions, this.options);
        this.target = $(this.config.target);
        this.dismiss = this.target.find(SELECTORS.dismiss);

    }
    bindEvents(){
        this.element.on('click',(event) => this.checkState(event));
        this.dismiss.on('click', () => this.close());

    }
    checkState(){

        if(this.target.hasClass(this.config.activeClass)){
            this.close();
        }else{
            this.open();
        }

    }
    open(evnet){
    
        this.isOpen = true
        this.element.trigger('off-canvas.open');

        $('body').css({
            'padding-right': getScrollbarWidth()
        })
        $(this.target).addClass(this.config.activeClass);
        $('body').addClass(this.config.bodyClass);
        $('html').css({
            'overflow': 'hidden'
        });

        $(document).on('click.off-cavnas touchstart.off-cavnas',(event) => this.addEventClickOutside(event));


    }
    close(event){

        if(this.isOpen == false){
            return;
        }

        this.element.trigger('off-canvas.close');
        this.isOpen = false;
        $(this.target).removeClass(this.config.activeClass);
        $('body').removeClass(this.config.bodyClass);
        $('body').attr('style', '');
        $('html').css('overflow', '');
        $(document).off('click.off-cavnas touchstart.off-cavnas');


    }
    addEventClickOutside(event){
        // $(event.target).closest('[data-dt-idx]').length - fix for data table
        if(!$(event.target).closest(this.target).length && !$(event.target).closest(this.element).length && !$(event.target).closest('[data-dt-idx]').length) {
            if($(this.target).is(":visible")) {
                this.close();
            }
        }

    }


}



function initDataSelectors(){
    $(SELECTORS.button).each(function(){
         new OffCanvas($(this))
    });
}

function initJqueryPlugin(){
    $.fn.luOffCanvas = function( options ) {

        return this.each(function() {
            const $this = $(this);
            let offCanvas  = $this.data('lu-off-canvas');

            if (typeof options === 'string') {

                if (typeof offCanvas[options] === 'undefined') {
                    throw new Error(`No method named "${options}"`);
                }

                offCanvas[options]();

            }else{

                offCanvas =  new OffCanvas($(this), options);
                $this.data('lu-off-canvas', offCanvas);

            }

        });

    };
}

const init = {
    initDataSelectors,
    initJqueryPlugin,
};

export default init;
