import anime from 'animejs';

class locationMapSwitcher{
    constructor(switcher) {
        this.switcher = $(switcher);
        if(!this.switcher){
            return;
        }
        this.highlight = this.switcher.find('[data-switcher-highlight]');
        this.item = this.switcher.find('[data-switcher-item]');
        this.location = $('body').find('[data-mode-switch]');
        this.counter = this.location.find('[data-animation-counter]');
        this.pins = this.location.find('[data-location-pin]');
        this.do_pins = this.location.find('[data-location-pin].locations-map__pin--do');
        this.not_do_pins = this.location.find('[data-location-pin]:not(.locations-map__pin--do)');
        this.bindEvents();
    }
    bindEvents(){
        this.highlight.on('click',(event)=>{
            this.clickButton(event);
        });
        this.item.on('click',(event)=>{
            this.switch(event, this);
        });
    }
    clickButton(event){
        let that = this;
        this.highlight.siblings('.switcher__items').find('.switcher__item').not('.is-active').trigger('click');
    }
    switch(event){
        if (!$(event.currentTarget).hasClass('is-active')){
            $('.switcher--locations-map').toggleClass('switcher--gray')
            this.switchMap(event);
        }
    }
    switchMap(event){
        this.location[0].classList.toggle('locations-map--vultr');
        this.location[0].classList.toggle('locations-map--do');
        let type = event.currentTarget.attributes['data-switcher-item'].value;
        this.mapAnimation(type);
    }
    mapAnimation(type){
        let pinDelay = 80,
            counterAnimation = {},
            counterAnimationDuration = (this.pins.length - this.do_pins.length) * 80;;

        if (type == "vultr"){
            counterAnimation = {
                counterStartValue: this.pins.length,
                counterValue: this.do_pins.length
            };
            for (let pin of Array.from(this.not_do_pins)) {

                anime({
                    targets: pin,
                    scale: [4.2, 1],
                    opacity: [0, 1],
                    delay: pinDelay,
                    duration: 480,
                    easing: 'cubicBezier(.16,0,0,1)',
                    begin: function(anim) {
                        $(pin).removeClass('is-hidden');
                    },
                });
                pinDelay = pinDelay + 80
    
            }
        }
        else{
            counterAnimation = {
                counterStartValue: this.do_pins.length,
                counterValue: this.pins.length
            };
            for (let pin of Array.from(this.not_do_pins)) {

                anime({
                    targets: pin,
                    scale: [1, 4.2],
                    opacity: [1, 0],
                    delay: pinDelay,
                    duration: 480,
                    easing: 'cubicBezier(.16,0,0,1)',
                    complete: function(anim) {
                        $(pin).addClass('is-hidden');
                    }
                });
                pinDelay = pinDelay + 80
            }
        }
        
        anime({
            targets: counterAnimation,
            counterValue: counterAnimation.counterStartValue,
            round: 1,
            easing: 'linear',
            duration: counterAnimationDuration,
            update: () => {
                this.counter.text(counterAnimation.counterValue)
            },
            complete: () => {
                setTimeout(() => {

                    //locationsMap.init();

                }, 500)
            }
        });
    }
}

export default {
    init() {
        $('[data-switcher]').each(function () {
            new locationMapSwitcher(this)
        });
    }
}
