import anime from 'animejs';
export default {
    refs:{},
    beforeInit(element, config){

        this.refs['element'] = element;
        this.refs['icons'] = element.find('[data-animation-icon]');

    },
    init(element, config){
        let iconsAnimation = anime({
            targets: [...Array.from(this.refs.icons)],
            translateY: {
                value: [16, 0],
                duration: 1000
            },
            opacity: {
                value: [0, 1],
                duration: 480
            },
            delay: function(el, i, l) {

                if(i == 0){
                    return 0;
                }else{
                    return i * 160;
                }

            },
            easing: 'easeInSine'
        });
    },
}
