import util from './../util.js';

const SELECTORS = {
    select: 'select',
    rawSelect: '.form-control--basic'
};

const Default = {
    maxItems: 1,
};

class Select{
    constructor(element, options){

        this.element = element;
        this.element.trigger('selectValue')

        this.options = options;
        this.getConfig();
        this.initPlugin();

    }
    initPlugin(){
		if ($(this.element).attr("id") == "filter_profiler") {
			return;
		}
        this.select = $(this.element).selectize({
            placeholder: '',
            maxItems: this.config.maxItems,
            createOnBlur: false,
            copyClassesToDropdown: false,
            allowEmptyOption: false,
            closeAfterSelect: true,
            onInitialize: () => {
                this.onInit();
            },
            onChange: (value) => {
                this.onChange(value);
            },
            onFocus: () => {
                this.onFocus();
            },
            onBlur: () => {
                this.onBlur();
            }
        });
        this.selectize = this.select[0].selectize;
    }
    getConfig(){
        var dataConfig  = this.element.data();
        if(dataConfig.options){
            this.dataOptions = util.parseDataOptions(dataConfig.options);
        }else{
            this.dataOptions = {};
        }
        this.config = $.extend({}, Default, dataConfig, this.dataOptions, this.options);
    }
    onInit(){
        if (typeof this.config.onInit === 'function') {
            this.config.onInit(value);
        }
    }
    onChange(value){

        if (typeof this.config.onChange === 'function') {
            this.config.onChange(value);
        }
        this.element.trigger('selectValue')
    }
    onFocus(event){
        if (typeof this.config.onFocus === 'function') {
            this.config.onFocus();
        }

    }
    onBlur(){
        if (typeof this.config.onBlur === 'function') {
            this.config.onBlur();
        }
    }
}

function initDataSelectors(){
    $(SELECTORS.select).not(SELECTORS.rawSelect).each(function(){
        new Select($(this));
    });
}

function initJqueryPlugin(){
    $.fn.luSelect = function( options ) {
        return this.each(function() {
            new Select($(this), options);
        });
    };
}

const init = {
    initDataSelectors,
    initJqueryPlugin
};

export default init;

