import breakpoint from './../utils/brakepoints';
import anime from 'animejs';
import scrollMonitor from 'scrollmonitor';


function randomInteger(min, max) {

    let number = Math.floor((Math.random() * (max - min + 1)) + min);

    return number;
}


class LocationsMap {

    constructor(map) {

        if(!map.length){
            return
        }

        this.currentAnimationIndex = 0;
        this.animationDelay  = 800;

        this.cacheDOM(map);
        this.bindEvents();
        this.initAnimation();
        this.initScrollWatchers();

    }
    cacheDOM(map) {

        this.map = map;
        this.pins = this.map.find('[data-location-pin]');
        this.pinsAnimation = this.pins.find('[data-location-pin-animation]');
        this.pinsDot = this.pins.find('[data-location-pin-dot]');

    }
    bindEvents() {

        $(window).on('visibilitychange', (event) => {

            if(document.hidden){
                this.pauseAnimation();
            }else{

                if(this.scrollWatcher.isInViewport){
                    this.playAnimations();
                }

            }

        });


        this.pinsDot
        .mouseenter((event) =>{

            let pin = $(event.currentTarget);
            pin.closest('[data-location-pin]').addClass('is-hover');

        })
        .mouseleave((event) =>{
            let pin = $(event.currentTarget);
            pin.closest('[data-location-pin]').removeClass('is-hover');
        });

    }
    initAnimation() {

        this.setDelay();
        this.animations = [];

        this.pins.each((index, item) => {

            let pinAnimation = $(item).find('[data-location-pin-animation] > span');

            let animation = anime({
                targets: [pinAnimation[0], pinAnimation[1]],
                scale: 5.8,
                opacity: [0.5, 0],
                border: 0,
                easing: 'linear',
                loop: 1,
                duration: 1600,
                autoplay: false,
                delay(target, index) {

                    if (index == 1) {

                        return 700;

                    } else {

                        return 0;

                    }
                }
            });
            this.animations.push(animation);
        });

    }
    playAnimations() {

        this.animations[this.currentAnimationIndex].restart();
        this.currentAnimationIndex++;
        this.timer = setInterval(() => {

            this.animations[this.currentAnimationIndex].restart();

            if (this.currentAnimationIndex < this.animations.length - 1) {
                this.currentAnimationIndex++;
            } else {
                this.currentAnimationIndex = 0;
            }

        }, this.animationDelay);

    }
    pauseAnimation(){
        clearInterval(this.timer);

        for (let animation of this.animations) {
            animation.pause();
            animation.seek(0);
        }
    }
    initScrollWatchers() {

        this.scrollWatcher = scrollMonitor.create(this.map);

        this.scrollWatcher.enterViewport(() => {

            this.playAnimations();
        });

        this.scrollWatcher.exitViewport(() => {

            this.pauseAnimation();

        });

    }
    setDelay(){

        let delay  = this.map.data('locations-map-delay');
        if(delay){
            this.animationDelay = delay;
        }
    }
}




export default {
    init() {
        new LocationsMap($("[data-locations-map]"));
    }
}
