import PerfectScrollbar from 'perfect-scrollbar';



export default{

    init(){

        $('[data-custom-scrollbar]').each(function(){

            new PerfectScrollbar(this, {
                wheelSpeed: 2,
                wheelPropagation: true,
                minScrollbarLength: 20
            });

        });

    }
}
