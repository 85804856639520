import Cookies from 'js-cookie';

const SELECTORS = {
    container: '[data-mode-switch]',
}

const Default = {
    cookieName: '',
    cookieExpTime: 365,
}

class ModeSwitch {
    constructor(container){
        this.cacheDom(container)
        this.getConfig()
        this.bindEvents()
        this.checkCookies()
        if(!this.config.cookieName || !this.config.cookieExpTime){
            return;
        }

    }
    cacheDom(container) {
        this.head = document.getElementsByTagName('HEAD')[0];
        this.sytleFile;
        this.container = $(container);
        this.switchBttns = this.container.find('button')
        this.switcher = this.container.find('[data-active-element]')
    }

    bindEvents(){
        this.switchBttns.on('click', event =>
        {
            let bttn = event.currentTarget

            setTimeout(() => {
                this.checkMode(bttn)
            }, 100);
           
        })
    }
    getConfig() {
        var dataConfig = this.container.data();
        this.config = $.extend({}, Default, dataConfig, this.dataOptions, this.options); 
    }

    addStyleFile(){
        this.sytleFile = document.createElement('link');
        this.sytleFile.rel = 'stylesheet'
        this.sytleFile.type = 'text/css';
        this.sytleFile.classList.add('dark-mode-style');
        this.sytleFile.href = '../assets/css/style-dark.css';
        this.head.appendChild(this.sytleFile)
    }
    removeStyleFile() {
        if(typeof this.sytleFile != 'undefined' || this.sytleFile){
            this.head.removeChild(this.sytleFile)
        }
    }
    
   
    checkMode(btn){
        let mode = btn.getAttribute('data-mode')
        if(btn.classList.contains('is-active') ){
            btn.setAttribute('disabled', '')
            Cookies.set(this.config.cookieName, mode, {
                expires: this.config.cookieExpTime
            });
           
            if(mode === "dark"){
                this.addStyleFile()
            }else{
                this.removeStyleFile()
            }
        }
        this.switchBttns.each(function(index, btn){
            if(! btn.classList.contains('is-active')){
                btn.removeAttribute('disabled')
            }
        })
    }
    checkCookies() {
        let cookies = Cookies.get(this.config.cookieName)
        let switcher = this.switcher
        let that = this;
        
        this.switchBttns.each(function (index, btn) {
            if (btn.getAttribute('data-mode') == cookies) {
                btn.setAttribute('disabled', '')
                switcher.attr('data-active-element', index)
            }
        })

        if(cookies === "dark"){
            that.addStyleFile()
        }else{
            that.removeStyleFile()
        }
    }
}
export default {
    init(){
        $(SELECTORS.container).each(function(){
            new ModeSwitch(this);
        });
    }
}