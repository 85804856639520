import anime from 'animejs';

import { fade, fadeInTop, fadeInBottom, fadeInBottomFast, serverAnimation, slideInBottom, slideInTop } from './utils';

export const kubernetesIllustration = {
    refs: {},
    beforeInit(element, config) {

        this.refs['element'] = element;
        this.refs['lift'] = element.find('[data-animation-lift]');
        this.refs['light'] = element.find('[data-animation-light]');
        this.refs['path'] = element.find('[data-animation-path]');

        this.refs['topElement'] = element.find('[data-cluster]');
        this.refs['bottomElement'] = element.find('[data-animation-bottom]');
        this.refs['shadowElement'] = element.find('[data-animation-shadow]');
        this.refs['mainLight'] = element.find('[data-animation-mlight]');

        this.refs['sidecluster'] = element.find('[data-sidecluster]');
        this.refs['sideclusterBottom'] = element.find('[data-sidecluster-bottom]');
        this.refs['sideclusterCenter'] = element.find('[data-sidecluster-center]');
        this.refs['sideclusterMiddle'] = element.find('[data-sidecluster-middle]');

        this.refs['sideclusterTop'] = element.find('[data-sidecluster-top]');
        // this.refs['lift'] = element.find('[data-animation-lift]');

        this.refs['cluster'] = element.find('[data-cluster]');
        this.refs['clusterBottom'] = element.find('[data-cluster-bottom]');
        this.refs['clusterCenter'] = element.find('[data-cluster-center]');
        this.refs['clusterTop'] = element.find('[data-cluster-top]');
    },
    init(element, config) {

        fade(this.refs.light, 160);
        // fadeInTop(this.refs.sideElements, 0, 32);
        fadeInBottomFast(this.refs.sideclusterTop, 0, -16);
        serverAnimation(this.refs['sidecluster'], this.refs['sideclusterMiddle'], this.refs['sideclusterCenter'], this.refs['sideclusterBottom']);


        serverAnimation(this.refs['cluster'], this.refs['clusterTop'], this.refs['clusterCenter'], this.refs['clusterBottom']);
        if (this.refs.topElement.length) {
            fadeInTop(this.refs.topElement, 0, 32);
            fadeInTop(this.refs.bottomElement, 0, 16);
            fadeInTop(this.refs.mainLight, 0, 32);
            // fadeInTop(this.refs.lift, 0, 32);
            fade(this.refs.shadowElement);

        }

        if (this.refs.path.length) {
            let leftPathAnimation = anime({
                targets: [this.refs.path[0]],
                strokeDashoffset: [anime.setDashoffset, 0],
                duration: 1000,
                easing: 'easeInSine',
                opacity: {
                    value: [0, 1],
                    duration: 480
                },
            });

            let rightPathAnimation = anime({
                targets: [this.refs.path[1]],
                strokeDashoffset: [anime.setDashoffset, 0],
                duration: 1000,
                easing: 'easeInSine',
                opacity: {
                    value: [0, 1],
                    duration: 480
                },
            });
        }
    }
}

export const mainIllustration = {
    refs: {},
    beforeInit(element, config) {

        this.refs['element'] = element;
        this.refs['sideElements'] = element.find('[data-animation-ssd], [data-animation-cpu], [data-animation-browser], [data-animation-server]');
        this.refs['sideElements2'] = element.find('[data-animation-ssd-2], [data-animation-cpu-2], [data-animation-browser-2], [data-animation-server-2]')
        this.refs['light'] = element.find('[data-animation-light]');
        this.refs['path'] = element.find('[data-animation-path]');

        this.refs['topElement'] = element.find('[data-animation-top]');
        this.refs['bottomElement'] = element.find('[data-animation-bottom]');
        this.refs['shadowElement'] = element.find('[data-animation-shadow]');

    },
    init(element, config) {
        fade(this.refs.light, 160)
        fadeInTop(this.refs.sideElements, 0, 32);

        if (this.refs.sideElements2.length) {
            fadeInTop(this.refs.sideElements2, 400, 32);
        }

        if (this.refs.topElement.length) {
            fadeInTop(this.refs.topElement, 0, 32);
            fadeInTop(this.refs.bottomElement, 0, 16);
            fade(this.refs.shadowElement);
        }

        if (this.refs.path.length) {
            let leftPathAnimation = anime({
                targets: [this.refs.path[0]],
                strokeDashoffset: [anime.setDashoffset, 0],
                duration: 1000,
                easing: 'easeInSine',
                opacity: {
                    value: [0, 1],
                    duration: 480
                },
            });

            let rightPathAnimation = anime({
                targets: [this.refs.path[1]],
                strokeDashoffset: [anime.setDashoffset, 0],
                duration: 1000,
                easing: 'easeInSine',
                opacity: {
                    value: [0, 1],
                    duration: 480
                },
            });
        }
    }
}


export const ddos = {
    refs: {},
    beforeInit(element) {
        this.refs['element'] = element;
        this.refs['sideElements'] = element.find('[data-animation-ssd], [data-animation-cpu], [data-animation-browser]');
        this.refs['light'] = element.find('[data-animation-light]');
        this.refs['path'] = element.find('[data-animation-path]');

        this.refs['wall'] = element.find('[data-animation-wall]');
        this.refs['arrowheads'] = element.find('[data-animation-arrowhead]');
        this.refs['targets'] = element.find('[data-animation-target]');

        this.refs['server'] = element.find('[data-server]');
        this.refs['serverTop'] = element.find('[data-server-top]');
        this.refs['serverCenter'] = element.find('[data-server-center]');
        this.refs['serverBottom'] = element.find('[data-server-bottom]');

        this.refs['shield'] = element.find('[data-animation-shield]');
    },
    init() {


        fade(this.refs.light, 160)
        fadeInTop(this.refs.sideElements, 0, 32);
        fadeInBottom(this.refs.wall, 0, 32);
        serverAnimation(this.refs['server'], this.refs['serverTop'], this.refs['serverCenter'], this.refs['serverBottom'])


        let leftPathAnimation = anime({
            targets: [this.refs.path[0]],
            strokeDashoffset: [anime.setDashoffset, 0],
            duration: 1000,
            easing: 'easeInSine',
            opacity: {
                value: [0, 1],
                duration: 480
            },
        });

        let rightPathAnimation = anime({
            targets: [this.refs.path[1]],
            strokeDashoffset: [anime.setDashoffset, 0],
            duration: 1000,
            easing: 'easeInSine',
            opacity: {
                value: [0, 1],
                duration: 480
            },
        });
        fade(this.refs.arrowheads, 1000)
        fade(this.refs.targets, 800)
        fadeInTop(this.refs.shield, 0, 8);
    }
}


export const directConnect = {
    refs: {},
    beforeInit(element) {
        this.refs['element'] = element;

        this.refs['path'] = element.find('[data-animation-path]');

        this.refs['blocks'] = element.find('[data-animation-block]');
        this.refs['trees'] = element.find('[data-animation-trees]');
        this.refs['light'] = element.find('[data-animation-light]');
        this.refs['centerBlock'] = element.find('[data-center-block]');

        this.refs['server'] = element.find('[data-server]');
        this.refs['serverTop'] = element.find('[data-server-top]');
        this.refs['serverCenter'] = element.find('[data-server-center]');
        this.refs['serverBottom'] = element.find('[data-server-bottom]');


    },
    init() {
        fade(this.refs.light, 0)
        fadeInTop(this.refs.trees, 300, 16);
        fadeInTop(this.refs['centerBlock'], 0);
        fadeInTop(this.refs['blocks'], 600);

        serverAnimation(this.refs['server'], this.refs['serverTop'], this.refs['serverCenter'], this.refs['serverBottom']);


        anime({
            targets: [this.refs.path[0]],
            strokeDashoffset: [anime.setDashoffset, 0],
            duration: 1000,
            easing: 'easeInSine',
            delay: 500,
            opacity: {
                value: [0, 1],
                duration: 480
            },
        });

        anime({
            targets: [this.refs.path[1]],
            strokeDashoffset: [anime.setDashoffset, 0],
            duration: 1000,
            easing: 'easeInSine',
            delay: 500,
            opacity: {
                value: [0, 1],
                duration: 480
            },
        });
    }
}

export const ipSpace = {
    refs: {},
    beforeInit(element) {
        this.refs['element'] = element;

        this.refs['path'] = element.find('[data-animation-path]');
        this.refs['light'] = element.find('[data-animation-light]');

        this.refs['globe'] = element.find('[data-animation-globe]');
        this.refs['lines'] = element.find('[data-animation-line]');
        this.refs['dots'] = element.find('[data-animation-dot]');

        this.refs['server'] = element.find('[data-server]');
        this.refs['serverTop'] = element.find('[data-server-top]');
        this.refs['serverCenter'] = element.find('[data-server-center]');
        this.refs['serverBottom'] = element.find('[data-server-bottom]');
    },
    init() {
        fade(this.refs.light, 0)
        serverAnimation(this.refs['server'], this.refs['serverTop'], this.refs['serverCenter'], this.refs['serverBottom']);
        fadeInTop(this.refs['globe']);
        anime({
            targets: [this.refs.path[0]],
            strokeDashoffset: [anime.setDashoffset, 0],
            duration: 1000,
            easing: 'easeInSine',
            delay: 500,
            opacity: {
                value: [0, 1],
                duration: 480
            },
        });

        anime({
            targets: [this.refs.path[1]],
            strokeDashoffset: [anime.setDashoffset, 0],
            duration: 1000,
            easing: 'easeInSine',
            delay: 500,
            opacity: {
                value: [0, 1],
                duration: 480
            },
        });
        fade(this.refs.globe, 0)

        anime({
            targets: [...Array.from(this.refs.lines)],
            strokeDashoffset: [anime.setDashoffset, 0],
            duration: 1000,
            delay: 800,
            easing: 'easeInSine',
            opacity: {
                value: [0, 1],
                duration: 480
            },
        });
        let startDelay = 1000;
        anime({
            targets: [...Array.from(this.refs.dots)],
            duration: 480,
            easing: 'easeInSine',
            delay: function (el, i, l) {
                return (i * 160) + startDelay;
            },
            opacity: {
                value: [0, 1],
            },
        });

    }
}

export const privateNetworking = {
    refs: {},
    beforeInit(element, config) {

        this.refs['light'] = element.find('[data-animation-light]');
        this.refs['sideElements'] = element.find('[data-animation-ssd], [data-animation-cpu], [data-animation-browser]');

        this.refs['server'] = element.find('[data-server]');
        this.refs['serverTop'] = element.find('[data-server-top]');
        this.refs['serverCenter'] = element.find('[data-server-center]');
        this.refs['serverBottom'] = element.find('[data-server-bottom]');
        this.refs['paths'] = element.find('[data-animation-path]');
        this.refs['buildings'] = element.find('[data-animation-building]');

        this.refs['shield'] = element.find('[data-animation-shield]');
        this.refs['pipe'] = element.find('[data-animation-pipe]');
        this.refs['map'] = element.find('[data-animation-map]');

    },
    init(element, config) {
        serverAnimation(this.refs['server'], this.refs['serverTop'], this.refs['serverCenter'], this.refs['serverBottom'])
        fadeInTop(this.refs.buildings, 0, 16)

        anime({
            targets: Array.from(this.refs.paths),
            strokeDashoffset: [anime.setDashoffset, 0],
            duration: 1000,
            easing: 'easeInSine',
            opacity: {
                value: [0, 1],
                duration: 480
            },
        })

        fade(this.refs.light, 160)
        fadeInTop(this.refs.sideElements, 0, 32)
        fadeInTop(this.refs.shield, 0, 8)
        fadeInTop(this.refs.map, 0, 16)
        fade(this.refs.pipe)

    }
}

export const gaming = {
    refs: {},
    beforeInit(element, config) {

        this.refs['element'] = element;
        this.refs['sideElements'] = element.find('[data-animation-ssd], [data-animation-cpu], [data-animation-browser], [data-animation-pad]');
        this.refs['light'] = element.find('[data-animation-light]');
        this.refs['path'] = element.find('[data-animation-path]');

        this.refs['bullets'] = element.find('[data-animation-bullet]');

        this.refs['topElement'] = element.find('[data-animation-top]');
        this.refs['bottomElement'] = element.find('[data-animation-bottom]');
        this.refs['shadowElement'] = element.find('[data-animation-shadow]');

    },
    init(element, config) {

        fade(this.refs.light, 160)
        fadeInTop(this.refs.sideElements, 0, 32);

        if (this.refs.topElement.length) {
            fadeInTop(this.refs.topElement, 0, 32);
            fadeInTop(this.refs.bottomElement, 0, 16);
            fade(this.refs.shadowElement);
        }

        fadeInTop(this.refs.bullets, 0, 16);

        if (this.refs.path.length) {
            let leftPathAnimation = anime({
                targets: [this.refs.path[0]],
                strokeDashoffset: [anime.setDashoffset, 0],
                duration: 1000,
                easing: 'easeInSine',
                opacity: {
                    value: [0, 1],
                    duration: 480
                },
            });

            let rightPathAnimation = anime({
                targets: [this.refs.path[1]],
                strokeDashoffset: [anime.setDashoffset, 0],
                duration: 1000,
                easing: 'easeInSine',
                opacity: {
                    value: [0, 1],
                    duration: 480
                },
            });
        }

    }
}

export const highFrequency = {
    refs: {},
    beforeInit(element, config) {

        this.refs['element'] = element;
        this.refs['sideElements'] = element.find('[data-animation-ssd], [data-animation-cpu], [data-animation-browser]');
        this.refs['light'] = element.find('[data-animation-light]');
        this.refs['path'] = element.find('[data-animation-path]');

        this.refs['topElement'] = element.find('[data-animation-top]');
        this.refs['bottomElement'] = element.find('[data-animation-bottom]');
        this.refs['shadowElement'] = element.find('[data-animation-shadow]');

        this.refs['mainLight'] = element.find('[data-animation-main-light]');
        this.refs['lights'] = element.find('[data-animation-lights] rect');


    },
    init(element, config) {

        fade(this.refs.light, 160)
        fadeInTop(this.refs.sideElements, 0, 32);

        if (this.refs.topElement.length) {
            fadeInTop(this.refs.topElement, 0, 32);
            fadeInTop(this.refs.bottomElement, 0, 16);
            fade(this.refs.shadowElement);
        }

        if (this.refs.path.length) {
            let leftPathAnimation = anime({
                targets: [this.refs.path[0]],
                strokeDashoffset: [anime.setDashoffset, 0],
                duration: 1000,
                easing: 'easeInSine',
                opacity: {
                    value: [0, 1],
                    duration: 480
                },
            });

            let rightPathAnimation = anime({
                targets: [this.refs.path[1]],
                strokeDashoffset: [anime.setDashoffset, 0],
                duration: 1000,
                easing: 'easeInSine',
                opacity: {
                    value: [0, 1],
                    duration: 480
                },
            });
        }


        fadeInTop(this.refs.mainLight, 0, 16);
        let lightDelay = 500;
        for (let light of Array.from(this.refs.lights)) {
            anime({
                targets: [light],
                translateY: [
                    {
                        value: [16, 0],
                        duration: 1000
                    },
                    {
                        value: [0, 0],
                        duration: 480
                    }
                ],
                opacity: [
                    {
                        value: [0, 1],
                        duration: 480
                    },
                    {
                        value: [1, 0],
                        duration: 600
                    }
                ],
                delay: lightDelay,
                loop: true,
                easing: 'easeInSine'
            });
            lightDelay += 200
        }

    }
}

export const optimizedCloud = {
    refs: {},
    beforeInit(element, config) {

        this.refs['element'] = element;
        this.refs['sideElements'] = element.find('[data-animation-ssd], [data-animation-cpu], [data-animation-browser]');
        this.refs['light'] = element.find('[data-animation-light]');
        this.refs['path'] = element.find('[data-animation-path]');

        this.refs['topElement'] = element.find('[data-animation-top]');
        this.refs['bottomElement'] = element.find('[data-animation-bottom]');
        this.refs['shadowElement'] = element.find('[data-animation-shadow]');

        this.refs['mainLight'] = element.find('[data-animation-main-light]');
        this.refs['lights'] = element.find('[data-animation-lights] path');


    },
    init(element, config) {

        fade(this.refs.light, 160)
        fadeInTop(this.refs.sideElements, 0, 32);

        if (this.refs.topElement.length) {
            fadeInTop(this.refs.topElement, 0, 32);
            fadeInTop(this.refs.bottomElement, 0, 16);
            fade(this.refs.shadowElement);
        }


        if (this.refs.path.length) {
            let leftPathAnimation = anime({
                targets: [this.refs.path[0]],
                strokeDashoffset: [anime.setDashoffset, 0],
                duration: 1000,
                easing: 'easeInSine',
                opacity: {
                    value: [0, 1],
                    duration: 480
                },
            });

            let rightPathAnimation = anime({
                targets: [this.refs.path[1]],
                strokeDashoffset: [anime.setDashoffset, 0],
                duration: 1000,
                easing: 'easeInSine',
                opacity: {
                    value: [0, 1],
                    duration: 480
                },
            });
        }

        fadeInTop(this.refs.mainLight, 0, 16);

        let lightDelay = 500;
        for (let light of Array.from(this.refs.lights)) {
            anime({
                targets: [light],
                translateY: [
                    {
                        value: [16, 0],
                        duration: 1000
                    },
                    {
                        value: [0, 0],
                        duration: 480
                    }
                ],
                opacity: [
                    {
                        value: [0, 1],
                        duration: 480
                    },
                    {
                        value: [1, 0],
                        duration: 600
                    }
                ],
                delay: lightDelay,
                loop: true,
                easing: 'easeInSine'
            });
            lightDelay += 200
        }

    }
}

export const bareMetal = {
    refs: {},
    beforeInit(element, config) {

        this.refs['element'] = element;
        this.refs['sideElements'] = element.find('[data-animation-ssd], [data-animation-cpu], [data-animation-browser]');
        this.refs['light'] = element.find('[data-animation-light]');
        this.refs['path'] = element.find('[data-animation-path]');

        this.refs['topElement'] = element.find('[data-animation-top]');
        this.refs['bottomElement'] = element.find('[data-animation-bottom]');
        this.refs['shadowElement'] = element.find('[data-animation-shadow]');

        this.refs['mainLight'] = element.find('[data-animation-main-light]');
        this.refs['lights'] = element.find('[data-animation-lights] rect');


    },
    init(element, config) {

        fade(this.refs.light, 160)
        fadeInTop(this.refs.sideElements, 0, 32);

        if (this.refs.topElement.length) {
            fadeInTop(this.refs.topElement, 0, 32);
            fadeInTop(this.refs.bottomElement, 0, 16);
            fade(this.refs.shadowElement);
        }

        anime({
            targets: [...Array.from(this.refs.path)],
            strokeDashoffset: [anime.setDashoffset, 0],
            duration: 1000,
            easing: 'easeInSine',
            opacity: {
                value: [0, 1],
                duration: 480
            },
        });

        fadeInTop(this.refs.mainLight, 0, 16);
        let lightDelay = 500;
        for (let light of Array.from(this.refs.lights)) {
            anime({
                targets: [light],
                translateY: [
                    {
                        value: [16, 0],
                        duration: 1000
                    },
                    {
                        value: [0, 0],
                        duration: 480
                    }
                ],
                opacity: [
                    {
                        value: [0, 1],
                        duration: 480
                    },
                    {
                        value: [1, 0],
                        duration: 600
                    }
                ],
                delay: lightDelay,
                loop: true,
                easing: 'easeInSine'
            });
            lightDelay += 200
        }
    }
}

export const objectStorage = {
    refs: {},
    beforeInit(element, config) {
        this.refs['element'] = element;
        this.refs['sideElements'] = element.find('[data-animation-bucket]');
        this.refs['light'] = element.find('[data-animation-light]');
        this.refs['path'] = element.find('[data-animation-path]');

        this.refs['topElement'] = element.find('[data-animation-top]');
        this.refs['bottomElement'] = element.find('[data-animation-bottom]');
        this.refs['shadowElement'] = element.find('[data-animation-shadow]');
    },
    init(element, config) {
        fadeInTop(this.refs.sideElements, 0, 16);
        fade(this.refs.light, 160)
        fadeInTop(this.refs.topElement, 0, 32);
        fadeInTop(this.refs.bottomElement, 0, 16);
        fade(this.refs.shadowElement);

        anime({
            targets: [this.refs.path[0]],
            strokeDashoffset: [anime.setDashoffset, 0],
            duration: 1000,
            easing: 'easeInSine',
            opacity: {
                value: [0, 1],
                duration: 480
            },
        });

        anime({
            targets: [this.refs.path[1]],
            strokeDashoffset: [anime.setDashoffset, 0],
            duration: 1000,
            easing: 'easeInSine',
            opacity: {
                value: [0, 1],
                duration: 480
            },
        });
    }
}

export const referralProgram = {
    refs: {},
    beforeInit(element, config) {
        this.refs['element'] = element;
        this.refs['path'] = element.find('[data-animation-path]');
        this.refs['topElement'] = element.find('[data-animation-top]');
        this.refs['shadowElement'] = element.find('[data-animation-shadow]');
        this.refs['sideElements'] = element.find('[data-animation-left], [data-animation-right]');
    },
    init(element, config) {
        fadeInTop(this.refs.sideElements, 0, 32);

        if (this.refs.topElement.length) {
            fadeInTop(this.refs.topElement, 0, 32);
            fade(this.refs.shadowElement);
        }

        if (this.refs.path.length) {
            let leftPathAnimation = anime({
                targets: [this.refs.path[0]],
                strokeDashoffset: [-900, 0],
                duration: 1000,
                easing: 'easeInSine',
                opacity: {
                    value: [0, 1],
                    duration: 480
                },
            });

            let rightPathAnimation = anime({
                targets: [this.refs.path[1]],
                strokeDashoffset: [-900, 0],
                duration: 1000,
                easing: 'easeInSine',
                opacity: {
                    value: [0, 1],
                    duration: 480
                },
            });
        }
    }
}

export const cloudCompute = {
    refs: {},
    beforeInit(element, config) {

        this.refs['element'] = element;
        this.refs['sideElements'] = element.find('[data-animation-ssd], [data-animation-cpu], [data-animation-browser], [data-animation-server]');
        this.refs['sideElements2'] = element.find('[data-animation-ssd-2], [data-animation-cpu-2], [data-animation-browser-2], [data-animation-server-2]')
        this.refs['light'] = element.find('[data-animation-light]');
        this.refs['path'] = element.find('[data-animation-path]');

        this.refs['topElement'] = element.find('[data-animation-top]');
        this.refs['bottomElement'] = element.find('[data-animation-bottom]');
        this.refs['shadowElement'] = element.find('[data-animation-shadow]');

        this.refs['mainLight'] = element.find('[data-animation-main-light]');
        this.refs['lights'] = element.find('[data-animation-lights] path');
    },
    init(element, config) {
        fade(this.refs.light, 160)
        fadeInTop(this.refs.sideElements, 0, 32);

        if (this.refs.sideElements2.length) {
            fadeInTop(this.refs.sideElements2, 400, 32);
        }

        if (this.refs.topElement.length) {
            fadeInTop(this.refs.topElement, 0, 32);
            fadeInTop(this.refs.bottomElement, 0, 16);
            fade(this.refs.shadowElement);
        }

        if (this.refs.path.length) {
            let leftPathAnimation = anime({
                targets: [this.refs.path[0]],
                strokeDashoffset: [anime.setDashoffset, 0],
                duration: 1000,
                easing: 'easeInSine',
                opacity: {
                    value: [0, 1],
                    duration: 480
                },
            });

            let rightPathAnimation = anime({
                targets: [this.refs.path[1]],
                strokeDashoffset: [anime.setDashoffset, 0],
                duration: 1000,
                easing: 'easeInSine',
                opacity: {
                    value: [0, 1],
                    duration: 480
                },
            });
        }


        fadeInTop(this.refs.mainLight, 0, 16);

        let lightDelay = 500;
        for (let light of Array.from(this.refs.lights)) {
            anime({
                targets: [light],
                translateY: [
                    {
                        value: [16, 0],
                        duration: 1000
                    },
                    {
                        value: [0, 0],
                        duration: 480
                    }
                ],
                opacity: [
                    {
                        value: [0, 1],
                        duration: 480
                    },
                    {
                        value: [1, 0],
                        duration: 600
                    }
                ],
                delay: lightDelay,
                loop: true,
                easing: 'easeInSine'
            });
            lightDelay += 200
        }
    }
}


export const mainIllustrationVfx = {
    refs: {},
    beforeInit(element, config) {

        this.refs['element'] = element;
        this.refs['sideElements'] = element.find('[data-animation-ssd], [data-animation-cpu], [data-animation-browser], [data-animation-server]');
        this.refs['sideElements2'] = element.find('[data-animation-ssd-2], [data-animation-cpu-2], [data-animation-browser-2], [data-animation-server-2]')
        this.refs['lightLeft'] = element.find('[data-animation-light-left]');
        this.refs['lightRight'] = element.find('[data-animation-light-right]');
        this.refs['path'] = element.find('[data-animation-path]');

        this.refs['topElement'] = element.find('[data-animation-top]');
        this.refs['bottomElement'] = element.find('[data-animation-bottom]');
        this.refs['shadowElement'] = element.find('[data-animation-shadow]');

    },
    init(element, config) {
        fade(this.refs.lightLeft, 500);
        fade(this.refs.lightRight, 400);
        fadeInTop(this.refs.sideElements, 0, 32);

        if (this.refs.sideElements2.length) {
            fadeInTop(this.refs.sideElements2, 400, 32);
        }

        if (this.refs.topElement.length) {
            fadeInTop(this.refs.topElement, 0, 32);
            fadeInTop(this.refs.bottomElement, 0, 16);
            fade(this.refs.shadowElement);
        }

        if (this.refs.path.length) {
            let leftPathAnimation = anime({
                targets: [this.refs.path[0]],
                strokeDashoffset: [anime.setDashoffset, 0],
                duration: 1000,
                easing: 'easeInSine',
                opacity: {
                    value: [0, 1],
                    duration: 480
                },
            });

            let rightPathAnimation = anime({
                targets: [this.refs.path[1]],
                strokeDashoffset: [anime.setDashoffset, 0],
                duration: 1000,
                easing: 'easeInSine',
                opacity: {
                    value: [0, 1],
                    duration: 480
                },
            });
        }
    }
}

export const blockchain = {
    refs: {},
    beforeInit(element, config) {
        this.refs['element'] = element;
        this.refs['shadow'] = element.find('[data-animation-shadow]');
        this.refs['bottom'] = element.find('[data-animation-bottom]');
        this.refs['block'] = element.find('[data-animation-block]');
        this.refs['server'] = element.find('[data-animation-server]');
        this.refs['front'] = element.find('[data-animation-front]');
        this.refs['path'] = element.find('[data-animation-path]');
        this.refs['sideElements'] = element.find('[data-animation-cpu]');

    },
    init(element, config) {
        fadeInTop(this.refs.sideElements, 200, 32);
        fade(this.refs.shadow);
        fadeInBottom(this.refs.block, 200, 32);
        fadeInBottom(this.refs.server, 200, 32);
        fadeInBottom(this.refs.bottom, 0, 32);
        fadeInTop(this.refs.front, 200, 32);
        if (this.refs.path.length) {
            let leftPathAnimation = anime({
                targets: [this.refs.path[0]],
                strokeDashoffset: [anime.setDashoffset, 0],
                duration: 1000,
                easing: 'easeInSine',
                opacity: {
                    value: [0, 1],
                    duration: 480
                },
            });
            let centerPathAnimation = anime({
                targets: [this.refs.path[1]],
                strokeDashoffset: [1139, 0],
                delay: 700,
                duration: 1000,
                easing: 'easeInSine',
                opacity: {
                    value: [0, 1],
                    duration: 280
                },
            });
            let rightPathAnimation = anime({
                targets: [this.refs.path[2]],
                strokeDashoffset: [anime.setDashoffset, 0],
                duration: 1000,
                easing: 'easeInSine',
                opacity: {
                    value: [0, 1],
                    duration: 480
                },
            });
        }
    }
}

export const highPerformance = {
    refs: {},
    beforeInit(element, config) {
        this.refs['element'] = element;
        this.refs['shadow'] = element.find('[data-animation-shadow]');
        this.refs['bottom'] = element.find('[data-animation-bottom]');
        this.refs['block'] = element.find('[data-animation-block]');
        this.refs['lightMain'] = element.find('[data-animation-light-main]');
        this.refs['light'] = element.find('[data-animation-light]');
        this.refs['path'] = element.find('[data-animation-path]');
        this.refs['arrows'] = element.find('[data-animation-arrows]');
        this.refs['sideElements'] = element.find('[data-animation-ssd], [data-animation-cpu]');


    },
    init(element, config) {
        fade(this.refs.light, 160)
        fadeInTop(this.refs.sideElements, 0, 32);

        fadeInTop(this.refs.block, 20, 32);
        fadeInTop(this.refs.bottom, 0, 16);
        fade(this.refs.shadow);
        fade(this.refs.lightMain, 900);
        fadeInTop(this.refs.arrows, 900, -8);

        if (this.refs.path.length) {
            let leftPathAnimation = anime({
                targets: [this.refs.path[0]],
                strokeDashoffset: [anime.setDashoffset, 0],
                duration: 1000,
                easing: 'easeInSine',
                opacity: {
                    value: [0, 1],
                    duration: 480
                },
            });
            let rightPathAnimation = anime({
                targets: [this.refs.path[1]],
                strokeDashoffset: [anime.setDashoffset, 0],
                duration: 1000,
                easing: 'easeInSine',
                opacity: {
                    value: [0, 1],
                    duration: 480
                },
            });
        }
    }
}


export const pixelStreaming = {
    refs: {},
    beforeInit(element, config) {
        this.refs['element'] = element;
        this.refs['shadow'] = element.find('[data-animation-shadow]');
        this.refs['bottom'] = element.find('[data-animation-bottom]');
        this.refs['block'] = element.find('[data-animation-block]');
        this.refs['top'] = element.find('[data-animation-top]');
        this.refs['light'] = element.find('[data-animation-light]');
        this.refs['sideElements'] = element.find('[data-animation-cpu]');
        this.refs['path'] = element.find('[data-animation-path]');
        this.refs['arrow'] = element.find('[data-animation-arrow]');
        this.refs['subpath'] = element.find('[data-animation-subpath]');


    },
    init(element, config) {
        fade(this.refs.light, 160)
        fadeInTop(this.refs.sideElements, 0, 32);
        fadeInTop(this.refs.top, 50, 16);
        fadeInTop(this.refs.block, 0, 32);
        fadeInTop(this.refs.bottom, 0, 16);
        fade(this.refs.shadow);

        if (this.refs.path.length) {
            let leftPathAnimation = anime({
                targets: [this.refs.path[0]],
                strokeDashoffset: [anime.setDashoffset, 0],
                duration: 1000,
                easing: 'easeInSine',
                opacity: {
                    value: [0, 1],
                    duration: 480
                },
            });

            anime({
                targets: [this.refs.subpath[1]],
                strokeDashoffset: [-1134, 1],
                duration: 1000,
                delay: 300,
                easing: 'easeInSine',
                opacity: {
                    value: [0, 1],
                    duration: 480
                },
            });
            anime({
                targets: [this.refs.subpath[0]],
                strokeDashoffset: [-1134, 0],
                duration: 1000,
                delay: 600,
                easing: 'easeInSine',
                opacity: {
                    value: [0, 1],
                    duration: 480
                },
            });
            anime({
                targets: [this.refs.subpath[2]],
                strokeDashoffset: [-1134, 2],
                delay: 900,
                duration: 1000,
                easing: 'easeInSine',
                opacity: {
                    value: [0, 1],
                    duration: 480
                },
            });


            let rightPathAnimation = anime({
                targets: [this.refs.path[1]],
                strokeDashoffset: [-934, 0],
                duration: 1000,
                delay: 1600,
                easing: 'easeInSine',
                opacity: {
                    value: [0, 1],
                    duration: 480
                },
            });


           anime({
                targets: [this.refs.arrow[0]],
                duration: 300,
                delay: 600,
                opacity: {
                    value: [0, 1],
                    duration: 480
                },
            });
            anime({
                targets: [this.refs.arrow[1]],
                duration: 100,
                delay: 1300,
                opacity: {
                    value: [0, 1],
                    duration: 480
                },
            }); 
            anime({
                targets: [this.refs.arrow[2]],
                duration: 100,
                delay: 1700,
                opacity: {
                    value: [0, 1],
                    duration: 480
                },
            }); 
            anime({
                targets: [this.refs.arrow[3]],
                duration: 100,
                delay: 2300,
                opacity: {
                    value: [0, 1],
                    duration: 480
                },
            }); 
        }
    }
    
}

