const SELECTORS = {
    form: '[data-ps-strength]',
    input: '[data-ps-strength-input]',
    tooltip: '[data-ps-strength-tooltip]',
    progress: '[data-ps-strength-progress]'
};


// Password Requirements
function oneUpercaseCharacter(password) {

    let uppercaseLetters = password.match(/[A-Z]/g);

    if (uppercaseLetters) {
        return true
    } else {
        return false
    }
}

function oneLowercaseCharacter(password) {

    let lowercaseLetters = password.match(/[a-z]/g);

    if (lowercaseLetters) {
        return true
    } else {
        return false
    }
}

function oneNumber(password) {

    let digits = password.match(/[0-9]/g);

    if (digits) {
        return true
    } else {
        return false
    }
}

function tenCharacters(password) {
    if (password.length >= 10) {

        return true

    } else {

        return false;

    }
}


class PasswordStrength {
    constructor(form) {

        this.blockSubmit = true;

        this.cacheDOM(form);
        this.bindEvents();

    }
    cacheDOM(form) {

        this.form = $(form);
        this.passwordInput = this.form.find(SELECTORS.input);
        this.tooltip = this.form.find(SELECTORS.tooltip);
        this.progress = this.form.find(SELECTORS.progress);

        // Requirements list
        this.uppercase = this.tooltip.find('[data-ps-uppercase]');
        this.lowercase = this.tooltip.find('[data-ps-lowercase]');
        this.number = this.tooltip.find('[data-ps-number]');
        this.minCharacters = this.tooltip.find('[data-ps-min-characters]');

    }
    bindEvents() {
        this.form.on('submit', (event) => {

            if(this.blockSubmit){
                this.showIcon();
                this.showTooltip();
                event.preventDefault();
            }

        });

        this.passwordInput.on('focus', () => {

            this.showIcon();
            this.showTooltip();

        });

        this.passwordInput.on('blur', (event) => {

            if(event.target.value.length < 1){
                this.hideIcon();
            }

            this.hideTooltip()
        });

        this.passwordInput.on('keyup', (event) => {

            if(event.target.value.length >= 1){
                this.showIcon();
            }

            this.checkRequirements(event)
        });

        this.form.on('form.open-tooltip', (event) => {
            this.showTooltip();
        });


    }
    showTooltip() {
        
        this.tooltip.addClass('is-active');
        this.form.addClass('tooltip-is-open');

    }
    hideTooltip() {

        this.tooltip.removeClass('is-active');
        this.form.removeClass('tooltip-is-open');

    }
    showIcon(){

        this.form.addClass('show-password-danger');

    }
    hideIcon(){

        this.form.removeClass('show-password-danger');

    }
    checkRequirements(event) {

        let password = event.target.value;
        let counter = 0;

        if (oneUpercaseCharacter(password)) {

            counter++;
            this.uppercase.addClass('is-done');

        } else {

            this.uppercase.removeClass('is-done');

        }

        if (oneLowercaseCharacter(password)) {

            counter++;
            this.lowercase.addClass('is-done');

        } else {

            this.lowercase.removeClass('is-done');

        }

        if (oneNumber(password)) {

            counter++;
            this.number.addClass('is-done');

        } else {

            this.number.removeClass('is-done');

        }

        if (tenCharacters(password)) {

            counter++;
            this.minCharacters.addClass('is-done');

        } else {

            this.minCharacters.removeClass('is-done');

        }


        this.updateProgressBar(counter);

        if(counter >= 4){

            this.blockSubmit = false;
            this.form.addClass('password-is-strong');

        }else{

            this.blockSubmit = true;
            this.form.removeClass('password-is-strong');

        }

    }
    updateProgressBar(counter){
        this.progress.removeClass('is-week is-medium is-strong');

        switch(true){

            case counter > 0 && counter <= 2:
            this.progress.addClass('is-week');
            break;

            case counter == 3:
            this.progress.addClass('is-medium');
            break;

            case counter == 4:
            this.progress.addClass('is-strong');
            break;

        }
    }
}


export default {
    init() {
        $(SELECTORS.form).each(function(){
            new PasswordStrength(this)
        });
    }
}
