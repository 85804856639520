import scrollMonitor from 'scrollmonitor';
import animationTypes from './types/index.js';
import cloneDeep from 'lodash/cloneDeep';
import util from './../../../../assets-ui/js/components/util.js';
import brakepoints from './../../utils/brakepoints';


const SELECTORS = {
    container: '[data-animation]'
};
const Default = {
    delay: 0,
    offset: -200,
    mobileOffset: -100,
    mobileAnimation: false
};

class ElementAnimation {
    constructor(element) {

        this.element = $(element);
        this.isInitiated = false;
        this.getConfig();
        if(this.config.customInit){

            this.element.on('initAnimation', ()=>{
                this.initWatchers();
            });

        }
    }
    getConfig() {

        var dataConfig = this.element.data();

        if (dataConfig.animationOptions) {
            this.dataOptions = util.parseDataOptions(dataConfig.animationOptions);
        } else {
            this.dataOptions = {};
        }
        this.config = $.extend({}, Default, dataConfig, this.dataOptions, this.options);


    }
    initWatchers() {

        this.elementWatcher = scrollMonitor.create(this.element[0], {
            top: this.config.offset,
            bottom: this.config.offset
        });

        this.elementWatcher.enterViewport(() => {

            this.initAnimation();

        });



    }

    initAnimation() {
        if(this.isInitiated){
            return false;
        }
        try{

            this.animationType =  cloneDeep(animationTypes[this.config.type]);
            if (this.animationType)
			{
	            this.isInitiated = true;

	            this.animationType.beforeInit(this.element, this.config);

	            setTimeout(() => {

	                this.animationType.init(this.element, this.config);

	            }, this.config.delay);
	        }
	        else
	        {
	        	console.log('Unknown animation type ' + this.config.type);
	        }

        }catch(error){
            console.log(error)
            console.log(this.config.type)
        }

    }
    removeWatchers() {
        if(this.elementWatcher){
            this.elementWatcher.destroy();
        }
    }
}

export default {
    init() {
        let containers = $(SELECTORS.container)
        let animations = []

        for (let container of Array.from(containers)) {
            animations.push(new ElementAnimation(container))
        };

        let onlyDesktopAnimations = animations.filter((animation)=>{
            if(animation.config.mobileAnimation){
                animation.initWatchers()
                return false
            }else{
                return true
            }
        })

        brakepoints.up('lg', {
            match() {

                setTimeout(() => {
                    for (let animation of onlyDesktopAnimations) {
                        animation.initWatchers()
                    };
                },100)
            },
            unmatch() {
                for (let animation of animations) {

                    animation.removeWatchers()

                };
            },
        });
    }
}
