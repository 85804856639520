import anime from 'animejs';
import {fade, fadeOut, fadeInTop, serverAnimation, fadeInBottom} from './utils';
import {default as showScreens} from './screens';


const noAnimation = {
    refs: {},
    beforeInit(element) {
    },
    init() {

        return  new Promise(function(resolve, reject) {
            resolve();
        })
    },
    hide() {

        return  new Promise(function(resolve, reject) {
            resolve();
        })
    }
};

const gallery = {
    refs: {},
    animations:{},
    beforeInit(element) {

        this.refs['element'] = $(element);
        this.refs['photos'] = this.refs['element'].find('[data-photo]');

    },
    init() {

        let photoAnimation = anime({
            targets: [...Array.from(this.refs.photos)],
            opacity: {
                value: [0, 1],
                duration: 480
            },
            delay: function(el, i, l) {

                if(i == 0){
                    return 0;
                }else{
                    return i * 160;
                }

            },
            easing: 'easeInSine'
        });

        return photoAnimation.finished;

    },
    hide() {


        let photoAnimation = anime({
            targets: [...Array.from(this.refs.photos)],

            opacity: {
                value: [1, 0],
                duration: 300
            },
            delay: function(el, i, l) {

                if(i == 0){
                    return 0;
                }else{
                    return i * 80;
                }

            },
            easing: 'easeInSine'
        });

        return photoAnimation.finished;
    },
};


const backups = {
    refs: {},
    beforeInit(element) {

        this.refs['element'] = element;

        this.refs['server'] = element.find('[data-server]');
        this.refs['serverTop'] = element.find('[data-server-top]');
        this.refs['serverCenter'] = element.find('[data-server-center]');
        this.refs['serverBottom'] = element.find('[data-server-bottom]');
        this.refs['light'] = element.find('[data-animation-light]');


        this.refs['pathBottom'] = element.find('[data-animation-path-bottom]');
        this.refs['pathTop'] = element.find('[data-animation-path-top]');

        this.refs['screen'] = element.find('[data-animation-screen]');
        this.refs['icon'] = element.find('[data-animation-icon]');
        this.refs['book'] = element.find('[data-animation-book]');
        this.refs['block'] = element.find('[data-animation-block]');
        this.refs['pages'] =  this.refs['element'].find('[data-animation-page]');

    },
    init() {
        this.refs['element'].attr('style', '');

        let serverAnimationPromise = serverAnimation(this.refs['server'], this.refs['serverTop'], this.refs['serverCenter'], this.refs['serverBottom'])
        fadeInTop(this.refs['screen'], 0, 8);
        fadeInTop(this.refs['icon'], 0);
        fadeInTop(this.refs['book'], 0, 32);
        fadeInTop(this.refs['block'], 0, 16);
        fadeInTop(this.refs['light'], 0, 16);

        let pageDelay = 400;
        for(let page of Array.from(this.refs.pages)){
            fadeInBottom([page], pageDelay, -8);
            pageDelay = pageDelay + 200;
        }

        anime({
            targets: [...Array.from(this.refs['pathBottom'])],
            strokeDashoffset: [518.729, 0],
            duration: 1000,
            easing: 'easeInSine',
        });

        anime({
            targets: [...Array.from(this.refs['pathTop'])],
            strokeDashoffset: [31, 0],
            delay: 600,
            duration: 600,
            easing: 'easeInSine',
        });

        return serverAnimationPromise;

    },
}

const snapshot = {
    refs: {},
    beforeInit(element) {

        this.refs['server'] = element.find('[data-server]');
        this.refs['serverTop'] = element.find('[data-server-top]');
        this.refs['serverCenter'] = element.find('[data-server-center]');
        this.refs['serverBottom'] = element.find('[data-server-bottom]');


        this.refs['screen'] = element.find('[data-animation-screen]');
        this.refs['serverConnections'] = element.find('[data-animation-server-connection]');
        this.refs['addonLight'] = element.find('[data-animation-addon-light]');

        this.refs['light'] = element.find('[data-animation-light]');
        this.refs['block'] = element.find('[data-animation-block]');

        this.refs['line1'] = element.find('[data-animation-line-1]');
        this.refs['line2'] = element.find('[data-animation-line-2]');
        this.refs['line3'] = element.find('[data-animation-line-3]');
        this.refs['camera'] = element.find('[data-animation-camera]');
        this.refs['cameraView'] = element.find('[data-animation-camera-view]');


    },
    init() {
        serverAnimation([this.refs['server'][0]], [this.refs['serverTop'][0]], [this.refs['serverCenter'][0]], [this.refs['serverBottom'][0]], 400);
        serverAnimation([this.refs['server'][1]], [this.refs['serverTop'][1]], [this.refs['serverCenter'][1]], [this.refs['serverBottom'][1]]);

        fadeInTop(this.refs['block'], 0, 8);
        fadeInTop(this.refs['light'], 0, 16);
        fadeInTop(this.refs['addonLight'], 0, 16);
        fade(this.refs['serverConnections'], 800, 16);

        fadeInTop(this.refs['camera'], 0, 16);
        fadeInTop(this.refs['cameraView'], 400, 16);
        fadeInTop(this.refs['screen'], 0, 8);

        anime({
            targets: [...Array.from(this.refs['line1'])],
            strokeDashoffset: [anime.setDashoffset, 0],
            delay: 600,
            duration: 600,
            easing: 'easeInSine',
        });

        anime({
            targets: [...Array.from(this.refs['line2'])],
            strokeDashoffset: [anime.setDashoffset, 0],
            delay: 600,
            duration: 600,
            easing: 'easeInSine',
        });

        anime({
            targets: [...Array.from(this.refs['line3'])],
            strokeDashoffset: [anime.setDashoffset, 0],
            delay: 600,
            duration: 600,
            easing: 'easeInSine',
        });


    },
}


const ddos = {
    refs: {},
    beforeInit(element) {
        this.refs['screen'] = element.find('[data-animation-screen]');
        this.refs['arrows'] = element.find('[data-animation-arrow]');
        this.refs['arrowheads'] = element.find('[data-animation-arrowhead]');
        this.refs['wall'] = element.find('[data-animation-wall]');
        this.refs['computer'] = element.find('[data-animation-computer]');
        this.refs['line'] = element.find('[data-animation-line]');
        this.refs['targets'] = element.find('[data-animation-target]');
        this.refs['shield'] = element.find('[data-animation-shield]');

        this.refs['light'] = element.find('[data-animation-light]');
        this.refs['block'] = element.find('[data-animation-block]');
    },
    init() {
        fadeInTop(this.refs['screen'], 0, 8);
        fadeInTop(this.refs['wall'], 0, 8);
        fadeInTop(this.refs['computer'], 0, 16);

        fade(this.refs['shield'], (el, i)=>{
            return 400 + (140 * i)
        });

        fadeInTop(this.refs['block'], 0, 8);
        fadeInTop(this.refs['light'], 0, 16);

        anime({
            targets: [...Array.from(this.refs['arrows'])],
            strokeDashoffset: [anime.setDashoffset, 0],
            delay: 600,
            duration: 600,
            easing: 'easeInSine',
        });

        fade(this.refs['arrowheads'], 1200);
        anime({
            targets: [...Array.from(this.refs['line'])],
            strokeDashoffset: [anime.setDashoffset, 0],
            delay: 600,
            duration: 800,
            easing: 'easeInSine',
        });

        let targetDelay = 0;
        for(let target of Array.from(this.refs['targets'])){
            fade([target], targetDelay);
            targetDelay += 400
        }
    },
}


const firewall = {
    refs: {},
    beforeInit(element) {
        this.refs['element'] = element;

        this.refs['server'] = element.find('[data-server]');
        this.refs['serverTop'] = element.find('[data-server-top]');
        this.refs['serverCenter'] = element.find('[data-server-center]');
        this.refs['serverBottom'] = element.find('[data-server-bottom]');

        this.refs['clouds'] = element.find('[data-animation-cloud]');
        this.refs['light'] = element.find('[data-animation-light]');
        this.refs['shield'] = element.find('[data-animation-shield]');
        this.refs['lines'] = element.find('[data-animation-line]');
        this.refs['wall'] = element.find('[data-animation-wall]');

    },
    init() {

        serverAnimation(this.refs['server'], this.refs['serverTop'], this.refs['serverCenter'], this.refs['serverBottom'])
        fadeInTop(this.refs['clouds'], 0, 16);
        fadeInTop(this.refs['light'], 0, 8);
        fadeInTop(this.refs['shield'], 0, 8);
        fadeInTop(this.refs['wall'], 0, 32);

        anime({
            targets: [...Array.from(this.refs['lines'])],
            strokeDashoffset: [anime.setDashoffset, 0],
            delay: 600,
            duration: 800,
            easing: 'easeInSine',
        });
    }
}

const networking = {
    refs: {},
    beforeInit(element) {

        this.refs['server'] = element.find('[data-server]');
        this.refs['serverTop'] = element.find('[data-server-top]');
        this.refs['serverCenter'] = element.find('[data-server-center]');
        this.refs['serverBottom'] = element.find('[data-server-bottom]');

        this.refs['lines'] = element.find('[data-animation-line]');
        this.refs['screen'] = element.find('[data-animation-screen]');
        this.refs['tubes'] = element.find('[data-animation-tube]');
        this.refs['block'] = element.find('[data-animation-block]');
        this.refs['light'] = element.find('[data-animation-light]');
        this.refs['shield'] = element.find('[data-animation-shield]');

    },
    init() {
        anime({
            targets: [...Array.from(this.refs['lines'])],
            strokeDashoffset: [anime.setDashoffset, 0],
            delay: 600,
            duration: 800,
            easing: 'easeInSine',
        });
        fadeInTop([this.refs['tubes'][1]], 0, 16);
        fadeInTop([this.refs['tubes'][0]], 0, 8);
        fadeInTop(this.refs['screen'], 0, 8);
        fadeInTop(this.refs['block'], 0, 16);
        fadeInTop(this.refs['light'], 0, 8);
        fadeInTop(this.refs['shield'], 240, 8);


        serverAnimation(this.refs['server'], this.refs['serverTop'], this.refs['serverCenter'], this.refs['serverBottom'])
    }
}

const optimizedCloudCpu = {
    refs: {},
    beforeInit(element, config) {

        this.refs['lines'] = element.find('[data-animation-line]');
        this.refs['centerElement'] = element.find('[data-animation-center-element]')

        this.refs['rightPanel'] = element.find('[data-animation-right-panel]')
        this.refs['leftPanel'] = element.find('[data-animation-left-panel]')
        this.refs['leftBluePanel'] = element.find('[data-animation-left-blue-panel]')

        this.refs['lights'] = element.find('[data-animation-lights]')
    },
    init(element, config) {

        fadeInTop(this.refs.rightPanel)
        fadeInBottom(this.refs.centerElement, 0, -48)

        fadeInTop(this.refs.leftPanel, 400, 64)
        fadeInTop(this.refs.leftBluePanel, 560, 24)

        fade(this.refs.lights, 1300)

        anime({
            targets: [...Array.from(this.refs['lines'])],
            strokeDashoffset: [anime.setDashoffset, 0],
            delay: 1000,
            duration: 1000,
            opacity: {
                value: [0, 1],
                duration: 100
            },
            easing: 'easeInSine',
        });
    }
}




export default {
    showScreens,
    backups,
    snapshot,
    ddos,
    firewall,
    networking,
    gallery,
    noAnimation,
    optimizedCloudCpu
}
