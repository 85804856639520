import anime from 'animejs';

export default {
    refs:{},
    beforeInit(element, config){

        this.refs['element'] = element;
        this.refs['icon'] = element.find('[data-animation-icon]');
        this.refs['arrow'] = element.find('[data-animation-arrow]');

    },
    init(element, config){

        let iconAnimation = anime({
            targets: this.refs.icon[0],
            translateY: {
                value: [32, 0],
                duration: 1000
            },
            opacity: {
                value: [0, 1],
                duration: 480
            },
            easing: 'easeInSine'
        });


        let arrowAnimation;
        if (this.refs.arrow.length) {
            let arrowAnimation = anime({
                targets: this.refs.arrow[0],
                translateX: {
                    value: [-16, 0],
                    duration: 1000
                },
                opacity: {
                    value: [0, 1],
                    duration: 480
                },
                delay: 160,
                easing: 'easeInSine'
            });

            Promise.all([iconAnimation.finished, arrowAnimation.finished]).then(() => {
                this.refs.element.removeClass('is-animated')
            });

        } else {

            iconAnimation.finished.then(() => {
                this.refs.element.removeClass('is-animated')
            });

        }
    }
}
