import anime from 'animejs';

export default {
    refs:{},
    beforeInit(element, config){
        this.refs['speedometers'] = $(element).find('[data-speedometer]');
    },
    init(element, config){
        let delay = 0;
        let duration = 420;

        for(let speedometer of Array.from(this.refs.speedometers)){

            let $speedometer = $(speedometer);
            let arrow = $speedometer.find('[data-speedometer-arrow]');
            let speedomentrValue = arrow.data('speedometer-arrow');
            let speedPath = $speedometer.find('[data-speedometer-speed]');

            let scoreElement = $speedometer.parent().find('[data-animation-score]');
            let scoreValue = scoreElement.data('animation-score');

            if(speedomentrValue == 0){
                continue;
            }

            // 235deg - max arrow angle
            // 87.4986 - dial lenght

            let arrowAngle = ((speedomentrValue * 238)/100)
            let dialLength = (87.4986 - (speedomentrValue * 87.4986)/100).toFixed(4)

            let speedleftPathAnimation = anime({
                targets: [speedPath[0]],
                strokeDashoffset: [anime.setDashoffset, dialLength],
                duration: duration,
                delay: delay,
                easing: 'cubicBezier(.21,.55,.43,.75)'
            });


            let arrowAnimation = anime({
                targets: arrow[0],
                duration: duration,
                rotate: `${arrowAngle}deg`,
                delay: delay,
                easing: 'cubicBezier(.21,.55,.43,.75)',
                complete:()=>{
                    $speedometer.removeClass('is-animated');
                }
            });


            let scoreObject = {
                scoreValue: 0
            };

            anime({
                targets: scoreObject,
                scoreValue: scoreValue,
                duration: duration,
                delay: delay,
                easing: 'cubicBezier(.21,.55,.43,.75)',
                update: () => {
                    if(scoreObject.scoreValue != 0){
                        scoreElement.text(parseInt(scoreObject.scoreValue));
                    }
                },
            });
            duration = duration + 160

        }
    },
}
