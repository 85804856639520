import scrollMonitor from 'scrollmonitor';
import brakepoints from './../utils/brakepoints';
import Swiper from 'swiper/bundle';
import dropdown from '../../../assets-ui/js/components/dropdowns/dropdown'

window.swiper = new Swiper({
    el: '.marketplace__gallery .swiper-container',
    initialSlide: 0,
    spaceBetween: 40,
    slidesPerView: 1,
    centeredSlides: true,
    slideToClickedSlide: true,
    grabCursor: true,
    autoHeight: true,
});

function mobileNavbarScrollIntoView() {
    $('.site-navbar .nav__link').click(e => {
        if(brakepoints.is('mobile') || brakepoints.is('tablet')) {
            $(e.currentTarget).parents('.navbar__menu')[0].scrollTop = 0
        }
    })
}
mobileNavbarScrollIntoView()

function closeDropdownsOnResize() {
    if($('.marketplace__categories-dropdown').length > 0) {
        $(window).resize(e => {
            dropdown.closeAllDropdowns()
        })
    }
}

function tilesSlider() {

    $('[data-tiles-slider] [data-tiles-slider-item]').on('click', function (event) {

        let slider = $(this).closest('[data-tiles-slider]');
        let sliderContainer = $(this).closest('[data-screens-slider]');
        let pagination = sliderContainer.find('[data-slider-pagination]');
        let item = $(this)
        let index = item.data('tiles-slider-item');
        sliderContainer.find(`[data-slide-to="${index}"]`).trigger('click')
        event.preventDefault();
        slider.find('.is-active').removeClass('is-active');

        pagination.find('.is-active').removeClass('is-active');
        pagination.find(`[data-slide-to="${index}"]`).addClass('is-active');

        item.closest('content-slider__item').trigger('click');
        item.addClass('is-active');

    });
};

function linkTo() {
    $('body').on('click','[data-link-to]', (event) => {
        event.preventDefault();

        let item = $(event.currentTarget);
        let url = item.data('link-to');
        let hash = url.substring(url.indexOf('#'));

        $(`[href="${hash}"]`).tab('show');

        $('[data-nav]').trigger('close-nav');

        window.location = url;

    })
};

function addAttachements (){
    $(document).on('change', '#inputAttachments', function () {
        if ($(this).val() != 0) {
            var path = $(this).val();
            var filename = path.replace(/^.*\\/, "");
            extraTicketAttachment();

            $(this).siblings('label').find('span').text(filename);
            $(this).parent().removeClass('form-control form-control--lg').addClass('attachments__btn--added');
            $(this).siblings('label').find('i').html('<span class="icon-file"><svg class="icon-ui icon-ui--24" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 24 24"><path class="fill stroke" d="M13,9h6v12H5V3h8V9z"></path></svg></span><span class="icon-delete"><svg class="icon-ui icon-ui--24" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 24 24"><path class="fill stroke" d="M5,5V3h14v2H5z M18,21H6V9h12V21z"></path></svg></span>')

            $(this).removeAttr('id');
            $(this).removeAttr('for');

            $(this).siblings('label').on('click', function (event) {
                $(this).remove();
                event.preventDefault();
            });
        }
    });
}

function extraTicketAttachment() {
    var i = 0;
    jQuery(".attachments").prepend('<div class="attachments__btn form-control form-control--lg"><input type="file" id="inputAttachments" name="attachments[]" class="attachments__input" /><label for="inputAttachments" class="attachments__label"><i><svg class="icon-ui icon-ui--18" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"><path class="stroke" d="M3,9h12"></path><path class="stroke" d="M9,3v12"></path></svg></i><span>Click to add files</span></label></div>');
}

function modalSubscribe(){
    $('#modal-subscribe').on('click', (event)=>{
        event.stopPropagation();
    })
}



function setNavItemWidthVariable(){

    if(brakepoints.is('desktop') || brakepoints.is('tablet')){

        $('[data-main-menu] .nav__item.has-dropdown').each((index, item)=>{
            let $item = $(item)
            $item.css("--parentItemWidth", $item.innerWidth()+'px');
        })

    }

    $(window).on('resize ', ()=>{
        setTimeout(()=>{

            if(brakepoints.is('desktop') || brakepoints.is('tablet')){
                $('[data-main-menu] .nav__item.has-dropdown').each((index, item)=>{
                    let $item = $(item)
                    $item.css("--parentItemWidth", $item.innerWidth()+'px');
                })
            }

        },50)

    });
}


function stickySectionHeader() {
    if (document.querySelector('[data-sticky-starter]')) {
        let stickyStart = document.querySelector("[data-sticky-starter]");
        let stickyWrapper = document.querySelector("[data-sticky-wrapper]");
        let bounding = stickyStart.getBoundingClientRect();
        var isInViewport = function (elem) {
            var bounding = elem.getBoundingClientRect();
            return (
                bounding.top <= 30 &&
                bounding.left >= 0 &&
                bounding.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
                bounding.right <= (window.innerWidth || document.documentElement.clientWidth)
            );
        };


        window.addEventListener(
            "scroll",
            function (event) {
                stickyStart = document.querySelector("[data-sticky-starter]");
                let stickyEnd = document.querySelector("[data-sticky-end]");
                if (isInViewport(stickyStart)) {
                    stickyWrapper.classList.remove("hidden");
                    if (stickyEnd.getBoundingClientRect().top < 0) {
                        stickyWrapper.classList.add('hidden');
                    }
                }
                else {
                    stickyWrapper.classList.add('hidden');
                }
            },
            false);
    }
}


export default {
    init() {
        closeDropdownsOnResize();
        tilesSlider();
        linkTo();
        addAttachements();
        modalSubscribe();
        setNavItemWidthVariable();
        stickySectionHeader();
    }
}
