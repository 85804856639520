import anime from 'animejs';
import { fade, fadeInTop, fadeInBottom, serverAnimation, slideInBottom, slideInTop } from './utils';

export const innovators = {
    refs: {},
    beforeInit(element, config) {

        this.refs['element'] = element;
        this.refs['arrow'] = element.find('[data-animation-arrow]');
        this.refs['path'] = element.find('[data-animation-path]');
        this.refs['path'] = element.find('[data-animation-path]');
        this.refs['path'] = element.find('[data-animation-path]');
        this.refs['path'] = element.find('[data-animation-path]');
        this.refs['browser'] = element.find('[data-animation-browser]');
        this.refs['bulb'] = element.find('[data-animation-bulb]');
        this.refs['screen'] = element.find('[data-animation-screen]');
        this.refs['money'] = element.find('[data-animation-money]');
        this.refs['money'] = element.find('[data-animation-money]');
    },
    init(element, config) {

        fadeInBottom(this.refs.arrow, 0, 64);
        fadeInBottom(this.refs.browser, 0, 32);
        fadeInTop(this.refs.bulb, 0, 32);
        fadeInTop(this.refs.screen, 0, 48);
        fadeInTop([this.refs.money[0]], 0, 32);
        fadeInTop([this.refs.money[1]], 0, 48);
        fadeInTop(this.refs.screen, 0, 32);

        let PathAnimation1 = anime({
            targets: [this.refs.path[0]],
            strokeDashoffset: [227, 0],
            duration: 800,
            delay: 700,
            easing: 'linear',
            opacity: {
                value: [0, 1],
                duration: 50
            },
        });

        let PathAnimation2 = anime({
            targets: [this.refs.path[1]],
            strokeDashoffset: [58, 0],
            duration: 300,
            delay: 400,
            easing: 'linear',
            opacity: {
                value: [0, 1],
                duration: 200
            },
        });
        let PathAnimation3 = anime({
            targets: [this.refs.path[2]],
            strokeDashoffset: [-194, 0],
            duration: 1000,
            delay: 500,
            easing: 'linear',
            opacity: {
                value: [0, 1],
                duration: 50
            },
        });
        let PathAnimation4 = anime({
            targets: [this.refs.path[3]],
            strokeDashoffset: [-98, 0],
            duration: 500,
            delay: 1500,
            easing: 'linear',
            opacity: {
                value: [0, 1],
                duration: 200
            },
        });
    }

}


export const serviceProviders = {
    refs: {},
    beforeInit(element, config) {

        this.refs['element'] = element;
        this.refs['path'] = element.find('[data-animation-path]');
        this.refs['path'] = element.find('[data-animation-path]');
        this.refs['user'] = element.find('[data-animation-user]');
        this.refs['center'] = element.find('[data-animation-center]');
        this.refs['block'] = element.find('[data-animation-block]');
        this.refs['block'] = element.find('[data-animation-block]');
        this.refs['block'] = element.find('[data-animation-block]');
        this.refs['screen'] = element.find('[data-animation-screen]');



    },
    init(element, config) {

        fadeInBottom(this.refs.user, 0, 48);
        fadeInBottom(this.refs.center, 0, 32);
        fadeInTop([this.refs.block[0]], 0, 32);
        fadeInTop([this.refs.block[1]], 0, 48);
        fadeInTop([this.refs.block[2]], 0, 64);
        fadeInTop(this.refs.screen, 0, 48);

        let PathAnimation1 = anime({
            targets: [this.refs.path[0]],
            strokeDashoffset: [756, 0],
            duration: 1000,
            delay: 600,
            easing: 'linear',
            opacity: {
                value: [0, 1],
                duration: 200
            },
        });

        let PathAnimation2 = anime({
            targets: [this.refs.path[1]],
            strokeDashoffset: [-55, 0],
            duration: 400,
            delay: 1600,
            easing: 'linear',
            opacity: {
                value: [0, 1],
                duration: 200
            },
        });
        let PathAnimation3 = anime({
            targets: [this.refs.path[2]],
            strokeDashoffset: [-53, 0],
            duration: 600,
            delay: 500,
            easing: 'linear',
            opacity: {
                value: [0, 1],
                duration: 200
            },
        });
    }
}

export const illustrationResellers = {
    refs: {},
    beforeInit(element, config) {

        this.refs['element'] = element;
        this.refs['cloud'] = element.find('[data-animation-cloud]');
        this.refs['user3'] = element.find('[data-animation-user-3]');
        this.refs['user2'] = element.find('[data-animation-user-2]');
        this.refs['user1'] = element.find('[data-animation-user-1]');
        this.refs['path'] = element.find('[data-animation-path]');
        this.refs['suitcase'] = element.find('[data-animation-suitcase]');
        this.refs['coinstack'] = element.find('[data-animation-coin-stack]');
        this.refs['coinstack'] = element.find('[data-animation-coin-stack]');



    },
    init(element, config) {

        fadeInTop([this.refs.cloud[0]], 0, 32);
        fadeInBottom(this.refs.user3, 0, 32);
        fadeInBottom(this.refs.user1, 0, 48);
        fadeInBottom(this.refs.user2, 0, 32);
        fadeInTop([this.refs.coinstack[0]], 0, 32);
        fadeInTop([this.refs.coinstack[1]], 0, 48);
        fadeInTop(this.refs.suitcase, 0, 48);



        let leftPathAnimation = anime({
            targets: [this.refs.path[0]],
            strokeDashoffset: [1107, 0],
            duration: 1000,
            delay: 600,
            easing: 'easeInSine',
            opacity: {
                value: [0, 1],
                duration: 480
            },
        });

        // let centerPathAnimation = anime({
        //     targets: [this.refs.path[1]],
        //     strokeDashoffset: [48,0],
        //     duration: 500,
        //     delay: 1300,
        //     easing: 'linear',
        //     // opacity: {
        //     //     value: [0, 1],
        //     //     duration: 480
        //     // },
        // });

        // let rightPathAnimation = anime({
        //     targets: [this.refs.path[2]],
        //     strokeDashoffset: [-200, 0],
        //     duration: 1000,
        //     delay: 600,
        //     easing: 'easeInSine',
        //     opacity: {
        //         value: [0, 1],
        //         duration: 480
        //     },
        // });

    }
}

export const sideClusterSectionWide = {
    refs: {},
    beforeInit(element, config) {

        this.refs['element'] = element;
        this.refs['light'] = element.find('[data-animation-light]');
        this.refs['cluster'] = element.find('[data-widecluster]');
        this.refs['clusterBottom'] = element.find('[data-widecluster-bottom]');
        this.refs['clusterCenter'] = element.find('[data-widecluster-center]');
        this.refs['clusterTop'] = element.find('[data-widecluster-top]');


    },
    init(element, config) {

        fade([this.refs.light[0]], 160);
        fade([this.refs.light[1]], 160);
        serverAnimation(this.refs['cluster'], this.refs['clusterTop'], this.refs['clusterCenter'], this.refs['clusterBottom'])

    }
}

export const vultrContainerStorageInterface = {
    refs: {},
    beforeInit(element, config) {

        this.refs['element'] = element;
        this.refs['path'] = element.find('[data-animation-path]');
        this.refs['browser'] = element.find('[data-animation-browser]');
        this.refs['cluster'] = element.find('[data-cluster]');
        this.refs['clusterBottom'] = element.find('[data-cluster-bottom]');
        this.refs['clusterCenter'] = element.find('[data-cluster-center]');
        this.refs['clusterTop'] = element.find('[data-cluster-top]');
        this.refs['serverleft'] = element.find('[data-serverleft]');
        this.refs['serverleftBottom'] = element.find('[data-serverleft-bottom]');
        this.refs['serverleftCenter'] = element.find('[data-serverleft-center]');
        this.refs['serverleftTop'] = element.find('[data-serverleft-top]');
        this.refs['serverright'] = element.find('[data-serverright]');
        this.refs['serverrightBottom'] = element.find('[data-serverright-bottom]');
        this.refs['serverrightCenter'] = element.find('[data-serverright-center]');
        this.refs['serverrightTop'] = element.find('[data-serverright-top]');


    },
    init(element, config) {

        fadeInBottom(this.refs.browser, 0, -32);
        serverAnimation(this.refs['cluster'], this.refs['clusterTop'], this.refs['clusterCenter'], this.refs['clusterBottom'])
        serverAnimation(this.refs['serverleft'], this.refs['serverleftTop'], this.refs['serverleftCenter'], this.refs['serverleftBottom'])
        serverAnimation(this.refs['serverright'], this.refs['serverrightTop'], this.refs['serverrightCenter'], this.refs['serverrightBottom'])

        let leftPathAnimation = anime({
            targets: [this.refs.path[0]],
            strokeDashoffset: [274, 0],
            duration: 1000,
            easing: 'easeInSine',
            opacity: {
                value: [0, 1],
                duration: 480
            },
        });

        let centerPath1Animation = anime({
            targets: [this.refs.path[1]],
            strokeDashoffset: [29, 0],
            duration: 1000,
            easing: 'easeInSine',
            opacity: {
                value: [0, 1],
                duration: 480
            },
        });
        let centerPath2Animation = anime({
            targets: [this.refs.path[2]],
            strokeDashoffset: [22, 0],
            duration: 1000,
            easing: 'easeInSine',
            opacity: {
                value: [0, 1],
                duration: 480
            },
        });

        let rightPathAnimation = anime({
            targets: [this.refs.path[3]],
            strokeDashoffset: [583, 0],
            duration: 1000,
            easing: 'easeInSine',
            opacity: {
                value: [0, 1],
                duration: 480
            },
        });
        let rightPathShort1Animation = anime({
            targets: [this.refs.path[4]],
            strokeDashoffset: [-203, 0],
            duration: 1000,
            easing: 'easeInSine',
            opacity: {
                value: [0, 1],
                duration: 480
            },
        });
        let rightPathShort2Animation = anime({
            targets: [this.refs.path[5]],
            strokeDashoffset: [292, 0],
            duration: 1000,
            easing: 'easeInSine',
            opacity: {
                value: [0, 1],
                duration: 480
            },
        });

    }
}


export const condor = {
    refs: {},
    beforeInit(element, config) {

        this.refs['element'] = element;
        this.refs['ssd1'] = element.find('[data-animation-ssd-1]');
        this.refs['ssd2'] = element.find('[data-animation-ssd-2]');
        this.refs['ssd3'] = element.find('[data-animation-ssd-3]');
        this.refs['path'] = element.find('[data-animation-path]');
        this.refs['user'] = element.find('[data-animation-user]');
        this.refs['plugin'] = element.find('[data-animation-plugin]');
        this.refs['plugin'] = element.find('[data-animation-plugin]');
        this.refs['module'] = element.find('[data-animation-module]');
        this.refs['shadow'] = element.find('[data-animation-shadow]');
        this.refs['cluster'] = element.find('[data-cluster]');
        this.refs['clusterBottom'] = element.find('[data-cluster-bottom]');
        this.refs['clusterCenter'] = element.find('[data-cluster-center]');
        this.refs['clusterTop'] = element.find('[data-cluster-top]');


    },
    init(element, config) {

        fadeInBottom(this.refs.user, 0, -32);
        fadeInBottom([this.refs.ssd1[0]], 0, -32);
        fadeInBottom([this.refs.ssd1[1]], 0, -48);
        fadeInBottom([this.refs.ssd2[0]], 0, -32);
        fadeInBottom([this.refs.ssd2[1]], 0, -48);
        fadeInBottom([this.refs.ssd3[0]], 0, -32);
        fadeInTop(this.refs.module, 0, 32);
        fadeInTop(this.refs.shadow, 0, 48);
        fadeInTop([this.refs.plugin[0]], 0, 32);
        fadeInTop([this.refs.plugin[1]], 0, 32);
        serverAnimation(this.refs['cluster'], this.refs['clusterTop'], this.refs['clusterCenter'], this.refs['clusterBottom'])

        let leftPathAnimation = anime({
            targets: [this.refs.path[0]],
            strokeDashoffset: [179, 0],
            duration: 1000,
            delay: 600,
            easing: 'easeInSine',
            opacity: {
                value: [0, 1],
                duration: 480
            },
        });

        let centerPathAnimation = anime({
            targets: [this.refs.path[1]],
            strokeDashoffset: [48, 0],
            duration: 500,
            delay: 1300,
            easing: 'linear',
            // opacity: {
            //     value: [0, 1],
            //     duration: 480
            // },
        });

        let rightPathAnimation = anime({
            targets: [this.refs.path[2]],
            strokeDashoffset: [-200, 0],
            duration: 1000,
            delay: 600,
            easing: 'easeInSine',
            opacity: {
                value: [0, 1],
                duration: 480
            },
        });

    }
}

export const vultrCloudControllerManager = {
    refs: {},
    beforeInit(element, config) {

        this.refs['element'] = element;
        this.refs['light'] = element.find('[data-animation-light]');
        this.refs['path'] = element.find('[data-animation-path]');
        this.refs['browser'] = element.find('[data-animation-browser]');
        this.refs['cluster'] = element.find('[data-cluster]');
        this.refs['clusterBottom'] = element.find('[data-cluster-bottom]');
        this.refs['clusterCenter'] = element.find('[data-cluster-center]');
        this.refs['clusterTop'] = element.find('[data-cluster-top]');

    },
    init(element, config) {

        fade([this.refs.light[0]], 400);
        fade([this.refs.light[1]], 400);
        fadeInTop(this.refs.browser, 0, 16);
        serverAnimation(this.refs['cluster'], this.refs['clusterTop'], this.refs['clusterCenter'], this.refs['clusterBottom'])

        let leftPathAnimation = anime({
            targets: [this.refs.path[0]],
            strokeDashoffset: [-393, 0],
            duration: 1000,
            easing: 'easeInSine',
            opacity: {
                value: [0, 1],
                duration: 480
            },
        });

        let centerPathAnimation = anime({
            targets: [this.refs.path[1]],
            strokeDashoffset: [-403, 0],
            duration: 1000,
            easing: 'easeInSine',
            opacity: {
                value: [0, 1],
                duration: 480
            },
        });

        let rightPathAnimation = anime({
            targets: [this.refs.path[2]],
            strokeDashoffset: [-393, 0],
            duration: 1000,
            easing: 'easeInSine',
            opacity: {
                value: [0, 1],
                duration: 480
            },
        });

    }
}


export const accelerateIllustration = {
    refs: {},
    beforeInit(element, config) {

        this.refs['element'] = element;
        this.refs['sideElements'] = element.find('[data-animation-ssd]');
        this.refs['light'] = element.find('[data-animation-light]');
        this.refs['path'] = element.find('[data-animation-path]');

        this.refs['topElement'] = element.find('[data-animation-top]');
        this.refs['bottomElement'] = element.find('[data-animation-bottom]');
        this.refs['shadowElement'] = element.find('[data-animation-shadow]');

        this.refs['server'] = element.find('[data-server]');
        this.refs['serverTop'] = element.find('[data-server-top]');
        this.refs['serverCenter'] = element.find('[data-server-center]');
        this.refs['serverBottom'] = element.find('[data-server-bottom]');

    },
    init(element, config) {

        fade(this.refs.light, 160)
        fadeInTop(this.refs.sideElements, 0, 32);
        fadeInTop(this.refs.topElement, 0, 32);
        fadeInTop(this.refs.bottomElement, 0, 16);
        fade(this.refs.shadowElement);
        serverAnimation(this.refs['server'], this.refs['serverTop'], this.refs['serverCenter'], this.refs['serverBottom'])

        let leftPathAnimation = anime({
            targets: [this.refs.path[0]],
            strokeDashoffset: [934, 0],
            duration: 1000,
            easing: 'easeInSine',
            opacity: {
                value: [0, 1],
                duration: 480
            },
        });

        let rightPathAnimation = anime({
            targets: [this.refs.path[1]],
            strokeDashoffset: [934, 0],
            duration: 1000,
            easing: 'easeInSine',
            opacity: {
                value: [0, 1],
                duration: 480
            },
        });

    }
}

export const directAccess = {
    refs: {},
    beforeInit(element, config) {
        this.refs['element'] = element;
        this.refs['user'] = element.find('[data-animation-user]');
        this.refs['browser'] = element.find('[data-animation-browser]');
        this.refs['userPath'] = element.find('[data-animation-user-path]');
        this.refs['serverPath'] = element.find('[data-animation-server-path]');

        this.refs['server'] = element.find('[data-server]');
        this.refs['serverTop'] = element.find('[data-server-top]');
        this.refs['serverCenter'] = element.find('[data-server-center]');
        this.refs['serverBottom'] = element.find('[data-server-bottom]');

    },
    init(element, config) {
        // user
        fadeInTop(this.refs.user, 0, 32);

        // browser
        fadeInTop(this.refs.browser, 0, 16);

        //server
        if (this.refs['server'].length) {
            serverAnimation([this.refs['server'][0]], [this.refs['serverTop'][0]], [this.refs['serverCenter'][0]], [this.refs['serverBottom'][0]])
            serverAnimation([this.refs['server'][1]], [this.refs['serverTop'][1]], [this.refs['serverCenter'][1]], [this.refs['serverBottom'][1]])
        }

        // paths
        if (this.refs['userPath'].length) {
            let userPathAnimation = anime({
                targets: [this.refs.userPath[0], this.refs.userPath[1]],
                strokeDashoffset: [430.852, 0],
                delay: 0,
                duration: 1000,
                easing: 'easeInSine',
            });

            let serverPathLeftAnimation = anime({
                targets: [this.refs.serverPath[0]],
                strokeDashoffset: [792.172, 0],
                delay: 0,
                duration: 1000,
                easing: 'easeInSine',
            });

            let serverPathRightAnimation = anime({
                targets: [this.refs.serverPath[1]],
                strokeDashoffset: [792.172, 0],
                delay: 0,
                duration: 1000,
                easing: 'easeInSine',
            });
        }

    }
}

export const highlyAvailable = {
    refs: {},
    beforeInit(element, config) {

        this.refs['server'] = element.find('[data-server]');
        this.refs['serverTop'] = element.find('[data-server-top]');
        this.refs['serverCenter'] = element.find('[data-server-center]');
        this.refs['serverBottom'] = element.find('[data-server-bottom]');

        this.refs['fileLock'] = element.find('[data-animation-file-lock]');
        this.refs['lock'] = element.find('[data-animation-lock]');

        this.refs['rightPath'] = element.find('[data-animation-right-path]');
        this.refs['leftPath'] = element.find('[data-animation-left-path]');
        this.refs['centerPath'] = element.find('[data-animation-center-path]');

        this.refs['checks'] = element.find('[data-animation-check]');
        this.refs['files'] = element.find('[data-animation-file]');
        this.refs['ssd'] = element.find('[data-animation-ssd]');


    },
    init(element, config) {


        fade(this.refs['ssd']);
        fadeInTop(this.refs['files'], 300);
        fadeInTop(this.refs['lock'], 0);
        fadeInTop(this.refs['fileLock']);
        serverAnimation([this.refs['server'][0]], [this.refs['serverTop'][0]], [this.refs['serverCenter'][0]], [this.refs['serverBottom'][0]])

        let centerPathAnimation = anime({
            targets: [this.refs['centerPath'][0]],
            strokeDashoffset: [182.704, 0],
            delay: 0,
            duration: 600,
            easing: 'easeInSine',
        });

        let leftPathAnimation = anime({
            targets: [this.refs['leftPath'][0]],
            strokeDashoffset: [260.647, 0],
            delay: 600,
            duration: 600,
            easing: 'easeInSine',
        });

        let rightPathAnimation = anime({
            targets: [this.refs['rightPath'][0]],
            strokeDashoffset: [260.647, 0],
            delay: 600,
            duration: 600,
            easing: 'easeInSine',
        });


        let checksAnimation = anime({
            targets: [this.refs.checks[0], this.refs.checks[1], this.refs.checks[2]],
            duration: 600,
            delay: 1100,
            strokeDashoffset: [50.448, 0],
            easing: 'cubicBezier(.16,0,0,1)',
        });
    }
}

export const bestPerformance = {
    refs: {},
    beforeInit(element, config) {

        this.refs['blocks'] = element.find('[data-animation-block]');
        this.refs['blockPaths'] = element.find('[data-animation-block-path]');
        this.refs['ring'] = element.find('[data-animation-ring]');

        this.refs['arrowBottom'] = element.find('[data-animation-arrow-bottom]');
        this.refs['arrowTop'] = element.find('[data-animation-arrow-top]');

        this.refs['server'] = element.find('[data-server]');
        this.refs['serverTop'] = element.find('[data-server-top]');
        this.refs['serverCenter'] = element.find('[data-server-center]');
        this.refs['serverBottom'] = element.find('[data-server-bottom]');


    },
    init(element, config) {

        fadeInTop(this.refs['blocks'], 300);
        fadeInTop(this.refs['ring'], 600);
        serverAnimation([this.refs['server'][0]], [this.refs['serverTop'][0]], [this.refs['serverCenter'][0]], [this.refs['serverBottom'][0]])

        anime({
            targets: [...Array.from(this.refs['blockPaths'])],
            strokeDashoffset: [140.401, 0],
            delay: 900,
            duration: 600,
            easing: 'easeInSine',
        });

        anime({
            targets: [...Array.from(this.refs['arrowBottom'])],
            strokeDashoffset: [1093.0572, 0],
            delay: 600,
            duration: 600,
            easing: 'easeInSine',
        });

        anime({
            targets: [...Array.from(this.refs['arrowTop'])],
            strokeDashoffset: [34.466, 0],
            delay: 1000,
            duration: 600,
            easing: 'easeInSine',
        });

    }
}

export const advancedNetworking = {
    refs: {},
    beforeInit(element, config) {

        this.refs['server'] = element.find('[data-server]');
        this.refs['serverTop'] = element.find('[data-server-top]');
        this.refs['serverCenter'] = element.find('[data-server-center]');
        this.refs['serverBottom'] = element.find('[data-server-bottom]');

        this.refs['screen'] = element.find('[data-animation-screen]');
        this.refs['pins'] = element.find('[data-animation-pin]');
        this.refs['screenPath'] = element.find('[data-animation-screen-path]');

        this.refs['serverPath'] = element.find('[data-animation-server-path]');
        this.refs['screenPath'] = element.find('[data-animation-screen-path]');

    },
    init(element, config) {

        serverAnimation([this.refs['server'][0]], [this.refs['serverTop'][0]], [this.refs['serverCenter'][0]], [this.refs['serverBottom'][0]])
        serverAnimation([this.refs['server'][1]], [this.refs['serverTop'][1]], [this.refs['serverCenter'][1]], [this.refs['serverBottom'][1]])

        fadeInTop(this.refs['pins'], 300);
        fadeInTop(this.refs['screen'], 0);

        anime({
            targets: [...Array.from(this.refs['serverPath'])],
            strokeDashoffset: [540.260, 0],
            delay: 0,
            duration: 1000,
            easing: 'easeInSine',
        });

        anime({
            targets: [...Array.from(this.refs['screenPath'])],
            strokeDashoffset: [73, 0],
            delay: 300,
            duration: 600,
            easing: 'easeInSine',
        });

    }
}

export const fullControl = {
    refs: {},
    beforeInit(element, config) {

        this.refs['server'] = element.find('[data-server]');
        this.refs['serverTop'] = element.find('[data-server-top]');
        this.refs['serverCenter'] = element.find('[data-server-center]');
        this.refs['serverBottom'] = element.find('[data-server-bottom]');
        this.refs['light'] = element.find('[data-animation-light]');

        this.refs['blocks'] = element.find('[data-animation-block]');
        this.refs['lines'] = element.find('[data-animation-line]');

        this.refs['browser'] = element.find('[data-animation-browser]');
        this.refs['browserBody'] = element.find('[data-animation-browser-body]');

        this.refs['key'] = element.find('[data-animation-key]');
        this.refs['keyHole'] = element.find('[data-animation-keyhole]');

    },
    init(element, config) {

        serverAnimation(this.refs['server'], this.refs['serverTop'], this.refs['serverCenter'], this.refs['serverBottom'])

        fadeInTop([this.refs['light'][0]], 300, 16);

        fadeInTop([this.refs['browser'][0]], 300, 8);
        fadeInTop(this.refs['browserBody'], 300, 32);

        fadeInBottom(this.refs['key'], 0, -8);
        fadeInTop(this.refs['keyHole'], 0, 8);

        anime({
            targets: [...Array.from(this.refs['lines'])],
            strokeDashoffset: [599.623, 0],
            delay: 0,
            duration: 1000,
            easing: 'easeInSine',
        });
    }
}

export const automatedScaling = {
    refs: {},
    beforeInit(element, config) {

        this.refs['arrowBottom'] = element.find('[data-animation-arrow-bottom]');
        this.refs['arrowTop'] = element.find('[data-animation-arrow-top]');
        this.refs['blocks'] = element.find('[data-animation-block]');
        this.refs['browser'] = element.find('[data-animation-browser]');

        this.refs['scaleBottom'] = element.find('[data-animation-scale-bottom]');
        this.refs['scaleArrows'] = element.find('[data-animation-scale-arrows]');
        this.refs['scaleTop'] = element.find('[data-animation-scale-top]');

    },
    init(element, config) {

        fadeInTop([this.refs['browser'][0]], 300, 8);
        fadeInTop([this.refs['blocks'][0]], 300, 16);
        fadeInTop([this.refs['blocks'][1]], 300, 24);


        fadeInBottom(this.refs['scaleTop'], 300, -16);
        fadeInBottom(this.refs['scaleArrows'], 300, -8);
        fadeInTop(this.refs['scaleBottom'], 300, 24);

        anime({
            targets: [...Array.from(this.refs['arrowBottom'])],
            strokeDashoffset: [1093.0572, 0],
            delay: 0,
            duration: 1600,
            easing: 'easeInSine',
        });

        anime({
            targets: [...Array.from(this.refs['arrowTop'])],
            strokeDashoffset: [46.139, 0],
            delay: 1600,
            duration: 600,
            easing: 'easeInSine',
        });

    }
}

export const localizedPeering = {
    refs: {},
    beforeInit(element, config) {


        this.refs['blocks'] = element.find('[data-animation-block]');
        this.refs['user'] = element.find('[data-animation-user]');
        this.refs['pins'] = element.find('[data-animation-pin]');
        this.refs['circle'] = element.find('[data-animation-circle]');

        this.refs['lines'] = element.find('[data-animation-line]');


    },
    init(element, config) {


        fadeInBottom([this.refs['user'][0]], 300, -32);


        fadeInBottom([this.refs['pins'][0]], 0, -8);
        fadeInBottom([this.refs['pins'][1]], 0, -16);
        fadeInBottom([this.refs['pins'][2]], 0, -24);
        fadeInBottom([this.refs['pins'][3]], 0, -32);

        fadeInTop([this.refs['blocks'][0]], 0, 8);
        fadeInTop([this.refs['blocks'][1]], 0, 16);
        fadeInTop([this.refs['blocks'][2]], 0, 24);
        fadeInTop([this.refs['blocks'][3]], 0, 32);


        anime({
            targets: this.refs['lines'][0],
            strokeDashoffset: [91.178, 0],
            delay: 600,
            duration: 800,
            easing: 'easeInSine',
        });
        anime({
            targets: this.refs['lines'][1],
            strokeDashoffset: [117.598, 0],
            delay: 600,
            duration: 800,
            easing: 'easeInSine',
        });

        anime({
            targets: this.refs['lines'][2],
            strokeDashoffset: [117.597, 0],
            delay: 600,
            duration: 800,
            easing: 'easeInSine',
        });

        anime({
            targets: this.refs['lines'][3],
            strokeDashoffset: [93.088, 0],
            delay: 600,
            duration: 800,
            easing: 'easeInSine',
        });

        anime({
            targets: this.refs['circle'][0],
            strokeDashoffset: [693.352, 0],
            delay: 800,
            duration: 600,
            easing: 'easeInSine',
        });


    }
}

export const blockStorage = {
    refs: {},
    beforeInit(element, config) {

        this.refs['front'] = element.find('[data-animation-front]');
        this.refs['back'] = element.find('[data-animation-back]');
        this.refs['shadow'] = element.find('[data-animation-shadow]');

        this.refs['server'] = element.find('[data-server]');
        this.refs['serverTop'] = element.find('[data-server-top]');
        this.refs['serverCenter'] = element.find('[data-server-center]');
        this.refs['serverBottom'] = element.find('[data-server-bottom]');
        this.refs['path'] = element.find('[data-animation-path]');

        this.refs['disksContainer'] = element.find('[data-disks-container]');
        this.refs['disksRow1'] = element.find('[data-animation-disks-row-1]');
        this.refs['disksRow2'] = element.find('[data-animation-disks-row-2]');
        this.refs['disksRow3'] = element.find('[data-animation-disks-row-3]');
        this.refs['disksRow4'] = element.find('[data-animation-disks-row-4]');


    },
    init(element, config) {

        serverAnimation([this.refs['server'][0]], [this.refs['serverTop'][0]], [this.refs['serverCenter'][0]], [this.refs['serverBottom'][0]])

        serverAnimation([this.refs['server'][1]], [this.refs['serverTop'][1]], [this.refs['serverCenter'][1]], [this.refs['serverBottom'][1]])

        anime({
            targets: [this.refs.path[0]],
            strokeDashoffset: [934, 0],
            duration: 1000,
            easing: 'easeInSine',
            opacity: {
                value: [0, 1],
                duration: 480
            },
        });

        anime({
            targets: [this.refs.path[1]],
            strokeDashoffset: [934, 0],
            duration: 1000,
            easing: 'easeInSine',
            opacity: {
                value: [0, 1],
                duration: 480
            },
        });

        fade(this.refs['disksContainer']);
        slideInBottom(this.refs['disksRow1'], 0, -24);
        slideInBottom(this.refs['disksRow2'], 0, -16);
        slideInTop(this.refs['disksRow3'], 0, 16);
        slideInTop(this.refs['disksRow4'], 0, 8);

        fadeInTop([this.refs.front[0], this.refs.back[0]], 0, 16);
        fade(this.refs.shadow);


    }
}

export const customISO = {
    refs: {},
    beforeInit(element, config) {

        this.refs['pathLeft'] = element.find('[data-animation-path-left]');
        this.refs['pathRight'] = element.find('[data-animation-path-right]');

        this.refs['disc'] = element.find('[data-animation-disc]');
        this.refs['discTop'] = element.find('[data-animation-disc-top]');
        this.refs['discBottom'] = element.find('[data-animation-disc-bottom]');

        this.refs['robot'] = element.find('[data-animation-robot]');
        this.refs['robotDisk'] = element.find('[data-animation-robot-disk]');


    },
    init(element, config) {
        fade(this.refs['disc'], 0)
        anime({
            targets: [this.refs.pathLeft[0]],
            strokeDashoffset: [775.709, 0],
            delay: 0,
            duration: 1000,
            easing: 'easeInSine',
        });

        anime({
            targets: [this.refs.pathRight[0]],
            strokeDashoffset: [775.709, 0],
            delay: 0,
            duration: 1000,
            easing: 'easeInSine',
        });

        fadeInBottom(this.refs['discTop'], 0, -8);
        fadeInTop(this.refs['discBottom'], 0, 8);
        fadeInTop(this.refs['robot'], 0, 16);

        anime({
            targets: [...Array.from(this.refs['robotDisk'])],
            translateY: {
                value: [20, 0],
                duration: 1000
            },
            translateX: {
                value: [46, 0],
                duration: 1000
            },
            opacity: {
                value: [0, 1],
                duration: 480
            },
            delay: 600,
            duration: 1000,
            easing: 'easeInSine'
        });

    }
}

export const blocks = {
    refs: {},
    beforeInit(element, config) {

        this.refs['server'] = element.find('[data-server]');
        this.refs['serverTop'] = element.find('[data-server-top]');
        this.refs['serverCenter'] = element.find('[data-server-center]');
        this.refs['serverBottom'] = element.find('[data-server-bottom]');

    },
    init(element, config) {

        serverAnimation(this.refs['server'], this.refs['serverTop'], this.refs['serverCenter'], this.refs['serverBottom'])

    }
}

export const vultr = {
    refs: {},
    beforeInit(element, config) {
        this.refs['blocks'] = element.find('[data-animation-block]');
        this.refs['centerBlock'] = element.find('[data-center-block]');
        this.refs['trees'] = element.find('[data-animation-trees]');
        this.refs['users'] = element.find('[data-animation-user]');
        this.refs['path'] = element.find('[data-animation-path]');
    },
    init(element, config) {

        fadeInTop(this.refs['centerBlock'], 0);
        fadeInTop(this.refs['blocks'], 600);
        fadeInTop(this.refs.users, 0, 32);
        fadeInTop(this.refs.trees, 300, 16);

        anime({
            targets: [this.refs.path[0]],
            strokeDashoffset: [anime.setDashoffset, 0],
            duration: 1000,
            easing: 'easeInSine',
            delay: 500,
            opacity: {
                value: [0, 1],
                duration: 480
            },
        });

        anime({
            targets: [this.refs.path[1]],
            strokeDashoffset: [anime.setDashoffset, 0],
            duration: 1000,
            easing: 'easeInSine',
            delay: 500,
            opacity: {
                value: [0, 1],
                duration: 480
            },
        });
    }
}

export const error = {
    refs: {},
    beforeInit(element, config) {
        this.refs['element'] = element;
        this.refs['sideElements'] = element.find('[data-animation-ssd], [data-animation-cpu], [data-animation-browser]');
        this.refs['light'] = element.find('[data-animation-light]');
        this.refs['path'] = element.find('[data-animation-path]');
        this.refs['robot'] = element.find('[data-animation-robot]');

        this.refs['digits'] = element.find('[data-animation-digit]');
        this.refs['website'] = element.find('[data-animation-website]')
    },
    init(element, config) {
        fade(this.refs.light, 160)
        fadeInTop(this.refs.sideElements, 0, 32);
        fadeInTop(this.refs['robot'], 0, 16);


        if (this.refs['website'].length) {

            fade(this.refs.website, 780)

        } else {

            fade([this.refs.digits[0]], 600)
            fade([this.refs.digits[1]], 780)
            fade([this.refs.digits[2]], 960)

        }

        let leftPathAnimation = anime({
            targets: [this.refs.path[0]],
            strokeDashoffset: [anime.setDashoffset, 0],
            duration: 1000,
            easing: 'easeInSine',
            opacity: {
                value: [0, 1],
                duration: 480
            },
        });

        let rightPathAnimation = anime({
            targets: [this.refs.path[1]],
            strokeDashoffset: [anime.setDashoffset, 0],
            duration: 1000,
            easing: 'easeInSine',
            opacity: {
                value: [0, 1],
                duration: 480
            },
        });

    }
}

export const ddosMonitoring = {
    refs: {},
    beforeInit(element, config) {

        this.refs['server'] = element.find('[data-server]');
        this.refs['serverTop'] = element.find('[data-server-top]');
        this.refs['serverCenter'] = element.find('[data-server-center]');
        this.refs['serverBottom'] = element.find('[data-server-bottom]');

        this.refs['shield'] = element.find('[data-animation-shield]');
        this.refs['globe'] = element.find('[data-animation-globe]');
        this.refs['screen'] = element.find('[data-animation-screen]');
        this.refs['barells'] = element.find('[data-animation-barells]');

        this.refs['lines'] = element.find('[data-animation-line]');

    },
    init(element, config) {

        serverAnimation(this.refs['server'], this.refs['serverTop'], this.refs['serverCenter'], this.refs['serverBottom']);
        fadeInTop(this.refs.shield, 0, 8);
        fadeInTop(this.refs.globe, 0, 32);
        fadeInTop(this.refs.screen, 0, 16);
        fadeInTop(this.refs.barells, 0, 8);

        anime({
            targets: [...Array.from(this.refs.lines)],
            strokeDashoffset: [anime.setDashoffset, 0],
            duration: 640,
            easing: 'easeInSine',
            delay: 200,
            opacity: {
                value: [0, 1],
                duration: 480
            },
        });
    }
}

export const redundancy = {
    refs: {},
    beforeInit(element, config) {
        this.refs['user'] = element.find('[data-animation-user]');
        this.refs['serverOffline'] = element.find('[data-animation-server-offline]');
        this.refs['monitor'] = element.find('[data-animation-monitor]');
        this.refs['lines'] = element.find('[data-animation-line]');
        this.refs['block'] = element.find('[data-animation-block]');
        this.refs['light'] = element.find('[data-animation-light]');

        this.refs['server'] = element.find('[data-server]');
        this.refs['serverTop'] = element.find('[data-server-top]');
        this.refs['serverCenter'] = element.find('[data-server-center]');
        this.refs['serverBottom'] = element.find('[data-server-bottom]');

    },
    init(element, config) {
        serverAnimation(this.refs['server'], this.refs['serverTop'], this.refs['serverCenter'], this.refs['serverBottom']);
        fadeInTop(this.refs.user, 0, 32);
        fadeInTop(this.refs.serverOffline, 0, 32);
        fadeInTop(this.refs.monitor, 0, 32);
        fadeInTop(this.refs.light, 0, 16);
        fadeInTop(this.refs.block, 0, 8);

        anime({
            targets: [...Array.from(this.refs.lines)],
            strokeDashoffset: [anime.setDashoffset, 0],
            duration: 640,
            easing: 'easeInSine',
            delay: 800,
            opacity: {
                value: [0, 1],
                duration: 480
            },
        });
    }
}

export const privateNetworking = {
    refs: {},
    beforeInit(element, config) {
        this.refs['server'] = element.find('[data-server]');
        this.refs['serverTop'] = element.find('[data-server-top]');
        this.refs['serverCenter'] = element.find('[data-server-center]');
        this.refs['serverBottom'] = element.find('[data-server-bottom]');

    },
    init(element, config) {


    }
}

export const reservedIPs = {
    refs: {},
    beforeInit(element, config) {
        this.refs['server'] = element.find('[data-server]');
        this.refs['serverTop'] = element.find('[data-server-top]');
        this.refs['serverCenter'] = element.find('[data-server-center]');
        this.refs['serverBottom'] = element.find('[data-server-bottom]');

        this.refs['popovers'] = element.find('[data-animation-popover]');
        this.refs['pins'] = element.find('[data-animation-pin]');
        this.refs['monitor'] = element.find('[data-animation-monitor]');
        this.refs['light'] = element.find('[data-animation-light]');
        this.refs['list'] = element.find('[data-animation-list]');

        this.refs['lines'] = element.find('[data-animation-line]');

    },
    init(element, config) {

        serverAnimation(this.refs['server'], this.refs['serverTop'], this.refs['serverCenter'], this.refs['serverBottom']);

        let popoverDelay = 0;
        for (let page of Array.from(this.refs.popovers)) {
            fadeInTop([page], popoverDelay, 8);
            popoverDelay = popoverDelay + 200;
        }

        let pinsDelay = 0;
        for (let page of Array.from(this.refs.pins)) {
            fadeInTop([page], pinsDelay, 8);
            pinsDelay = pinsDelay + 200;
        }
        fadeInTop(this.refs.list, 0, -8);
        fade(this.refs.light);


        fadeInBottom(this.refs.monitor, 0, 8);

        anime({
            targets: [...Array.from(this.refs.lines)],
            strokeDashoffset: [anime.setDashoffset, 0],
            duration: 640,
            easing: 'easeInSine',
            delay: 800,
            opacity: {
                value: [0, 1],
                duration: 480
            },
        });
    }
}

export const secureNetworking = {
    refs: {},
    beforeInit(element, config) {
        this.refs['lines'] = element.find('[data-animation-line]');
        this.refs['blocks'] = element.find('[data-animation-blocks]');
        this.refs['pipes'] = element.find('[data-animation-pipes]');
        this.refs['browser'] = element.find('[data-animation-browser]');
    },
    init(element, config) {

        anime({
            targets: [...Array.from(this.refs.lines)],
            strokeDashoffset: [anime.setDashoffset, 0],
            duration: 640,
            easing: 'easeInSine',
            delay: 800,
            opacity: {
                value: [0, 1],
                duration: 480
            },
        });

        fadeInTop(this.refs.pipes, 0, 8);
        fadeInTop(this.refs.browser, 0, 16);

        let blocksDelay = 200;
        for (let blocks of Array.from(this.refs.blocks)) {
            fadeInTop([blocks], blocksDelay, 8);
        }
    }
}

export const nvme = {
    refs: {},
    beforeInit(element, config) {
        this.refs['paths'] = element.find('[data-animation-path]');
        this.refs['monitor'] = element.find('[data-animation-monitor]');
        this.refs['mainLight'] = element.find('[data-animation-main-light]');
        this.refs['flash'] = element.find('[data-animation-flash]');
        this.refs['bottom'] = element.find('[data-animation-bottom]');
        this.refs['blueBlocks'] = element.find('[data-animation-blue-block]');
        this.refs['lights'] = element.find('[data-animation-lights] rect');
    },
    init(element, config) {

        anime({
            targets: [...Array.from(this.refs.paths)],
            strokeDashoffset: [anime.setDashoffset, 0],
            duration: 640,
            easing: 'easeInSine',
            delay: 800,
            opacity: {
                value: [0, 1],
                duration: 480
            },
        });

        fadeInTop(this.refs.monitor, 0, 24);
        fadeInTop(this.refs.mainLight, 0, 16);
        fadeInTop(this.refs.flash, 0, 16);
        fadeInTop(this.refs.bottom, 0, 8);
        fadeInTop([this.refs.blueBlocks[0]], 0, 8);
        fadeInTop([this.refs.blueBlocks[1]], 0, 16);


        let lightDelay = 500;
        for (let light of Array.from(this.refs.lights)) {
            anime({
                targets: [light],
                translateY: [
                    {
                        value: [16, 0],
                        duration: 1000
                    },
                    {
                        value: [0, 0],
                        duration: 480
                    }
                ],
                opacity: [
                    {
                        value: [0, 1],
                        duration: 480
                    },
                    {
                        value: [1, 0],
                        duration: 600
                    }
                ],
                delay: lightDelay,
                loop: true,
                easing: 'easeInSine'
            });
            lightDelay += 200
        }
    }
}

export const fastBenchmarks = {
    refs: {},
    beforeInit(element, config) {
        this.refs['usageCenter'] = element.find('[data-animation-usage-center]');
        this.refs['usageCenterPath'] = element.find('[data-animation-usage-center-path]');
        this.refs['usageLeft'] = element.find('[data-animation-usage-left]');
        this.refs['usageRight'] = element.find('[data-animation-usage-right]');
        this.refs['screen'] = element.find('[data-animation-screen]');
        this.refs['cond'] = element.find('[data-animation-usage-cond]');
        this.refs['usageCenterPath'] = element.find('[data-animation-usage-center-path]');
    },
    init(element, config) {

        fadeInTop(this.refs.usageCenter, 0, 32);
        fadeInTop(this.refs.usageLeft, 0, 24);
        fadeInTop(this.refs.usageRight, 0, 16);
        fadeInTop(this.refs.screen, 0, 8);
        fadeInTop(this.refs.cond, 0, 8);

        anime({
            targets: [...Array.from(this.refs.usageCenterPath)],
            strokeDashoffset: [anime.setDashoffset, 0],
            duration: 640,
            easing: 'easeInSine',
            delay: 700,
            opacity: {
                value: [0, 1],
                duration: 480
            },
        });
    }
}

export const fastCPU = {
    refs: {},
    beforeInit(element, config) {

        this.refs['screen'] = element.find('[data-animation-screen]');
        this.refs['motherboardRight'] = element.find('[data-animation-motherboard-right]');
        this.refs['motherboardLeft'] = element.find('[data-animation-motherboard-left]');

        this.refs['motherboardRight'] = element.find('[data-animation-motherboard-right]');
        this.refs['motherboardLeft'] = element.find('[data-animation-motherboard-left]');

        this.refs['boxRight'] = element.find('[data-animation-box-right]');
        this.refs['boxLeft'] = element.find('[data-animation-box-left]');

        this.refs['CPUTop'] = element.find('[data-animation-cpu-top]');
        this.refs['CPUBottom'] = element.find('[data-animation-cpu-bottom]');

        this.refs['CPULight'] = element.find('[data-animation-cpu-light]');
        this.refs['CPULights'] = element.find('[data-animation-cpu-lights] rect');

        this.refs['CPUMainLine'] = element.find('[data-animation-cpu-main-line]');
        this.refs['CPULines'] = element.find('[data-animation-cpu-line]');


    },
    init(element, config) {

        fadeInTop(this.refs.screen, 0, 32);

        fadeInTop(this.refs.motherboardLeft, 0, 32);
        fadeInTop(this.refs.motherboardRight, 0, 32);

        fadeInTop(this.refs.boxLeft, 0, 24);
        fadeInTop(this.refs.boxRight, 0, 24);

        fadeInTop(this.refs.CPUTop, 0, 24);
        fadeInTop(this.refs.CPUBottom, 0, 24);

        fadeInTop(this.refs.CPULight, 0, 16);

        anime({
            targets: [...Array.from(this.refs.CPUMainLine)],
            strokeDashoffset: [anime.setDashoffset, 0],
            duration: 1000,
            easing: 'easeInSine',
            delay: 600,
        });

        anime({
            targets: [...Array.from(this.refs.CPULines)],
            strokeDashoffset: [anime.setDashoffset, 0],
            duration: 640,
            easing: 'easeInSine',
            delay: 1600,
            opacity: {
                value: [0, 1],
                duration: 480
            },
        });
        let lightDelay = 500;
        for (let light of Array.from(this.refs.CPULights)) {
            anime({
                targets: [light],
                translateY: [
                    {
                        value: [16, 0],
                        duration: 1000
                    },
                    {
                        value: [0, 0],
                        duration: 480
                    }
                ],
                opacity: [
                    {
                        value: [0, 1],
                        duration: 480
                    },
                    {
                        value: [1, 0],
                        duration: 600
                    }
                ],
                delay: lightDelay,
                loop: true,
                easing: 'easeInSine'
            });
            lightDelay += 200
        }

    }
}

export const easyOfIntegration = {
    refs: {},
    beforeInit(element, config) {
        this.refs['element'] = element;
        this.refs['sideElements'] = element.find('[data-animation-integration]');
        this.refs['light'] = element.find('[data-animation-light]');
        this.refs['path'] = element.find('[data-animation-path]');

        this.refs['topElement'] = element.find('[data-animation-top]');
        this.refs['bottomElement'] = element.find('[data-animation-bottom]');
        this.refs['shadowElement'] = element.find('[data-animation-shadow]');

        this.refs['icons'] = element.find('[data-animation-icon]');

        this.refs['server'] = element.find('[data-server]');
        this.refs['serverTop'] = element.find('[data-server-top]');
        this.refs['serverCenter'] = element.find('[data-server-center]');
        this.refs['serverBottom'] = element.find('[data-server-bottom]');
    },
    init(element, config) {
        fadeInTop(this.refs.topElement, 0, 32);
        fadeInTop(this.refs.bottomElement, 0, 16);
        fade(this.refs.light, 160);
        fade(this.refs.shadowElement);
        serverAnimation(this.refs['server'], this.refs['serverTop'], this.refs['serverCenter'], this.refs['serverBottom'])
        anime({
            targets: [this.refs.path[0]],
            strokeDashoffset: [934, 0],
            duration: 1000,
            easing: 'easeInSine',
            opacity: {
                value: [0, 1],
                duration: 480
            },
        });

        anime({
            targets: [this.refs.path[1]],
            strokeDashoffset: [934, 0],
            duration: 1000,
            easing: 'easeInSine',
            opacity: {
                value: [0, 1],
                duration: 480
            },
        });

        let iconDelay = 100;
        for (let icon of Array.from(this.refs.icons).reverse()) {
            fadeInTop([icon], iconDelay, 16);
            iconDelay += 200
        }
    }
}

export const mediaStorage = {
    refs: {},
    beforeInit(element, config) {
        this.refs['element'] = element;
        this.refs['path'] = element.find('[data-animation-path]');
        this.refs['bucket'] = element.find('[data-animation-bucket]');
        this.refs['mediaCircle'] = element.find('[data-animation-media-circle]');
        this.refs['mediaIcons'] = element.find('[data-animation-media-icon]');
    },
    init(element, config) {
        anime({
            targets: [this.refs.path[0]],
            strokeDashoffset: [1195.312, 0],
            duration: 1000,
            easing: 'easeInSine',
            delay: 400,
            opacity: {
                value: [0, 1],
                duration: 480
            },
        });
        fadeInTop(this.refs.bucket);
        fadeInTop(this.refs.mediaCircle, 340);

        let iconDelay = 800;
        for (let icon of Array.from(this.refs.mediaIcons).reverse()) {
            fade([icon], iconDelay);
            iconDelay += 200
        }
    }
}

export const automaticBackups = {
    refs: {},
    beforeInit(element, config) {
        this.refs['element'] = element;
        this.refs['box'] = element.find('[data-animation-box]');
        this.refs['folder'] = element.find('[data-animation-folder]');
        this.refs['settings'] = element.find('[data-animation-settings]');
        this.refs['settingsPath'] = element.find('[data-animation-settings-path]');
        this.refs['api'] = element.find('[data-animation-api]');
        this.refs['browser'] = element.find('[data-animation-browser]');
        this.refs['pages'] = element.find('[data-animation-pages]');
    },
    init(element, config) {
        fadeInTop(this.refs.box);
        fadeInTop(this.refs.folder, 240);
        fadeInTop(this.refs.settings, 480);
        fadeInTop(this.refs.api, 720);
        fadeInTop(this.refs.browser, 960);
        fadeInTop(this.refs.pages, 1200);
        anime({
            targets: [this.refs.settingsPath[0]],
            strokeDashoffset: [76.41, 0],
            duration: 1000,
            easing: 'easeInSine',
            delay: 1260,
            opacity: {
                value: [0, 1],
                duration: 480
            }
        });
    }
}
export const customSolutions = {
    refs: {},
    beforeInit(element, config) {
        this.refs['element'] = element;
        this.refs['user'] = element.find('[data-animation-user]');
        this.refs['pin'] = element.find('[data-animation-pin]');
        this.refs['browser'] = element.find('[data-animation-browser]');
        this.refs['icons'] = element.find('[data-animation-icons]');
        this.refs['box'] = element.find('[data-animation-box]');
        this.refs['infinity'] = element.find('[data-animation-infinity]');
        this.refs['paths'] = element.find('[data-animation-path]');

    },
    init(element, config) {
        fadeInTop(this.refs.user);
        fadeInTop(this.refs.infinity, 120);
        fadeInTop(this.refs.browser, 340);
        fadeInTop(this.refs.pin, 340);
        fadeInTop(this.refs.icons, 440);
        fadeInTop(this.refs.box, 640);
        anime({
            targets: [this.refs.paths[0]],
            strokeDashoffset: [266.447, 0],
            duration: 1000,
            easing: 'easeInSine',
            delay: 400,
            opacity: {
                value: [0, 1],
                duration: 480
            }
        });
        anime({
            targets: [this.refs.paths[1]],
            strokeDashoffset: [340.592, 0],
            duration: 1000,
            easing: 'easeInSine',
            delay: 400,
            opacity: {
                value: [0, 1],
                duration: 480
            }
        });
    }
}
export const loadBalancerHiglyAvailable = {
    refs: {},
    beforeInit(element, config) {
        this.refs['element'] = element;
        this.refs['user'] = element.find('[data-animation-user]');
        this.refs['browser'] = element.find('[data-animation-browser]');
        this.refs['paths'] = element.find('[data-animation-path]');
        this.refs['frontPaths'] = element.find('[data-animation-front-paths]');

        this.refs['cluster'] = element.find('[data-animation-cluster]');
        this.refs['dots'] = element.find('[data-animation-dot]');

        this.refs['clusterBottom'] = element.find('[data-animation-cluster-bottom]');
        this.refs['clusterCenter'] = element.find('[data-animation-cluster-center]');
        this.refs['clusterTop'] = element.find('[data-animation-cluster-top]');

        this.refs['server'] = element.find('[data-server]');
        this.refs['serverTop'] = element.find('[data-server-top]');
        this.refs['serverCenter'] = element.find('[data-server-center]');
        this.refs['serverBottom'] = element.find('[data-server-bottom]');
    },
    init(element, config) {
        serverAnimation(this.refs['cluster'], this.refs['clusterTop'], this.refs['clusterCenter'], this.refs['clusterBottom'])
        serverAnimation(this.refs['server'], this.refs['serverTop'], this.refs['serverCenter'], this.refs['serverBottom'], 500)


        fadeInTop(this.refs.user, 400);
        fadeInTop(this.refs.browser, 600);
        fadeInTop(this.refs.frontPaths);
        anime({
            targets: [...Array.from(this.refs['paths'])],
            strokeDashoffset: [anime.setDashoffset, 0],
            duration: 1000,
            easing: 'easeInSine',
            delay: 500,
            opacity: {
                value: [0, 1],
                duration: 480
            },
        });

        let dotDelay = 900;
        for (let dot of Array.from(this.refs.dots).reverse()) {
            fade([dot], dotDelay);
            dotDelay += 200
        }
    }
}

export const loadBalancerSecure = {
    refs: {},
    beforeInit(element, config) {
        this.refs['element'] = element;
        this.refs['connector'] = element.find('[data-animation-connector]');
        this.refs['pipes'] = element.find('[data-animation-pipe]');
        this.refs['browser'] = element.find('[data-animation-browser]');
        this.refs['dots'] = element.find('[data-animation-dots]').children()
        this.refs['dots'] = element.find('[data-animation-dots]').children()
        this.refs['servers'] = element.find('[data-animation-server]');
        this.refs['path'] = element.find('[data-animation-path]');
    },
    init(element, config) {
        fadeInTop(this.refs.connector, 0, 16);
        fadeInTop(this.refs.pipes, 640, 8);
        fadeInTop(this.refs.browser, 320);
        fadeInTop(this.refs.servers, 480, 16);

        anime({
            targets: [this.refs.path[0]],
            strokeDashoffset: [574.513, 0],
            duration: 1000,
            easing: 'easeInSine',
            delay: 520,
            opacity: {
                value: [0, 1],
                duration: 480
            }
        });

        let dotDelay = 900;
        for (let dot of Array.from(this.refs.dots)) {
            fade([dot], dotDelay);
            dotDelay += 200
        }
    }
}

export const loadBalancerScalable = {
    refs: {},
    beforeInit(element, config) {
        this.refs['element'] = element;
        this.refs['connector'] = element.find('[data-animation-connector]');
        this.refs['pipes'] = element.find('[data-animation-pipe]');
        this.refs['browser'] = element.find('[data-animation-browser]');
        this.refs['add'] = element.find('[data-animation-add]');
        this.refs['servers'] = element.find('[data-animation-server]');
        this.refs['light'] = element.find('[data-animation-light]');
    },
    init(element, config) {
        fadeInTop(this.refs.connector, 0, 16);
        fade(this.refs.light, 0, 600);
        fadeInTop(this.refs.browser, 320);
        fadeInTop(this.refs.servers, 480, 16);
        fadeInTop(this.refs.pipes, 640, 8);
        fadeInBottom(this.refs.add, 800, -8);
    }
}

export const loadBalancerAPI = {
    refs: {},
    beforeInit(element, config) {
        this.refs['element'] = element;
        this.refs['bukcet'] = element.find('[data-animation-bucket]');
        this.refs['laptop'] = element.find('[data-animation-laptop]');
        this.refs['mouse'] = element.find('[data-animation-mouse]');
        this.refs['tunel'] = element.find('[data-animation-tunel]');
        this.refs['wheel'] = element.find('[data-animation-wheel]');

    },
    init(element, config) {
        fadeInTop(this.refs.laptop);
        fadeInTop(this.refs.mouse, 160);
        fadeInTop(this.refs.bukcet, 320);
        fadeInTop(this.refs.wheel, 480);
        fadeInTop(this.refs.tunel, 640);
    }
}

export const vultrCulture = {
    refs: {},
    beforeInit(element, config) {
        this.refs['element'] = element;
        this.refs['screenLeft'] = element.find('[data-animation-screen-left]');
        this.refs['screenRight'] = element.find('[data-animation-screen-right]');
        this.refs['screenMiddle'] = element.find('[data-animation-screen-middle]');
        this.refs['lines'] = element.find('[data-animation-lines]');
        this.refs['line'] = element.find('[data-animation-line]');
        this.refs['notebook'] = element.find('[data-animation-notebook]');
        this.refs['table'] = element.find('[data-animation-table]');
        this.refs['dude'] = element.find('[data-animation-dude]');
    },
    init(element, config) {
        fade(this.refs.table);
        fade(this.refs.lines);

        fadeInBottom(this.refs.screenLeft, 200);
        fadeInBottom(this.refs.screenRight, 200);
        fadeInBottom(this.refs.screenMiddle, 200);

        fadeInTop(this.refs.notebook, 300);
        fadeInTop(this.refs.dude, 500);

        anime({
            targets: [...Array.from(this.refs['line'])],
            strokeDashoffset: [anime.setDashoffset, 0],
            delay: 600,
            duration: 800,
            easing: 'easeInSine',
        });
    }
}

export const vultrValues = {

    refs: {},
    beforeInit(element, config) {
        this.refs['element'] = element;
        this.refs['stack'] = element.find('[data-animation-stack]');
        this.refs['stack2'] = element.find('[data-animation-stack-2]');
        this.refs['woman'] = element.find('[data-animation-woman]');
        this.refs['man'] = element.find('[data-animation-man]');
        this.refs['line'] = element.find('[data-animation-line]');
        this.refs['lines'] = element.find('[data-animation-lines]');

    },
    init(element, config) {
        fade(this.refs.lines);

        fadeInBottom(this.refs.stack);
        fadeInBottom(this.refs.stack2);

        fadeInTop(this.refs.woman, 300);
        fadeInTop(this.refs.man, 300);

        anime({
            targets: [...Array.from(this.refs['line'])],
            strokeDashoffset: [anime.setDashoffset, 0],
            delay: 600,
            duration: 1200,
            easing: 'easeInSine',
        });
    }
}

export const interviewProcess = {
    refs: {},
    beforeInit(element, config) {
        this.refs['element'] = element;
        this.refs['whiteboard'] = element.find('[data-animation-whiteboard]');
        this.refs['man'] = element.find('[data-animation-man]');
        this.refs['woman'] = element.find('[data-animation-woman]');
        this.refs['line'] = element.find('[data-animation-line]');
        this.refs['lines'] = element.find('[data-animation-lines]');

    },
    init(element, config) {
        fade(this.refs.lines);

        fadeInBottom(this.refs.whiteboard);
        fadeInTop(this.refs.woman, 300);
        fadeInTop(this.refs.man, 300);

        anime({
            targets: [...Array.from(this.refs['line'])],
            strokeDashoffset: [anime.setDashoffset, 0],
            delay: 600,
            duration: 1200,
            easing: 'easeInSine',
        });
    }
}

export const DirectConnectSection = {
    refs: {},

    beforeInit(element, config) {
        this.refs['element'] = element;

        this.refs['cloud'] = element.find('[data-animation-cloud]');
        this.refs['row'] = element.find('[data-animation-row]');
        this.refs['row2'] = element.find('[data-animation-row2]');

        this.refs['lines'] = element.find('[data-animation-lines]');

        this.refs['line'] = element.find('[data-animation-line]');
        this.refs['line2'] = element.find('[data-animation-line-2]');

    },
    init(element, config) {

        fadeInTop(this.refs.cloud);
        fade(this.refs.lines, 800)

        fadeInTop(this.refs.row, 300);
        fadeInTop(this.refs.row2, 500);
        anime({
            targets: [...Array.from(this.refs['line'])],
            strokeDashoffset: [anime.setDashoffset, 0],
            delay: 800,
            duration: 1200,
            easing: 'easeInSine',
        });

        anime({
            targets: [...Array.from(this.refs['line2'])],
            strokeDashoffset: [anime.setDashoffset, 0],
            delay: 1200,
            duration: 1200,
            easing: 'easeInSine',
        });
    }
}

export const optimizedCloudGeneral = {
    refs: {},
    beforeInit(element, config) {

        this.refs['element'] = element;
        this.refs['path'] = element.find('[data-animation-path]');

        this.refs['shadow'] = element.find('[data-animation-shadow]')

        this.refs['bottomBox'] = element.find('[data-animation-box-bottom]')
        this.refs['upperBox'] = element.find('[data-animation-upper-box]')

        this.refs['components'] = element.find('[data-animation-components]')

        this.refs['boxRight'] = element.find('[data-animation-box-right]')
    },
    init(element, config) {

        fade(this.refs.shadow);

        fadeInTop(this.refs.bottomBox, 0, 16)
        fadeInTop(this.refs.upperBox, 0, 32)

        fadeInBottom(this.refs.components, 400, -16)

        fadeInBottom(this.refs.boxRight, 0, 16)

        if (this.refs.path.length) {
            let leftPathAnimation = anime({
                targets: [this.refs.path[0]],
                strokeDashoffset: [anime.setDashoffset, 0],
                duration: 600,
                delay: 500,
                easing: 'easeInSine',
                opacity: {
                    value: [0, 1],
                    duration: 480
                },
            });

            let rightPathAnimation = anime({
                targets: [this.refs.path[1]],
                strokeDashoffset: [anime.setDashoffset, 0],
                duration: 600,
                delay: 500,
                easing: 'easeInSine',
                opacity: {
                    value: [0, 1],
                    duration: 480
                },
            });
        }
    }
}



export const optimizedCloudCpu = {
    refs: {},
    beforeInit(element, config) {

        this.refs['lines'] = element.find('[data-animation-line]');
        this.refs['centerElement'] = element.find('[data-animation-center-element]')

        this.refs['rightPanel'] = element.find('[data-animation-right-panel]')
        this.refs['leftPanel'] = element.find('[data-animation-left-panel]')
        this.refs['leftBluePanel'] = element.find('[data-animation-left-blue-panel]')

        this.refs['lights'] = element.find('[data-animation-lights]')
    },
    init(element, config) {

        fadeInTop(this.refs.rightPanel)
        fadeInBottom(this.refs.centerElement, 0, -48)

        fadeInTop(this.refs.leftPanel, 400, 64)
        fadeInTop(this.refs.leftBluePanel, 560, 24)

        fade(this.refs.lights, 1300)

        anime({
            targets: [...Array.from(this.refs['lines'])],
            strokeDashoffset: [anime.setDashoffset, 0],
            delay: 1000,
            duration: 1000,
            opacity: {
                value: [0, 1],
                duration: 100
            },
            easing: 'easeInSine',
        });
    }
}

export const optimizedCloudMemory = {
    refs: {},
    beforeInit(element, config) {

        this.refs['paths'] = element.find('[data-animation-path]');

        this.refs['ram1'] = element.find('[data-animation-ram-1]');
        this.refs['ram2'] = element.find('[data-animation-ram-2]');
        this.refs['ram3'] = element.find('[data-animation-ram-3]');

        this.refs['slot1'] = element.find('[data-animation-slot-1]');
        this.refs['slotbg1'] = element.find('[data-animation-slot-back-1]');

        this.refs['slot2'] = element.find('[data-animation-slot-2]');
        this.refs['slotbg2'] = element.find('[data-animation-slot-back-2]');

        this.refs['slot3'] = element.find('[data-animation-slot-3]');
        this.refs['slotbg3'] = element.find('[data-animation-slot-back-3]');

        this.refs['storage1'] = element.find('[data-animation-storage-1]')
        this.refs['storage2'] = element.find('[data-animation-storage-2]')

        this.refs['storageshadow1'] = element.find('[data-animation-storage-shadow-1]')
        this.refs['storageshadow2'] = element.find('[data-animation-storage-shadow-2]')

        this.refs['panel'] = element.find('[data-animation-panel]');
    },
    init(element, config) {

        fadeInBottom(this.refs.storage1)
        fade(this.refs.storageshadow1, 600)

        fadeInBottom(this.refs.storage2, 150, -48)
        fade(this.refs.storageshadow2, 750)

        fadeInTop(this.refs.slotbg1, 150, 16)
        fadeInTop(this.refs.slot1, 150, 16)

        fadeInTop(this.refs.slotbg2)
        fadeInTop(this.refs.slot2)

        fadeInTop(this.refs.slotbg3, 300, 48)
        fadeInTop(this.refs.slot3, 300, 48)

        fadeInBottom(this.refs.ram1, 400, -48)
        fadeInBottom(this.refs.ram2, 300, -8)
        fadeInBottom(this.refs.ram3)

        fadeInBottom(this.refs.panel)

        anime({
            targets: [...Array.from(this.refs['paths'])],
            strokeDashoffset: [anime.setDashoffset, 0],
            delay: 1000,
            duration: 1000,
            opacity: {
                value: [0, 1],
                duration: 100
            },
            easing: 'easeInSine',
        });
    }
}

export const optimizedCloudStorage = {
    refs: {},
    beforeInit(element, config) {

        this.refs['paths'] = element.find('[data-animation-path]');

        this.refs['barsBase'] = element.find('[data-animation-bars-base]');

        this.refs['bar1'] = element.find('[data-animation-bar-1]');
        this.refs['bar2'] = element.find('[data-animation-bar-2]');
        this.refs['bar3'] = element.find('[data-animation-bar-3]');

        this.refs['shadow'] = element.find('[data-animation-shadow]');
        this.refs['barShadow'] = element.find('[data-animation-bar-shadow]')
        this.refs['light'] = element.find('[data-animation-light]')

        this.refs['component1'] = element.find('[data-animation-component-1]');
        this.refs['component2'] = element.find('[data-animation-component-2]');

        this.refs['panel'] = element.find('[data-animation-panel]');

    },
    init(element, config) {
        fadeInTop(this.refs.barsBase)

        fadeInBottom(this.refs.bar1)
        fadeInBottom(this.refs.bar2, 300)
        fadeInBottom(this.refs.bar3, 800)

        fade(this.refs.shadow)
        fade(this.refs.light, 0, 1500)
        fade(this.refs.barShadow, 500)

        fadeInBottom(this.refs.component1)
        fadeInBottom(this.refs.component2, 500)

        fadeInTop(this.refs.panel)

        anime({
            targets: [...Array.from(this.refs['paths'])],
            strokeDashoffset: [anime.setDashoffset, 0],
            delay: 1000,
            duration: 1000,
            opacity: {
                value: [0, 1],
                duration: 100
            },
            easing: 'easeInSine',
        });
    }
}

export const gpu = {
    refs: {},
    beforeInit(element, config) {

        this.refs['element'] = element;
        this.refs['sideElements'] = element.find('[data-animation-cpu]');
        this.refs['light'] = element.find('[data-animation-light]');
        this.refs['path'] = element.find('[data-animation-path]');

        this.refs['topElement'] = element.find('[data-animation-top]');
        this.refs['bottomElement'] = element.find('[data-animation-bottom]');
        this.refs['shadowElement'] = element.find('[data-animation-shadow]');

        this.refs['server'] = element.find('[data-server]');
        this.refs['serverTop'] = element.find('[data-server-top]');
        this.refs['serverCenter'] = element.find('[data-server-center]');
        this.refs['serverBottom'] = element.find('[data-server-bottom]');

    },
    init(element, config) {

        fade(this.refs.light, 160)
        fadeInTop(this.refs.sideElements, 0, 32);
        fadeInTop(this.refs.topElement, 0, 32);
        fadeInTop(this.refs.bottomElement, 0, 16);
        fade(this.refs.shadowElement);
        serverAnimation(this.refs['server'], this.refs['serverTop'], this.refs['serverCenter'], this.refs['serverBottom'])

        let leftPathAnimation = anime({
            targets: [this.refs.path[0]],
            strokeDashoffset: [934, 0],
            duration: 1000,
            easing: 'easeInSine',
            opacity: {
                value: [0, 1],
                duration: 480
            },
        });

        let rightPathAnimation = anime({
            targets: [this.refs.path[1]],
            strokeDashoffset: [934, 0],
            duration: 1000,
            easing: 'easeInSine',
            opacity: {
                value: [0, 1],
                duration: 480
            },
        });

    }
}

export const gpuInstance = {
    refs: {},
    beforeInit(element, config) {

        this.refs['element'] = element;

        this.refs['topElement'] = element.find('[data-animation-top]');
        this.refs['coreElement1'] = element.find('[data-animation-core-1]');
        this.refs['coreElement2'] = element.find('[data-animation-core-2]');
        this.refs['coreElement3'] = element.find('[data-animation-core-3]');
        this.refs['coreElement4'] = element.find('[data-animation-core-4]');
        this.refs['coreElement5'] = element.find('[data-animation-core-5]');
        this.refs['coreElement6'] = element.find('[data-animation-core-6]');
        this.refs['coreElement7'] = element.find('[data-animation-core-7]');
        this.refs['coreElement8'] = element.find('[data-animation-core-8]');
        this.refs['bottomElement'] = element.find('[data-animation-bottom]');
        this.refs['shadowElement'] = element.find('[data-animation-shadow]');

        this.refs['server'] = element.find('[data-server]');
        this.refs['serverTop'] = element.find('[data-server-top]');
        this.refs['serverCenter'] = element.find('[data-server-center]');
        this.refs['serverBottom'] = element.find('[data-server-bottom]');

    },
    init(element, config) {
        console.log(this.refs['coreElement']);
      
        
        fadeInTop(this.refs.topElement, 0, 32);
        fadeInTop(this.refs.coreElement1, 0, 32);
        fadeInTop(this.refs.coreElement2, 250, 32);
        fadeInTop(this.refs.coreElement3, 500, 32);
        fadeInTop(this.refs.coreElement4, 750, 32);
        fadeInTop(this.refs.coreElement5, 1000, 32);
        fadeInTop(this.refs.coreElement6, 1250, 32);
        fadeInTop(this.refs.coreElement7, 1500, 32);
        fadeInTop(this.refs.coreElement8, 1750, 32);
        fadeInTop(this.refs.bottomElement, 0, 16);
        fade(this.refs.shadowElement);
        serverAnimation(this.refs['server'], this.refs['serverTop'], this.refs['serverCenter'], this.refs['serverBottom'])

        

    }
}

export const enterpriseGrade = {
    refs: {},
    beforeInit(element, config) {

        this.refs['arrowBottom'] = element.find('[data-animation-arrow-bottom]');
        this.refs['arrowTop'] = element.find('[data-animation-arrow-top]');
        this.refs['blocks'] = element.find('[data-animation-block]');
        this.refs['browser'] = element.find('[data-animation-browser]');

        this.refs['scaleBottom'] = element.find('[data-animation-scale-bottom]');
        this.refs['scaleArrows'] = element.find('[data-animation-scale-arrows]');
        this.refs['scaleTop'] = element.find('[data-animation-scale-top]');

        this.refs['path'] = element.find('[data-animation-path]');


    },
    init(element, config) {

        fadeInTop([this.refs['browser'][0]], 300, 8);
        fadeInTop([this.refs['blocks'][0]], 300, 16);
        fadeInTop([this.refs['blocks'][1]], 300, 24);


        fadeInBottom(this.refs['scaleTop'], 300, -16);
        fadeInBottom(this.refs['scaleArrows'], 300, -8);
        fadeInTop(this.refs['scaleBottom'], 300, 24);

        anime({
            targets: [...Array.from(this.refs['arrowBottom'])],
            strokeDashoffset: [1093.0572, 0],
            delay: 0,
            duration: 1600,
            easing: 'easeInSine',
        });

        anime({
            targets: [...Array.from(this.refs['arrowTop'])],
            strokeDashoffset: [46.139, 0],
            delay: 1600,
            duration: 600,
            easing: 'easeInSine',
        });

        anime({
            targets: [this.refs.path[0]],
            delay: 700,
            strokeDashoffset: [518, 0],
            duration: 1000,
            easing: 'easeInSine',
            opacity: {
                value: [0, 1],
                duration: 300
            },
        });
        anime({
            targets: [this.refs.path[3]],
            delay: 300,
            strokeDashoffset: [-518, 0],
            duration: 1000,
            easing: 'easeInSine',
            opacity: {
                value: [0, 1],
                duration: 300
            },
        });
        anime({
            targets: [this.refs.path[1]],
            delay: 1290,
            strokeDashoffset: [518, 0],
            duration: 1000,
            easing: 'easeInSine',
            opacity: {
                value: [0, 1],
                duration: 300
            },
        });
        anime({
            targets: [this.refs.path[2]],
            delay: 800,
            strokeDashoffset: [-518, 0],
            duration: 1000,
            easing: 'easeInSine',
            opacity: {
                value: [0, 1],
                duration: 300
            },
        });

    }
}


export const easyToUse = {
    refs: {},
    beforeInit(element, config) {

        this.refs['blocks'] = element.find('[data-animation-block]');
        this.refs['circle'] = element.find('[data-animation-circle]');
        this.refs['path'] = element.find('[data-animation-path]');
        this.refs['server'] = element.find('[data-server]');
        this.refs['serverTop'] = element.find('[data-server-top]');
        this.refs['serverCenter'] = element.find('[data-server-center]');
        this.refs['serverBottom'] = element.find('[data-server-bottom]'); 

    },
    init(element, config) {
        fadeInTop([this.refs['blocks'][0]], 300, 16);
        fadeInTop([this.refs['blocks'][1]], 300, 24);
        fadeInTop([this.refs['server']], 300, -8);
        anime({
            targets: [this.refs.path[1]],
            strokeDashoffset: [-132, 0],
            duration: 700,
            delay: 400,
            easing: 'easeInSine',
            opacity: {
                value: [0, 1],
                duration: 480
            },
        });
        anime({
            targets: this.refs['circle'][0],
            strokeDashoffset: [693.352, 0],
            delay: 1100,
            duration: 400,
            easing: 'easeInSine',
        });
        anime({
            targets: [this.refs.path[0]],
            strokeDashoffset: [-397, 0],
            duration: 600,
            easing: 'easeInSine',
            delay: 1500,
            opacity: {
                value: [0, 1],
                duration: 480
            },
        });
        serverAnimation(this.refs['server'], this.refs['serverTop'], this.refs['serverCenter'], this.refs['serverBottom'], 300)
   
    }
}

export const highlyAffordable = {
    refs: {},
    beforeInit(element, config) {


        this.refs['blocks'] = element.find('[data-animation-block]');
        this.refs['btn'] = element.find('[data-animation-btn]');
        this.refs['path'] = element.find('[data-animation-path]');
        this.refs['shadow'] = element.find('[data-animation-shadow]');


    },
    init(element, config) {


        fadeInBottom([this.refs['btn'][0]], 300, -32);

        fadeInTop([this.refs['blocks'][0]], 300, -8);
        fadeInBottom([this.refs['blocks'][1]], 300, -20);
        fadeInTop(this.refs.shadow, 300, 48);


        anime({
            targets: [this.refs.path[0]],
            strokeDashoffset: [-518, 0],
            duration: 1000,
            easing: 'easeInSine',
            delay: 500,
            opacity: {
                value: [0, 1],
                duration: 480
            },
        });

        anime({
            targets: [this.refs.path[1]],
            strokeDashoffset: [518, 0],
            duration: 1000,
            delay: 500,
            easing: 'easeInSine',
            opacity: {
                value: [0, 1],
                duration: 480
            },
        });



    }
}
