function parseOption(item) {
  if ('true' === item) {
    return true;
  } else if ('false' === item) {
    return false
  } else if (!isNaN(item * 1)) {
    return parseFloat(item);
  } else {
    return item;
  }
}


const Util = {

  parseDataOptions(dataOptions) {
    let options = [];
    let string = dataOptions.split(';');

    string.forEach(function (item, index) {
      let option = item.split(':');

      option = option.map(function (item) {
        return item.trim();
      })
      if (option[0]) {
        options[option[0]] = parseOption(option[1]);
      }
    });

    return options;
  }
}



export default Util