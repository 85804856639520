

const SELECTORS = {
    inputsContainer: '[data-inputs-container]',
    virtualInput: '[data-input]',
    isChecked: 'is-selected',
    inputTarget: '[data-input-target]',
    dataTargetId: '[data-target-id]',
    content: '[data-content]',
    contentTarget:'[data-content-target]',
};


class selectInput {
    constructor(inputGroup){
        this.inputGroup = inputGroup;
        
        this.type = this.inputGroup.data('inputs-container');

        this.virtualInputs = this.inputGroup.find(SELECTORS.virtualInput);
        this.bindEvents();
    }
    bindEvents(){

        this.virtualInputs.on('click', (event) => this.selectItem(event));
   
    }

    selectItem(event){ 

        if(this.type == 'checkboxes'){
            if($(event.currentTarget).hasClass('is-selected')){

                $(event.currentTarget).removeClass(SELECTORS.isChecked);
                $(event.currentTarget).find('input').prop("checked", false);

            }else{
                $(event.currentTarget).addClass(SELECTORS.isChecked);
                $(event.currentTarget).find('input').prop("checked", true);
            }

        }else{
            this.virtualInputs.removeClass(SELECTORS.isChecked);
            this.virtualInputs.find('input').prop("checked", false);
    
            $(event.currentTarget).addClass(SELECTORS.isChecked);
            $(event.currentTarget).find('input').prop("checked", true);
        }
        if(this.drop){
            this.getDropdownContent(event);
            this.setDropdownContent();
            this.drop.close();
        }
    
    }
    setDropdownContent(){

        for (var property in this.data) {
            $(this.drop.target).find('[data-view-'+ property+']').text(this.data[property]);
        }

    }
    getDropdownContent(event){

        this.data = $(event.currentTarget).data('config');

    }
}


function initDataSelectors(){
    $(SELECTORS.inputsContainer).each(function(){
        new selectInput($(this));
    });
}


function initJqueryPlugin(){
    $.fn.luSelectInput = function( options ) {
        return this.each(function() {
            new selectInput($(this), options);
        });
    };
}


const init = {
    initDataSelectors,
    initJqueryPlugin,
};


export default init;