import noUiSlider from 'nouislider';
import CSSClasses from './classes.js';

const SELECTORS = {
    container: '[data-range-container]',
    rangeSlider: '[data-range-slider]',
    price: '[data-range-price]',
    value: '[data-range-value]',
    input: '[data-range-input]'
};

const DEFAULTCONFIG = {
    connect: [true, false],
    behaviour: 'tap',
    snap: false,
    range: {},
    step: 1,
    startValue: 0,
    minValue: 1,
    maxValue: 10,
    pricePerOne: 2,
    pips: {
        mode: 'steps',
        stepped: true,
    },
    format: {
        to(value) {
            return value !== undefined && Math.round(value);
        },
        from(value) {
            return value;
        }
    },
};

class RangeSlider {
    constructor(container, options) {
        this.cacheDOM(container);
        this.bindEvents();
        this.setConfig(options);
        this.initPlugin();

    }
    cacheDOM(container) {
        this.container = $(container);

        this.sliderContainer = this.container.find(SELECTORS.rangeSlider);
        this.valueSelector = this.container.find(SELECTORS.value);
        this.input = this.container.find(SELECTORS.input);
        this.priceSelector = this.container.find(SELECTORS.price);
    }
    initPlugin() {

        this.setRange();
        let startValue = this.config.startValue;
        if(this.input.length){
            startValue = this.input.val();
        }
        this.slider = noUiSlider.create(this.sliderContainer[0], {
            start: startValue,
            step: this.config.step,
            snap: this.config.snap,
            connect: this.config.connect,
            range: this.range,
            behaviour: this.config.behaviour,
            format: this.config.format,
            pips: this.pips,
            'cssPrefix': 'range-slider',
            'cssClasses': CSSClasses

        });


        this.sliderPips = this.sliderContainer.find('[data-value]');

        if (this.customValues) {

            this.activeValue = this.customValues[this.slider.get()].value;

        } else {

            this.activeValue = this.slider.get();

        }

        this.sliderPips.on('click', (event) => {

            let pip = $(event.currentTarget);
            let pipValue = pip.data('value');

            this.slider.set(pipValue);

        });

        this.slider.on('update', (values) => {
            this.onUpdate(values);

        });

    }
    setConfig(options) {

        this.config = Object.assign({}, DEFAULTCONFIG, options);
    }
    bindEvents(){

        this.input.on('change', (event)=>{
            this.slider.set(event.target.value)
        });

    }
    setRange() {

        this.range = {};

        if (this.config.values) {

            this.customValues = [];

            for (let [index, value] of this.config.values.entries()) {

                this.customValues[index] = {
                    price: value.price,
                    value: value.value
                };

            }

            this.range['min'] = 0
            this.range['max'] = this.config.values.length - 1;

        } else {

            this.range['min'] = this.config.minValue;
            this.range['max'] = this.config.maxValue;

        }

        this.setPips();

    }
    onUpdate(values) {
        this.changeActivePip(values);

        if (this.customValues) {

            this.activeValue = this.customValues[values[0]].value
            this.input.val(this.customValues[values[0]].value)

        } else {

            this.activeValue = values[0]
            this.input.val(values[0])
        }
        this.valueSelector.text(this.activeValue);

        this.updatePrice(values);

        if (typeof this.config.onUpdate === 'function') {
            this.config.onUpdate(this.activeValue, this);
        }

    }
    setPips() {
        if(this.customValues){

            this.pips = Object.assign({}, {

                format:{
                    to: (value) => {
                        return value !== undefined && this.customValues[value].value;
                    }
                }

            }, this.config.pips);


        }else{
            this.pips = Object.assign({}, {}, this.config.pips);
        }
    }
    changeActivePip(values) {

        let value = values[0];
        let pip = this.sliderPips.parent().find('[data-value="' + value + '"]');
        this.sliderPips.parent().find('.is-active').removeClass('is-active');
        pip.addClass('is-active');

    }
    updatePrice(values) {

        if (this.customValues) {

            this.activePrice = this.customValues[values[0]].price;
            this.priceSelector.text(this.activePrice.toFixed(2));

        } else {

            let price = (parseFloat(this.config.pricePerOne) * parseFloat(this.activeValue)).toFixed(2);
            this.activePrice = price;
            this.priceSelector.text(price);

        }
    }
}

export default RangeSlider;
