/**
 * --------------------------------------------------------------------------
 * Name: Content Slider(layersUI)
 * Version: Alpha
 * License: -
 * Website: http://layersui.com/
 * --------------------------------------------------------------------------
 */
import Swiper from 'swiper/bundle';
import util from './util.js';



const SELECTORS = {
    container: '[data-content-slider]',
    nextSlide: '[data-next-slide]',
    prevSlide: '[data-prev-slide]',
    pagination: '[data-slider-pagination]'
};

const Default = {
    goToClickedSlide: false,
    storage: 'normal', //normal, hash, localStorage
    localStorageId: ''
};

class ContentSlider {
    constructor(container, options) {
        this.container = container;

        this.slides = this.container.find('.content-slider__item');
        this.slidesWrapper = this.container.find('.content-slider__wrapper');

        this.nextSlide = this.container.find(SELECTORS.nextSlide).first();
        this.prevSlide = this.container.find(SELECTORS.prevSlide).first();
        this.pagination = this.container.find(SELECTORS.pagination).first();

        this.options = options;
        this.isInit = false;

        this.getConfig();
        this.initPLugin();

    }
    getConfig() {

        let dataConfig = this.container.data();

        if (dataConfig.options) {
            this.dataOptions = util.parseDataOptions(dataConfig.options);
        } else {
            this.dataOptions = {};
        }
        this.config = $.extend({}, Default, dataConfig, this.dataOptions, this.options);
    }
    initPLugin() {
        var that = this;
        this.swiperInstance = new Swiper(this.container[0], {
            resistance: true,
            resistanceRatio: 0,
            slidesPerView: 'auto',
            watchSlidesProgress: true,
            scrollbar: {
                hide: false
            },
            pagination: {
                el: this.pagination[0],
                modifierClass: 'content-slider-pagination-',
                bulletClass: 'content-slider-pagination-bullet',
                bulletActiveClass: 'is-active',
                clickable: true
            },
            navigation: {
                nextEl: this.nextSlide[0],
                prevEl: this.prevSlide[0],
                disabledClass: 'is-disabled'
            },
            on: {
                init: function () {
                    that.onInit(this);
                    that.onResize();
                },
                tap: function (swiper, event) {
                    that.changeActiveSlide(this);
                    if (that.config.goToClickedSlide) {
                        that.goToClickedSlide(this, event);
                    }
                    that.onClick(this, event);
                },
                slideChangeStart: function (swiper, event) {
                    that.onSlideChangeStart(this, event);
                },
                slideChangeEnd: function (swiper, event) {
                    that.onSlideChangeEnd(this, event);
                },
                transitionStart: function (swiper, event) {
                    that.onTransitionStart(this, event);
                },
                transitionEnd: function (swiper, event) {
                    that.onTransitionEnd(this, event);
                },
                sliderMove: function (swiper, event) {
                    that.onSliderMove(this, event);
                },
                resize: function (swiper, event){
                    that.onResize(this, event);
                }
            },
            a11y: {
                enabled: false
            },
            containerModifierClass: 'content-slider--',
            slideClass: 'content-slider__item',
            slidePrevClass: 'content-slider__item--prev',
            slideNextClass: 'content-slider__item--next',
            slideVisibleClass: 'content-slider__item--visible',
            slideActiveClass: 'content-slider__item--active',
            wrapperClass: 'content-slider__wrapper'
        });
    }
    changeActiveSlide(swiper) {
        if (!this.isInit) {
            return;
        }
        var slides = swiper.slides;

        switch (this.config.storage) {
            case 'normal':

                break;

            case 'hash':
                break;

            case 'localStorage':
                $(slides).removeClass('is-active');
                window.localStorage.setItem(`content-slider-${this.config.localStorageId}`, swiper.clickedIndex);
                break;
        }

    }

    goToClickedSlide(swiper, event) {

        let clickedIndex = swiper.clickedIndex,
            slides = swiper.slides;
        swiper.updateActiveIndex();

        if (!$(slides[clickedIndex - 1]).hasClass('content-slider__item--visible')) {
            swiper.slideTo(swiper.activeIndex - 1);
        }
        if (!$(slides[clickedIndex + 1]).hasClass('content-slider__item--visible')) {
            swiper.slideTo(swiper.activeIndex + 1);
        }

    }

    //Callbacks
    onInit(swiper) {
        if (typeof this.config.onInit === 'function') {
            this.config.onInit();
        }
        switch (this.config.storage) {

            case 'normal':

                let activeSlideIndex = 0,
                    slides = swiper.slides;

                $(slides).each(function (index) {
                    if ($(this).hasClass('is-active')) {
                        activeSlideIndex = index;
                    }
                });

                swiper.slideTo(activeSlideIndex, 0);
                break;


            case 'hash':

                if (window.location.hash) {
                    let slide = $(swiper.$el).find('[href="' + window.location.hash + '"]'),
                        slideIndex = slide.closest('.content-slider__item').index();
                    slide.trigger('click');
                    swiper.slideTo(slideIndex, 0);
                }
                break;


            case 'storage':
                let index = window.localStorage.getItem(`content-slider-${this.config.localStorageId}`);

                if (index) {
                    swiper.slideTo(index, 0);
                    $(swiper.slides[index]).find('a').trigger('click');
                } else {
                    swiper.slideTo(0, 0);
                }
                break;
        }

        this.showSlider(swiper);
        this.isInit = true;
    }
    showSlider(swiper) {
        setTimeout(()=>{

            swiper.el.style.visibility = 'visible';


        },0)

    }
    onClick(swiper, event) {
        if (typeof this.config.onClick === 'function') {
            this.config.onClick(swiper, event);
        }
    }

    onSlideChangeStart(swiper, event) {
        if (typeof this.config.onSlideChange === 'function') {
            this.config.onSlideChangeStart(swiper, event);
        }
    }

    onSlideChangeEnd(swiper, event) {
        if (typeof this.config.onSlideChangeEnd === 'function') {
            this.config.onSlideChangeEnd(swiper, event);
        }
    }
    onTransitionStart(swiper, event) {
        if (typeof this.config.onTransitionStart === 'function') {
            this.config.onTransitionStart(swiper, event);
        }
    }
    onTransitionEnd(swiper, event) {
        if (typeof this.config.onTransitionEnd === 'function') {
            this.config.onTransitionEnd(swiper, event);
        }
    }
    onSliderMove(swiper, event) {
        if (typeof this.config.onSliderMove === 'function') {
            this.config.onSliderMove(swiper, event);
        }
    }
    getSlidesWidth() {

        let width = 0;
        this.slides.each(function () {
            width = $(this).innerWidth() + width;
        });

        return width;

    }
    onResize(swiper, event) {

        setTimeout(() => {

            if (this.slidesWrapper.innerWidth() <= this.getSlidesWidth()) {

                this.container.addClass('is-active');

            } else {

                this.container.removeClass('is-active');

            }

        }, 200)

        if (typeof this.config.onResize === 'function') {
            this.config.onResize(swiper, event);
        }
    }
}



function initDataSelectors() {
    $(SELECTORS.container).each(function () {
        new ContentSlider($(this));
    });
}


function initJqueryPlugin() {
    $.fn.luContentSlider = function (options) {
        return this.each(function () {
            new ContentSlider($(this), options);
        });
    };
}

const init = {
    initDataSelectors,
    initJqueryPlugin,
};

export default init;
