import util from './../../../assets-ui/js/components/util.js';
import cloneDeep from 'lodash/cloneDeep';
import brakepoints from './../utils/brakepoints.js';
import sliderAnimations from './animations/types/slider-animations.js';

const SELECTORS = {
    container: '[data-screens-slider]',
    wrapper: '[data-screens-slider-wrapper]',
    pagination: '[data-slider-pagination]',
    paginationItem: '[data-slide-to]',
    prev: '[data-prev-slide]',
    next: '[data-next-slide]',
    slideDesc: '[data-slide-desc]',

};

const Default = {
    defaultAnimationType: 'noAnimation'
}

class screenSlider {
    constructor(container) {
        this.container = container;
        this.activeSlideIndex = 0;


        this.animations = [];
        this.getConfig();

        this.cacheDOM();
        this.setActiveSlide();
        this.registerEvents();
        this.initSlider();


    }
    cacheDOM() {
        this.wrapper = this.container.find(SELECTORS.wrapper);
        this.slides = this.wrapper.children();

        this.pagination = this.container.find(SELECTORS.pagination);
        this.paginationItems = this.pagination.find(SELECTORS.paginationItem);

        this.prev = this.container.find(SELECTORS.prev);
        this.next = this.container.find(SELECTORS.next);

    }
    getConfig() {

        var dataConfig = this.container.data();

        if (dataConfig.options) {
            this.dataOptions = util.parseDataOptions(dataConfig.options);
        } else {
            this.dataOptions = {};
        }

        this.config = $.extend({}, Default, dataConfig, this.dataOptions, this.options);


    }
    initSlider() {
        this.setAnimations()
        this.container.css({
            opacity: 1,
            visibility: 'visible'
        })
        this.initHammer();
        this.initWatchers();

        this.showSlideDesc(this.paginationItems.eq(0).find(SELECTORS.slideDesc))
    }
    setActiveSlide() {

        let index = 0;

        for (let i = 0; i <= this.slides.length; i++) {

            if ($(this.slides[i]).hasClass('is-active')) {
                this.goToSlide(i)
                this.activeSlideIndex = index;
            }

            index++;
        }

    }
    registerEvents() {

        this.next.on('click', () => {
            this.nextSlide();
        });

        this.prev.on('click', () => {

            this.prevSlide();

        });

        this.paginationItems.on('click', (event) => {

            let index = $(event.currentTarget).data('slide-to');

            this.goToSlide(index);

        });


    }
    prevSlide() {
        if (this.activeSlideIndex - 1 < 0) {

            this.goToSlide(this.slides.length - 1);

        } else {

            this.goToSlide(this.activeSlideIndex - 1);

        }
    }
    nextSlide() {

        if (this.activeSlideIndex >= this.slides.length - 1) {

            this.goToSlide(0);

        } else {

            this.goToSlide(this.activeSlideIndex + 1);

        }

    }
    goToSlide(index, firstSlide = false) {

        if (this.activeSlideIndex == index && !firstSlide) {
            return;
        }

        let oldIndex = this.activeSlideIndex;

        if (firstSlide) {

            this.activeSlideIndex = index;
            $(this.slides[this.activeSlideIndex]).addClass('is-active');

            this.animations[this.activeSlideIndex].init()

        } else {

            this.updatePaginationStatus(index, oldIndex);
            this.activeSlideIndex = index;
            $(this.slides[oldIndex]).removeClass('is-active');

            $(this.slides[this.activeSlideIndex]).addClass('is-active');
            this.animations[index].init();

        }

    }
    showSlideDesc(slideDesc) {

        if(brakepoints.is('mobile') || brakepoints.is('tablet')){
            slideDesc.attr('style', '')
            slideDesc.css({
                display: 'block'
            })
        }else{
            slideDesc.stop().slideDown(450);
        }

    }
    hideSlideDesc(slideDesc) {

        if(brakepoints.is('mobile') || brakepoints.is('tablet')){
            slideDesc.attr('style', '')
            slideDesc.css({
                display: 'none'
            })
        }else{
            slideDesc.stop().slideUp(450);
        }


    }
    updatePaginationStatus(index, oldIndex) {
        this.paginationItems.removeClass('is-active');
        this.paginationItems.eq(index).addClass('is-active');

        this.hideSlideDesc(this.paginationItems.eq(oldIndex).find(SELECTORS.slideDesc));
        this.showSlideDesc(this.paginationItems.eq(index).find(SELECTORS.slideDesc));

    }
    initHammer() {
        if (!this.wrapper.length) {
            return;
        }

        var screenSliderThis = this;
        var touchStartX = 0
        this.wrapper[0].addEventListener('touchstart', function (event) {
        	touchStartX = event.changedTouches[0].screenX;
        });

        this.wrapper[0].addEventListener('touchend', function (event) {
        	var touchEndX = event.changedTouches[0].screenX;
        	if (touchEndX < touchStartX)
        	{
        		// swipe left
        		screenSliderThis.nextSlide();
        	}
        	else if (touchEndX > touchStartX)
        	{
        		// swipe right
        		screenSliderThis.prevSlide();
        	}
        });
    }
    initWatchers() {
    	this.goToSlide(0, true);
    }
    setAnimations() {
        for (let slide of Array.from(this.slides)) {

            let animationType = $(slide).data('animation-type');

            if (animationType) {

                let animation = cloneDeep(sliderAnimations[animationType]);
                animation.beforeInit($(slide));
                this.animations.push(animation);

            } else {

                let animation = cloneDeep(sliderAnimations[this.config.defaultAnimationType]);
                animation.beforeInit($(slide));
                this.animations.push(animation);

            }
        }
    }
}



export default {
    init() {
        $(SELECTORS.container).each(function () {
            new screenSlider($(this));
        });
    }
};
