import anime from 'animejs';
import locationsMap from './../../locations-map';

export default {
    refs: {},
    beforeInit(element, config) {

        this.refs['element'] = element;
        this.refs['pins'] = element.find('[data-location-pin]');
        this.refs['counter'] = element.find('[data-animation-counter]');

    },
    init(element, config) {

        let pinDelay = 80;
        let counterAnimationDuration = this.refs['pins'].length * 80;
        for (let pin of Array.from(this.refs['pins'])) {

            anime({
                targets: pin,
                scale: [4.2, 1],
                opacity: [0, 1],
                delay: pinDelay,
                duration: 480,
                easing: 'cubicBezier(.16,0,0,1)'
            });
            pinDelay = pinDelay + 80

        }


        let counterAnimation = {
            counterValue: 0
        };

        anime({
            targets: counterAnimation,
            counterValue: this.refs['pins'].length,
            round: 1,
            easing: 'linear',
            duration: counterAnimationDuration,
            update: () => {
                this.refs['counter'].text(counterAnimation.counterValue)
            },
            complete: () => {
                setTimeout(() => {

                    locationsMap.init();

                }, 500)
            }
        });
    }
}
