import packageIcon from './package-animation.js'
import fadeInTop from './fade-in-top'
import locations from './locations-map.js'
import features from './features.js'
import screens from './screens.js'
import chart from './chart.js'
import speedometer from './speedometer.js'
import yourBrand from './your-brand.js'
import {
    accelerateIllustration,
    vultrCloudControllerManager,
    condor,
    illustrationResellers,
    serviceProviders,
    innovators,
    vultrContainerStorageInterface,
    sideClusterSectionWide,
    directAccess,
    highlyAvailable,
    bestPerformance,
    advancedNetworking,
    fullControl,
    automatedScaling,
    localizedPeering,
    blockStorage,
    customISO,
    blocks,
    vultr,
    error,
    ddosMonitoring,
    redundancy,
    reservedIPs,
    secureNetworking,
    nvme,
    fastBenchmarks,
    fastCPU,
    easyOfIntegration,
    mediaStorage,
    automaticBackups,
    customSolutions,
    loadBalancerHiglyAvailable,
    loadBalancerSecure,
    loadBalancerScalable,
    loadBalancerAPI,
    vultrCulture,
    vultrValues,
    interviewProcess,
    DirectConnectSection,
    optimizedCloudCpu,
    optimizedCloudGeneral,
    optimizedCloudMemory,
    optimizedCloudStorage,
    gpu,
    gpuInstance,
    enterpriseGrade,
    easyToUse,
    highlyAffordable
} from './illustration-animations.js'

import {
    mainIllustration,
    ddos,
    directConnect,
    ipSpace,
    privateNetworking,
    gaming,
    highFrequency,
    optimizedCloud,
    cloudCompute,
    bareMetal,
    objectStorage,
    referralProgram,
    kubernetesIllustration,
    mainIllustrationVfx,
    blockchain,
    highPerformance,
    pixelStreaming
} from './banner-animations.js'

import groupIconsFade from './group-icons-fade.js'
import pricingTable from './pricing-table-animation.js'
import numberAnimation from './number.js'




export default {
    packageIcon,
    locations,
    features,
    groupIconsFade,
    screens,
    pricingTable,
    numberAnimation,
    chart,
    speedometer,
    fadeInTop,
    yourBrand,

    // banner illustrations
    mainIllustration,
    ddos,
    directConnect,
    ipSpace,
    privateNetworking,
    gaming,
    highFrequency,
    optimizedCloud,
    cloudCompute,
    bareMetal,
    objectStorage,
    referralProgram,
    kubernetesIllustration,
    mainIllustrationVfx,
    blockchain,
    highPerformance,
    pixelStreaming,

    //illustrations
    accelerateIllustration,
    vultrCloudControllerManager,
    condor,
    illustrationResellers,
    serviceProviders,
    innovators,
    vultrContainerStorageInterface,
    sideClusterSectionWide,
    directAccess,
    highlyAvailable,
    bestPerformance,
    advancedNetworking,
    fullControl,
    automatedScaling,
    localizedPeering,
    blockStorage,
    customISO,
    blocks,
    vultr,
    error,
    ddosMonitoring,
    redundancy,
    reservedIPs,
    secureNetworking,
    nvme,
    fastBenchmarks,
    fastCPU,
    easyOfIntegration,
    mediaStorage,
    automaticBackups,
    loadBalancerHiglyAvailable,
    customSolutions,
    loadBalancerSecure,
    loadBalancerScalable,
    loadBalancerAPI,
    vultrCulture,
    vultrValues,
    interviewProcess,
    DirectConnectSection,
    optimizedCloudCpu,
    optimizedCloudGeneral,
    optimizedCloudMemory,
    optimizedCloudStorage,
    gpu,
    gpuInstance,
    enterpriseGrade,
    easyToUse,
    highlyAffordable
}
