import brakepoints from './../utils/brakepoints';
class StickyNavbar{

    constructor(){

        this.container = $('[data-site-navbar]');
        if(!this.container.length || ($('[data-resources-navbar]').length && $('.site-sub-navbar').length <= 0)){
            return;
        }
        this.initSticky();

    }

    initSticky(){


        var didScroll;
        var lastScrollTop = 0;
        var delta = 112;
        var mainNav = $('[data-nav]');

        $(window).scroll(function (event) {
            didScroll = true;
        });

        setInterval(function () {
            if (didScroll) {
                hasScrolled();
                didScroll = false;
            }
        }, 50);

        function hasScrolled() {
            var navbarHeight = $('.site-navbar').outerHeight();

            var st = $(window).scrollTop();

            if (Math.abs(lastScrollTop - st) <= delta)
                return;
            if (st > lastScrollTop && st > navbarHeight) {
                // Scroll Down
                $('body').removeClass('scroll-up scrolled-top').addClass('scroll-down');
            } else {
                // Scroll Up
                if (st + $(window).height() < $(document).height()) {

                    $('body').removeClass('scroll-down scrolled-top').addClass('scroll-up');

                    mainNav.trigger('close-nav')
                    $('[data-main-menu], [data-user-menu]').trigger('close-nav');
                    
                }
            }

            if (st <= delta) {
                $('body').removeClass('scroll-up');
                $('body').removeClass('scroll-down');
                $('body').addClass('scrolled-top');
            }

            lastScrollTop = st;
        }
    }

}


export default{
    init(){
        new StickyNavbar();
    }
}
