import anime from 'animejs';

export default {
    refs:{},
    beforeInit(element, config){

        this.refs['element'] = element;
        this.refs['top'] = element.find('[data-animation-top]');
        this.refs['bottom'] = element.find('[data-animation-bottom]');
        this.refs['shadow'] = element.find('[data-animation-shadow]');

    },
    init(element, config){

        let elementAnimation = anime({
            targets: this.refs.element[0],
            opacity: [0, 1],
            duration: 480,
            easing: 'cubicBezier(.16,0,0,1)',
        });

        let topAnimation = anime({
            targets: this.refs.top[0],
            translateY: [32, 0],
            easing: 'cubicBezier(.16,0,0,1)',
        });

        let bottomAnimation = anime({
            targets: this.refs.bottom[0],
            translateY: [16, 0],
            duration: 1000,
            easing: 'cubicBezier(.16,0,0,1)',
        });

        let shoadowAnimation = anime({
            targets: this.refs.shadow[0],
            duration: 1000,
            easing: 'cubicBezier(.16,0,0,1)',
        });

        Promise.all([elementAnimation.finished, topAnimation.finished, bottomAnimation.finished, shoadowAnimation.finished]).then(() => {

            this.refs.top.attr('style', '');
            this.refs.bottom.attr('style', '');
            this.refs.shadow.attr('style', '');
            this.refs.element.removeClass('is-animated');
            this.refs.element.attr('style', '');

        });
    }
}
