export default {
    target: '',
    base: '__base',
    origin: '__origin',
    handle: '__handle',
    handleLower: '--handle-lower',
    handleUpper: '--handle-upper',
    horizontal: '--horizontal',
    vertical: '--vertical',
    background: '--background',
    connect: '__connect',
    connects: '__connects',
    ltr: '--ltr',
    rtl: '--rtl',
    draggable: '--draggable',
    drag: '--state-drag',
    tap: '--state-tap',
    active: 'is-active',
    tooltip: '__tooltip',
    pips: '__pips',
    pipsHorizontal: '--pips-horizontal',
    pipsVertical: '--pips-vertical',
    marker: '__marker',
    markerHorizontal: '--marker-horizontal',
    markerVertical: '--marker-vertical',
    markerNormal: '--marker-normal',
    markerLarge: '--marker-large',
    markerSub: '--marker-sub',
    value: '__value',
    valueHorizontal: '--value-horizontal',
    valueVertical: '--value-vertical',
    valueNormal: '--value-normal',
    valueLarge: '--value-large',
    valueSub: '--value-sub'
}
