
import {fadeInTop} from './utils';

export default {
    refs:{},
    beforeInit(element, config){

        this.refs['element'] = $(element);

    },
    init(element, config){

        fadeInTop(this.refs['element'], 0);

    }
}
